import { html } from '../../../../utilities/helper';
import '../datatable.scss';

export default function (dom, configs) {
	const renderHeaderCell = ({ sortable, defaultSort, name, orderby, sortdirection }) => {
		if (sortable) {
			return html`
				<th aria-label=${name} aria-sort="none" class="slds-text-title_caps slds-is-sortable ${defaultSort ? 'slds-is-sorted' : ''}" scope="col" data-orderby=${orderby} data-sortdirection=${sortdirection}>
					<a class="slds-th__action slds-text-link_reset" href="javascript:void(0);" role="button" tabindex="-1">
						<div class="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
							<span class="slds-truncate" title='${name}'>${name}</span>
							<span class="slds-icon_container slds-icon-utility-arrowup">
								<svg class="slds-icon slds-icon-text-default slds-is-sortable__icon" aria-hidden="true">
									<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#arrowup" />
								</svg>
							</span>
							<span class="slds-icon_container slds-icon-utility-arrowdown">
								<svg class="slds-icon slds-icon-text-default slds-is-sortable__icon" aria-hidden="true">
									<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#arrowdown" />
								</svg>
							</span>
						</div>
					</a>
				</th>
			`;
		}

		return html`
			<th aria-label=${name} aria-sort="none" class="slds-text-title_caps" scope="col">
				<a class="slds-th__action slds-text-link_reset" href="javascript:void(0);" role="button" tabindex="-1">
					<div class="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
						<span class="slds-truncate" title='${name}'>${name}</span>
					</div>
				</a>
			</th>
		`;
	};

	const render = () => {
		const { headers } = configs.templateOptions;
		const template = `
			<tr class="slds-line-height_reset">
				<th class="slds-text-title_caps slds-text-align_right" scope="col"></th>
				${headers.map((header) => renderHeaderCell(header)).join('')}
			</tr>
		`;

		dom.insertAdjacentHTML('beforeend', template);
	};

	const addEvents = () => {
		// Listener for header click
		dom.querySelectorAll('.slds-table thead th.slds-is-sortable').forEach((el) => el.addEventListener('click', configs.events.onSelectTableHeader));
	};

	const initialize = () => {
		render();
		addEvents();
	};

	initialize();
}

import React from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import PropTypes from 'prop-types';
import Tooltip from '../common/tooltip';
import { INTERACTIVE_NOTIFICATION_BUTTON_DEVICE_TYPE } from '../../constants';
import { hasProperty } from '../../utilities/helper';

function InteractiveNotification ({
	i18n,
	selectedInteractiveNotification,
	setInteractiveNotification,
	interactiveNotifications
}) {
	const iosButtons = [];
	const androidButtons = [];

	if (selectedInteractiveNotification.length > 0 && hasProperty(selectedInteractiveNotification[0], 'buttons')) {
		selectedInteractiveNotification[0].buttons.forEach((button) => {
			if (button.deviceType === INTERACTIVE_NOTIFICATION_BUTTON_DEVICE_TYPE.IOS) {
				iosButtons.push(button.name);
			} else {
				androidButtons.push(button.name);
			}
		});
	}

	return (
		<div className="interactive-notification-container">
			<div className="container-heading">{i18n.get('interactive_notification')}</div>
			<Tooltip
				i18n={i18n}
				cid="mp-messages-interactivenotifications"
			/>
			<Combobox
				id="interactive-notification-combobox"
				options={interactiveNotifications}
				labels={{
					noOptionsFound: `${i18n.get('loading')}...`,
					placeholderReadOnly: i18n.get('none')
				}}
				variant="readonly"
				required
				selection={selectedInteractiveNotification}
				events={{
					onSelect: setInteractiveNotification
				}}
			/>
			{iosButtons.length > 0 ? (
				<div className="notification-buttons-container ios">
					<div className="container-heading">{i18n.get('ios')}</div>
					<div className="button-names">{iosButtons.join(', ')}</div>
				</div>
			) : ''}
			{androidButtons.length > 0 ? (
				<div className="notification-buttons-container android">
					<div className="container-heading">{i18n.get('android')}</div>
					<div className="button-names">{androidButtons.join(', ')}</div>
				</div>
			) : ''}
		</div>
	);
}

InteractiveNotification.propTypes = {
	i18n: PropTypes.object.isRequired,
	setInteractiveNotification: PropTypes.func.isRequired,
	selectedInteractiveNotification: PropTypes.array.isRequired,
	interactiveNotifications: PropTypes.array.isRequired
};

export default InteractiveNotification;

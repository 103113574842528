import React from 'react';
import PropTypes from 'prop-types';
import ReportingId from './reporting-id';
import AddCustomFunctionality from './add-custom-functionality';
import { hasProperty } from '../../utilities/helper';

function AdvancedOptions ({
	i18n,
	mobileAppDetail,
	mobilePushReportingName,
	customKeys,
	handleCustomKeysChange
}) {
	const isCustomKeysAvailable = hasProperty(mobileAppDetail, 'keys') && mobileAppDetail.keys.length > 0;

	return (
		<div>
			<span className="section-subheading">{i18n.get('advanced_options_section_subheading')}</span>
			<ReportingId
				i18n={i18n}
				mobilePushReportingName={mobilePushReportingName}
			/>
			{isCustomKeysAvailable ? (
				<AddCustomFunctionality
					i18n={i18n}
					customKeysFromSelectedApp={mobileAppDetail.keys}
					payloadCustomKeys={customKeys}
					handleCustomKeysChange={handleCustomKeysChange}
				/>
			) : ''}
		</div>
	);
}

AdvancedOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	mobileAppDetail: PropTypes.object.isRequired,
	mobilePushReportingName: PropTypes.string.isRequired,
	customKeys: PropTypes.array.isRequired,
	handleCustomKeysChange: PropTypes.func.isRequired
};

export default AdvancedOptions;

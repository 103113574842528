var ContentBuilderSDK = require('@salesforce-mc/contentbuilder-sdk');
var log = require('../log');

function noop() {}

// this is where all of the scm onLoad logic happens
module.exports = function loadContent(options) {
    var contentBuilderService = null;
    var scm = options.scm;
    var contentBuilderElement = null;

    function finishLoading(error) {
        if (error) {
            log('content load completed with error');
            logError(error);
            destroyContentBuilder();
            options.onLoadError(error);
            return;
        }

        if (options.useContentBuilderAPI) {
            scm.contentBuilder = contentBuilderService;
        } else {
            destroyContentBuilder();
        }

        log('content load completed successfully');
        options.onLoadDone();
    }

    function destroyContentBuilder() {
        if (contentBuilderService) {
            log('destroying content builder api');
            contentBuilderService.destroy();
        }
        if (contentBuilderElement) {
            scm.config.element.removeChild(contentBuilderElement);
        }
    }

    // if the activity is not editable then we need to toggle the readonly state
    configureReadOnlyState(scm);

    // force the message definition view to load so content builder
    // can initialize before the user visits the view
    if (options.preloadContentBuilder) {
        preloadMessageDefinitionView(scm);
    }

    // the user can implement a getAssetId() function and return the id of a content builder
    // asset. If their code throws an error we need to handle that
    var getAssetIdResult = tryInvokeFunction(options, 'getAssetId', [scm]);
    if (getAssetIdResult.error) {
        finishLoading(getAssetIdResult.error);
        return;
    }

    contentBuilderElement = renderContentBuilderElement(scm);
    createContentBuilderAPI({
        element: contentBuilderElement,
        stack: options.contentBuilderOptions.stack,
        onReady: onContentBuilderReady
    });

    function onContentBuilderReady(contentBuilder) {
        contentBuilderService = contentBuilder;

        if (!getAssetIdResult.value) {
            log('no asset ID was given');
            finishLoading();
            return;
        }

        log('fetching content builder asset');

        fetchContentBuilderAsset({
            assetId: getAssetIdResult.value,
            contentBuilder: contentBuilder,
            onSuccess: function(asset) {
                log('content builder asset fetched successfully');
                var result = tryInvokeFunction(options, 'onAssetLoaded', [asset]);
                finishLoading(result.error);
            },
            onError: function(err) {
                log('an error occurred while fetching content builder asset');
                finishLoading(err);
            }
        });
    }
};

function renderContentBuilderElement(scm) {
    // the Content Builder API needs an element to live in
    var element = document.createElement('div');
    element.id = 'content-builder-api';
    element.style.display = 'none';
    scm.config.element.appendChild(element);
    return element;
}

function configureReadOnlyState(scm) {
    if (scm.sdk && scm.sdk.activity) {
        if (scm.sdk.activity.editable === false) {
            scm.setReadOnly(true);
        }
    }
}

function createContentBuilderAPI(opts) {
    var element = opts.element;
    var stack = opts.stack;
    var onReady = opts.onReady;
    var contentBuilder = null;

    // the API ready event seems to be broken. it fires every time the a
    // a grid or editor is created, we need to noop this callback after the first call
    var onContentBuilderReady = function() {
        log('content builder api is ready');
        onReady(contentBuilder);
        onReady = noop;
    };

    log('creating content builder api');
    contentBuilder = new ContentBuilderSDK.API(element, stack, {}, onContentBuilderReady);
}

function fetchContentBuilderAsset(opts) {
    var contentBuilder = opts.contentBuilder;
    var assetId = opts.assetId;
    var onSuccess = opts.onSuccess;
    var onError = opts.onError;
    contentBuilder.getAsset(assetId, onSuccess, function(err) {
        var error = err || new Error('An error occurred fetching asset from content builder. assetId=' + assetId);
        onError(error);
    });
}

function preloadMessageDefinitionView(scm) {
    // This function will force the message definition view to render (which initializes the content builder grid).
    // This will significantly increase the perceived performance of the grid.

    // We're changing the size of the view so the current load mask is a consistent size
    // and we'll need to set it back to the original value when we're done
    var messageDefinitionSize = scm.config.views.messageDefinition.size;
    scm.config.views.messageDefinition.size = 'scm-lg';
    scm.show('messageDefinition');
    scm.config.views.messageDefinition.size = messageDefinitionSize;
}

function tryInvokeFunction(object, functionName, args) {
    var result = {value: null, error: null};
    if (!object[functionName]) {
        return result;
    }
    try {
        log('invoking "%s"', functionName);
        result.value = object[functionName].apply(object, args);
    } catch (err) {
        log('error occurred while invoking "%s"', functionName);
        result.error = err;
    }
    return result;
}

function logError(error) {
    if (console && console.error) {
        console.error(error);
    }
}

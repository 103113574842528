import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// utilities
import isEmpty from 'lodash/isEmpty';
import { hasProperty } from '../utilities/helper';

// Component
import AdvancedOptionsComponent from '../components/advanced-options';

// actions
import { fetchMobileAppDetail, setMobileAppDetail, updateCustomKeys } from '../actions/mobile-app-selection';

class AdvancedOptions extends React.Component {
	constructor (props) {
		super(props);

		this.metaData = props.advancedOptionsStore[props.sectionKey];
		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}

		props.setMobileAppDetail(props.advancedOptionsStore.messageConfiguration.mobileAppDetail);

		this.renderCustomKeysWithExistingData();

		this.handleCustomKeysChange = this.handleCustomKeysChange.bind(this);
	}

	renderCustomKeysWithExistingData () {
		// On edit flow, if application data is alerady available but detail prop is empty, fetch the app detail manually
		if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.advancedOptionsStore.messageConfiguration, 'application')) {
			this.props.fetchMobileAppDetail(this.props.advancedOptionsStore.messageConfiguration.application.id);

			return;
		}

		// When selected app's detail is available but it doesn't have custom key data, remove custom keys from payload
		if (!isEmpty(this.props.mobileAppDetail) && !hasProperty(this.props.mobileAppDetail, 'keys')) {
			delete this.metaData.payload.keys;
			delete this.metaData.customKeys;

			return;
		}

		if (hasProperty(this.props.mobileAppDetail, 'keys')) {
			if (hasProperty(this.metaData.payload, 'keys')) {
				// Generate list of custom keys with empty value
				const tempKeys = this.props.mobileAppDetail.keys.map((originalCustomKey) => ({
					key: originalCustomKey.key,
					value: ''
				}));

				// Replace custom keys' value with existing data
				let foundCustomKey = 0;
				this.metaData.payload.keys.forEach((customKey) => {
					const keyIndex = tempKeys.findIndex((originalCustomKey) => originalCustomKey.key === customKey.key);

					if (keyIndex > -1) {
						foundCustomKey++;
						tempKeys[keyIndex].value = customKey.value;
					}
				});

				// If different app is selected, clear keys from payload
				if (!foundCustomKey) {
					delete this.metaData.payload.keys;
					delete this.metaData.customKeys;
				}

				// Update custom key prop
				this.props.updateCustomKeys(tempKeys);
			}
		}
	}

	componentDidUpdate () {
		// If customKeys prop has not been updated when app detail is available, initialize customKeys prop
		if (isEmpty(this.props.customKeys.filter((key) => key.value.length > 0)) && !isEmpty(this.metaData.customKeys)) {
			this.renderCustomKeysWithExistingData();

			return;
		}

		// If selected mobile app does not contain custom keys, remove customKeys from payload
		if (!hasProperty(this.props.mobileAppDetail, 'keys')) {
			// If application data is available but the app hasn't been fetched, stop proceeding.
			if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.advancedOptionsStore.messageConfiguration, 'application')) {
				return;
			}

			delete this.metaData.payload.keys;
			delete this.metaData.customKeys;
		}

		// Print out payload for debugging purpose
		console.log(this.props.advancedOptionsStore);
	}

	handleCustomKeysChange (key, value) {
		// Replace key object with updated key with value
		const keyIndex = this.props.customKeys.findIndex((customKey) => customKey.key === key);

		if (keyIndex === -1) {
			return;
		}

		const updatedKey = { ...this.props.customKeys[keyIndex], value };
		const finalCustomKeys = [
			...this.props.customKeys.slice(0, keyIndex),
			updatedKey,
			...this.props.customKeys.slice(keyIndex + 1)
		];

		this.props.updateCustomKeys(finalCustomKeys);

		// Omit keys with empty values first
		const filteredCustomKeys = finalCustomKeys.filter((prop) => prop.value.length > 0);

		if (isEmpty(filteredCustomKeys)) {
			delete this.metaData.payload.keys;
			delete this.metaData.customKeys;
		} else {
			this.metaData.payload.keys = filteredCustomKeys;
			this.metaData.customKeys = filteredCustomKeys;
		}
	}

	render () {
		return (
			<AdvancedOptionsComponent
				i18n={this.props.i18n}
				mobileAppDetail={this.props.mobileAppDetail}
				mobilePushReportingName={this.metaData.payload.mobilePushReportingName}
				customKeys={this.props.customKeys}
				handleCustomKeysChange={this.handleCustomKeysChange}
			/>
		);
	}
}

AdvancedOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	advancedOptionsStore: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired,
	mobileAppDetail: PropTypes.object,
	customKeys: PropTypes.array.isRequired,
	updateCustomKeys: PropTypes.func.isRequired,
	fetchMobileAppDetail: PropTypes.func.isRequired,
	setMobileAppDetail: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	mobileAppDetail: state.MobileAppSelection.mobileAppDetail,
	customKeys: state.MobileAppSelection.customKeys
});

const mapDispatchToProps = (dispatch) => ({
	updateCustomKeys: (keys) => {
		dispatch(updateCustomKeys(keys));
	},
	fetchMobileAppDetail: (id) => dispatch(fetchMobileAppDetail(id)),
	setMobileAppDetail: (detail) => {
		dispatch(setMobileAppDetail(detail));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedOptions);

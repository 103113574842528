const compile = require('./compile');
const COMPILE_MODES = require('./constants/compile-modes');
const AssetComparison = require('./asset-comparison');
const ThumbnailGenerator = require('./thumbnail-generator');

require('./less/assetutils.less');

// COMPILE

var AssetUtils = {
	COMPILE_MODE: COMPILE_MODES,
	compile: compile.compile,
	getView: compile.getView,
	haveSameContent: AssetComparison.haveSameContent,
	getContentHash: AssetComparison.getContentHash,
	getMinimalComparableAsset: AssetComparison.getMinimalComparableAsset,
	generateThumbnail: ThumbnailGenerator.generateThumbnail,
	generateZoomableThumbnail: ThumbnailGenerator.generateZoomableThumbnail,
	showMagnificationZoomableThumbnail: ThumbnailGenerator.showMagnificationZoomableThumbnail,
	hideMagnificationZoomableThumbnail: ThumbnailGenerator.hideMagnificationZoomableThumbnail
};

function getTemplate (asset) {
	asset = asset || {};
	return asset.template || asset.views && asset.views.html && asset.views.html.template;
}

function reservedWords () {
	return [
		'content',
		'subcontent',
		'supercontent',
		'design',
		'tags',
		'filename',
		'createdbyname',
		'modifiedbyname',
		'ownername',
		'name',
		'legacyid',
		'legacycategoryid',
		'legacytype',
		'enterpriseid'
	];
}

function checkForReservedWords (asset) {
	var wordsUsed = [],
		reservedWordsList = reservedWords();

	compile.compile(asset, null, null, function (ast, key) {
		if (key && reservedWordsList.indexOf(key.toLowerCase()) !== -1) {
			wordsUsed.push(key);
		}
		return ast;
	});

	return wordsUsed;
}

function getDuplicateSlotKeys (asset) {
	const keyCount = {};
	const slotRefs = compile.getReferences(asset.content || (asset.views && asset.views.html && asset.views.html.content) || '', 'slot');

	return slotRefs
		.map(slotRef => slotRef.split('data-key="')[1].split('"')[0])
		.filter(key => {
			keyCount[key] = (keyCount[key] || 0) + 1;
			if (keyCount[key] === 2) {
				return true;
			}
		});
}

function getUnmatchedSlotKeys (asset) {
	if(asset && asset.slots) {
		const slotObjectKeys = Object.keys(asset.slots) || [];
		const slotRefs = compile.getReferences(asset.content || (asset.views && asset.views.html && asset.views.html.content) || '', 'slot');

		slotRefs
			.map(slotRef => slotRef.split('data-key="')[1].split('"')[0])
			.filter(key => {
				const index = slotObjectKeys.indexOf(key);
				if (index > -1) {
					slotObjectKeys.splice(index, 1);
				}
			});
		return slotObjectKeys;
	}
	else {
		return [];
	}

}

function getPrimaryView (asset) {
	const { availableViews } = asset;

	if (!availableViews || availableViews.length === 0) {
		return undefined;
	}

	return availableViews[0].toLowerCase();
}

function getThumbnailView (asset) {
	let availableThumbnailViews = '';
	const assetId = asset.assetType.id;
	const view = getPrimaryView(asset);

	switch (assetId) {
		case 5:
		case 207:
		case 208:
			availableThumbnailViews = 'html';
			break;
		case 209:
			availableThumbnailViews = 'text';
			break;
		case 3:
		case 230:
		case 199:
			if (view) {
				availableThumbnailViews = view;
			}
			break;
		default:
			break;
	}
	return availableThumbnailViews;
}

function getSlots (asset) {
	if (asset.assetType && asset.assetType.id === 207) {
		return asset.views.html.slots;
	}
	return asset.slots;
}

function getContent (asset) {
	if (asset.assetType && asset.assetType.id === 207) {
		return asset.views.html.content;
	}
	return asset.content;
}

function getOrderedSlots (asset) {
	const parser = new DOMParser();
	const html = getContent(asset);
	const slots = getSlots(asset);
	const doc = parser.parseFromString(html, "text/html");
	const slotNodes = Array.from(doc.querySelectorAll('[data-type="slot"]'));
	let orderedSlots = [];

	slotNodes.forEach((slotElement) => {
		const slotID = slotElement.getAttribute('data-key');
		const slot = slots[slotID];

		if (slot) {
			orderedSlots.push(Object.assign({slotID}, slot));
		}
	});

	return orderedSlots;
}

function getOrderedBlocksBySlot (slot) {
	let nestedBlocks = [];

	if (slot.blocks) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(slot.content, "text/html");
		const blockNodes = Array.from(doc.querySelectorAll('[data-type="block"]'));

		blockNodes.forEach((blockElement) => {
			const blockID = blockElement.getAttribute('data-key');
			const block = slot.blocks[blockID];

			if (block) {
				nestedBlocks.push(Object.assign({blockID}, block));
			}
		});
	}

	return nestedBlocks;
}

function getOrderedBlocks (asset) {
	let nestedBlocks = [];

	getOrderedSlots(asset).forEach(function (slot) {
		if (slot.blocks) {
			Object.keys(slot.blocks).forEach(key => {
				const block = slot.blocks[key];
				const nestedSlots = block.slots;

				nestedBlocks.push(block);

				if (nestedSlots && Object.keys(nestedSlots).length != 0) {
					const nestedSlot = {content: block.content, slots: nestedSlots};
					nestedBlocks = nestedBlocks.concat(getOrderedBlocks(nestedSlot));
				}
			});
		}
	});

	return nestedBlocks;
}

AssetUtils.getTemplate = getTemplate;
AssetUtils.checkForReservedWords = checkForReservedWords;
AssetUtils.reservedWords = reservedWords;
AssetUtils.getOrderedSlots = getOrderedSlots;
AssetUtils.getOrderedBlocksBySlot = getOrderedBlocksBySlot;
AssetUtils.getOrderedBlocks = getOrderedBlocks;
AssetUtils.getPrimaryView = getPrimaryView;
AssetUtils.getThumbnailView = getThumbnailView;
AssetUtils.getDuplicateSlotKeys = getDuplicateSlotKeys;
AssetUtils.getUnmatchedSlotKeys = getUnmatchedSlotKeys;

// DYNAMIC

var DynamicUtils = {};

function buildDynamicMap (asset) {
	var dynamicMap = [];
	var compiled = AssetUtils.compile(asset, null, 'preview', function (a) {
		if (a.assetType && a.assetType.id === 201) {
			if (
				a.meta &&
				a.meta.options &&
				a.meta.options.dynamicContent &&
				a.meta.options.dynamicContent.views &&
				a.meta.options.dynamicContent.views.html
			) {
				var dview = a.meta.options.dynamicContent.views.html;
				var total = 1;
				if (dview.variants && dview.variants.length) {
					total += dview.variants.length;
				}
				dynamicMap.push({
					current: 0,
					total: total
				});
			}
		}
		return a;
	});
	return {
		dynamicMap: dynamicMap,
		compiled: compiled
	};
}

function renderDynamicMap (asset, dynamicMap) {
	var currentBlock = 0;
	asset = JSON.parse(JSON.stringify(asset));
	var compiled = AssetUtils.compile(asset, null, 'preview', function (a) {
		if (a.assetType && a.assetType.id === 201) {
			if (
				a.meta &&
				a.meta.options &&
				a.meta.options.dynamicContent &&
				a.meta.options.dynamicContent.views &&
				a.meta.options.dynamicContent.views.html
			) {
				var current = dynamicMap[currentBlock].current;
				currentBlock += 1;
				if (current) {
					var dview = a.meta.options.dynamicContent.views.html;
					if (dview.variants && dview.variants.length >= current) {
						var currentObj = dview.variants[current - 1];
						if (
							currentObj &&
							currentObj.content &&
							currentObj.content.textContent
						) {
							a.superContent = currentObj.content.textContent;
						}
					}
				}
			}
		}
		return a;
	});
	return compiled;
}

function getNumberOfVariants (dynamicMap) {
	var total = 1;
	for (var i = 0; i < dynamicMap.length; i++) {
		total *= dynamicMap[i].total;
	}
	return total;
}

function setDynamicMap (dynamicMap, n) {
	var currentTotal = 1;
	for (var i = 0; i < dynamicMap.length; i++) {
		var map = dynamicMap[i];
		map.current = Math.floor(n / currentTotal) % map.total;
		currentTotal *= map.total;
	}
	return dynamicMap;
}

function getVariantNumberFromMap (dynamicMap) {
	var currentTotal = 1;
	var number = 0;
	for (var i = 0; i < dynamicMap.length; i++) {
		var map = dynamicMap[i];
		number += currentTotal * map.current;
		currentTotal *= map.total;
	}
	return number;
}

DynamicUtils.buildDynamicMap = buildDynamicMap;
DynamicUtils.renderDynamicMap = renderDynamicMap;
DynamicUtils.getNumberOfVariants = getNumberOfVariants;
DynamicUtils.setDynamicMap = setDynamicMap;
DynamicUtils.getVariantNumberFromMap = getVariantNumberFromMap;
AssetUtils.DynamicUtils = DynamicUtils;

module.exports = AssetUtils;

module.exports = function configureView(scm, options) {
    // reuse or create a view called "summary"
    // this view will be the target we render the everything into.
    scm.config.views.summary = scm.config.views.summary || {};
    var view = scm.config.views.summary;

    // the summary view will be our default view
    scm.config.defaultView = 'summary';

    view.header = {
        text: scm.i18n.get('majik_ce_summary_header'),
        showContext: false
    };

    view.menu = {
        index: 0,
        hidden: true,
        text: scm.i18n.get('majik_ce_summary_menu')
    };

    view.footer = {
        buttons: []
    };

    if (options.showDetailViewButton) {
        view.footer.buttons.push({
            id: 'detail-btn',
            cssClass: 'slds-button_neutral',
            text: scm.i18n.get('majik_ce_detail_view_btn'),
            disabled: false,
            onClick: 'onDetailViewClicked'
        });
    }

    view.footer.buttons.push({
        id: 'done-btn',
        text: scm.i18n.get('majik_ce_done_btn'),
        disabled: false,
        onClick: 'onDoneClicked'
    });

    return view;
};

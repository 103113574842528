import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../common/tooltip';
import { NOTIFICATION_SOUND } from '../../constants';

function NotificationSound ({
	i18n,
	selectedNotificationSound,
	setNotificationSound
}) {
	return (
		<div className="notification-sound-container">
			<div className="container-heading">{i18n.get('notification_sound_container_heading')}</div>
			<Tooltip
				i18n={i18n}
				cid="mp-message-customsound"
			/>
			<div className="slds-form-element__control">
				<div className="slds-visual-picker slds-visual-picker_vertical">
					<input
						type="radio"
						id={NOTIFICATION_SOUND.DEFAULT}
						value={NOTIFICATION_SOUND.DEFAULT}
						name="options"
						checked={selectedNotificationSound === NOTIFICATION_SOUND.DEFAULT}
						onChange={setNotificationSound}
					/>
					<label htmlFor={NOTIFICATION_SOUND.DEFAULT}>
						<span className="slds-visual-picker__figure slds-visual-picker__text">
							<span>
								<span className="slds-text-heading_small slds-m-bottom_x-small">{i18n.get('notification_sound_options_default')}</span>
								<span className="slds-text-title">{i18n.get('notification_sound_options_default_desc')}</span>
							</span>
						</span>
						<span className="slds-icon_container slds-visual-picker__text-check">
							<svg className="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
								<use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
							</svg>
						</span>
					</label>
				</div>
				<div className="slds-visual-picker slds-visual-picker_vertical">
					<input
						type="radio"
						id={NOTIFICATION_SOUND.CUSTOM}
						value={NOTIFICATION_SOUND.CUSTOM}
						name="options"
						checked={selectedNotificationSound === NOTIFICATION_SOUND.CUSTOM}
						onChange={setNotificationSound}
					/>
					<label htmlFor={NOTIFICATION_SOUND.CUSTOM}>
						<span className="slds-visual-picker__figure slds-visual-picker__text">
							<span>
								<span className="slds-text-heading_small slds-m-bottom_x-small">{i18n.get('custom')}</span>
								<span className="slds-text-title">{i18n.get('notification_sound_options_custom_desc')}</span>
							</span>
						</span>
						<span className="slds-icon_container slds-visual-picker__text-check">
							<svg className="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
								<use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
							</svg>
						</span>
					</label>
				</div>
				<div className="slds-visual-picker slds-visual-picker_vertical">
					<input
						type="radio"
						id={NOTIFICATION_SOUND.NONE}
						value={NOTIFICATION_SOUND.NONE}
						name="options"
						checked={selectedNotificationSound === NOTIFICATION_SOUND.NONE}
						onChange={setNotificationSound}
					/>
					<label htmlFor={NOTIFICATION_SOUND.NONE}>
						<span className="slds-visual-picker__figure slds-visual-picker__text">
							<span>
								<span className="slds-text-heading_small slds-m-bottom_x-small">{i18n.get('none')}</span>
								<span className="slds-text-title">{i18n.get('notification_sound_options_none_desc')}</span>
							</span>
						</span>
						<span className="slds-icon_container slds-visual-picker__text-check">
							<svg className="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
								<use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
							</svg>
						</span>
					</label>
				</div>
			</div>
		</div>
	);
}

NotificationSound.propTypes = {
	i18n: PropTypes.object.isRequired,
	selectedNotificationSound: PropTypes.string.isRequired,
	setNotificationSound: PropTypes.func.isRequired
};

export default NotificationSound;

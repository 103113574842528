import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// utilities
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Campaign from '@salesforce-mc/campaign-selector-vanilla';
import { hasProperty } from '../utilities/helper';
import TimezoneUtils from '../utilities/timezone';
import PostmongerStore from '../utilities/postmonger';

import { NOTIFICATION_SOUND } from '../constants';

// components
import DeliveryOptionsComponent from '../components/delivery-options';

// actions
import { setNotificationSound, setInteractiveNotification, setIOSBadgeToggleValue, setSendWindowToggleValue, setCampaign, setSendWindowTime, setSendWindowTimezone } from '../actions/delivery-options';
import { fetchMobileAppDetail, setInteractiveNotifications, setMobileAppDetail } from '../actions/mobile-app-selection';

class DeliveryOptions extends React.Component {
	constructor (props) {
		super(props);

		this.metaData = props.deliveryOptionsStore[props.sectionKey];
		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}

		props.setInteractiveNotification(null, {
			selection: this.metaData.selectedInteractiveNotification ? this.metaData.selectedInteractiveNotification : []
		});
		props.setInteractiveNotifications(this.metaData.interactiveNotifications);
		props.setMobileAppDetail(props.deliveryOptionsStore.messageConfiguration.mobileAppDetail);

		this.renderNotificationSoundWithExistingData();
		this.renderInteractiveNotificationWithExistingData();
		this.renderIOSBadgeToggleWithExistingData();
		this.renderCampaignWithExistingData();
		if (PostmongerStore.isBROn('push_jb_send_window')) {
			this.renderSendWindowWithExistingData();
		}

		this.onAddCampaign = this.onAddCampaign.bind(this);
		this.onRemoveCampaign = this.onRemoveCampaign.bind(this);
	}

	componentDidUpdate () {
		if (isEmpty(this.props.selectedInteractiveNotification) && !isEmpty(this.props.interactiveNotifications) && hasProperty(this.metaData.payload, 'interactiveNotification')) {
			this.renderInteractiveNotificationWithExistingData();

			return;
		}

		this.saveNotificationSound();
		this.saveInteractiveNotification();
		this.saveIOSBadgeToggleValue();
		this.saveCampaign();
		if (PostmongerStore.isBROn('push_jb_send_window')) {
			this.saveSendWindow();
		}

		// print out payload for debugging purpose
		console.log(this.props.deliveryOptionsStore);
	}

	renderNotificationSoundWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'sound')) {
			return;
		}

		this.props.setNotificationSound({
			target: {
				value: this.metaData.payload.sound === '' ? NOTIFICATION_SOUND.NONE : this.metaData.payload.sound
			}
		});
	}

	renderInteractiveNotificationWithExistingData () {
		// On edit flow, if application data is alerady available but detail prop is empty, fetch the app detail manually
		if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.deliveryOptionsStore.messageConfiguration, 'application')) {
			this.props.fetchMobileAppDetail(this.props.deliveryOptionsStore.messageConfiguration.application.id);

			return;
		}

		// When selected app's detail is available but it doesn't have interactive notifications data, remove interactive notification from payload
		if (!isEmpty(this.props.mobileAppDetail) && isEmpty(this.props.interactiveNotifications)) {
			// Clear dropdown selection prop
			this.props.setInteractiveNotification(null, {
				selection: []
			});

			delete this.metaData.payload.interactiveNotification;

			return;
		}

		if (!isEmpty(this.props.mobileAppDetail) && !isEmpty(this.props.interactiveNotifications) && hasProperty(this.metaData.payload, 'interactiveNotification')) {
			this.props.setInteractiveNotification(null, {
				selection: [{
					id: this.metaData.payload.interactiveNotification.id ? this.metaData.payload.interactiveNotification.id : this.metaData.payload.interactiveNotification.name,
					label: this.metaData.payload.interactiveNotification.name,
					value: this.metaData.payload.interactiveNotification.name,
					buttons: this.metaData.payload.interactiveNotification.buttons
				}]
			});
		}
	}

	renderIOSBadgeToggleWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'iosBadge')) {
			return;
		}

		this.props.setIOSBadgeToggleValue(null, {
			checked: this.metaData.payload.iosBadge
		});
	}

	renderCampaignWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'campaignId')) {
			return;
		}

		this.props.setCampaign(this.metaData.selectedCampaign);
	}

	renderSendWindowWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'sendWindowStartTime')) {
			return;
		}

		// Enable send window toggle
		this.props.setSendWindowToggleValue(null, {
			checked: true
		});

		// Set start time picker
		if (hasProperty(this.metaData, 'sendWindowStartTime')) {
			this.props.setSendWindowTime('start', this.metaData.sendWindowStartTime);
		}

		// Set end time picker
		if (hasProperty(this.metaData, 'sendWindowEndTime')) {
			this.props.setSendWindowTime('end', this.metaData.sendWindowEndTime);
		}

		// Set timezone picker
		if (hasProperty(this.metaData, 'sendWindowTimeZone')) {
			this.props.setSendWindowTimezone(null, {
				selection: [TimezoneUtils.getTimezoneItemForComboboxById(this.metaData.sendWindowTimeZone.id)]
			});
		}
	}

	saveNotificationSound () {
		const notificationSoundTranslationMap = {
			default: this.props.i18n.get('notification_sound_options_default'),
			'custom.caf': this.props.i18n.get('custom'),
			none: this.props.i18n.get('none')
		};
		this.metaData.selectedNotificationSound = notificationSoundTranslationMap[this.props.selectedNotificationSound];
		this.metaData.payload.sound = this.props.selectedNotificationSound === NOTIFICATION_SOUND.NONE ? '' : this.props.selectedNotificationSound;
	}

	saveInteractiveNotification () {
		if (!hasProperty(this.props.mobileAppDetail, 'categories')) {
			// If application data is available but the app hasn't been fetched, stop proceeding.
			if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.deliveryOptionsStore.messageConfiguration, 'application')) {
				return;
			}

			delete this.metaData.selectedInteractiveNotification;
			delete this.metaData.payload.interactiveNotification;

			return;
		}

		if (isEmpty(this.props.selectedInteractiveNotification)) {
			delete this.metaData.selectedInteractiveNotification;
			delete this.metaData.payload.interactiveNotification;

			return;
		}

		this.metaData.selectedInteractiveNotification = this.props.selectedInteractiveNotification;
		this.metaData.payload.interactiveNotification = {
			id: this.props.selectedInteractiveNotification[0].id,
			name: this.props.selectedInteractiveNotification[0].value,
			buttons: this.props.selectedInteractiveNotification[0].buttons
		};
	}

	saveIOSBadgeToggleValue () {
		this.metaData.isIOSBadgeToggleEnabled = this.props.isIOSBadgeToggleEnabled ? this.props.i18n.get('enabled') : this.props.i18n.get('disabled');
		this.metaData.payload.iosBadge = this.props.isIOSBadgeToggleEnabled;
	}

	saveCampaign () {
		if (isEmpty(this.props.selectedCampaign)) {
			delete this.metaData.selectedCampaign;
			delete this.metaData.payload.campaignId;

			return;
		}

		this.metaData.selectedCampaign = this.props.selectedCampaign;
		this.metaData.payload.campaignId = this.props.selectedCampaign.campaignId;
	}

	saveSendWindow () {
		this.metaData.isSendWindowToggleEnabled = this.props.isSendWindowToggleEnabled;

		if (!this.props.isSendWindowToggleEnabled) {
			delete this.metaData.sendWindowStartTime;
			delete this.metaData.sendWindowEndTime;
			delete this.metaData.sendWindowTimeZone;

			delete this.metaData.payload.sendWindowStartTime;
			delete this.metaData.payload.sendWindowEndTime;
			delete this.metaData.payload.sendWindowTimeZone;

			return;
		}

		// Save metadata
		this.metaData.sendWindowStartTime = this.props.sendWindowStartTime;
		this.metaData.sendWindowEndTime = this.props.sendWindowEndTime;
		this.metaData.sendWindowTimeZone = this.props.sendWindowTimeZone;

		// Save payload
		this.metaData.payload.sendWindowStartTime = this.props.sendWindowStartTime.length > 0 ? moment(this.props.sendWindowStartTime, 'LT').format('YYYY-MM-DDTHH:mm') : '';
		this.metaData.payload.sendWindowEndTime = this.props.sendWindowEndTime.length > 0 ? moment(this.props.sendWindowEndTime, 'LT').format('YYYY-MM-DDTHH:mm') : '';
		this.metaData.payload.sendWindowTimeZone = this.props.sendWindowTimeZone.value;
	}

	onAddCampaign () {
		const self = this;
		let selection = this.props.selectedCampaign ? this.props.selectedCampaign : {};

		const CampaignSelectorModal = {
			footer: {
				buttons: [
					{
						id: 'campaign-select-btn',
						text: this.props.i18n.get('majik_button_select'),
						onClick: 'onSelectCampaign'
					}
				]
			},
			onInitialize: function (options) {
				// you can also access options on `this`
				this.element.innerHTML = `
					<div class="scm-common-header-text slds-text-heading_medium slds-text-color_default slds-truncate">
						${options.title}
					</div>
					<div id="campaign-selector"></div>
				`;

				const campaignSelectorConfig = {
					locale: self.props.i18n.getLocale()
				};

				if (!isEmpty(selection)) {
					campaignSelectorConfig.selection = selection;
				}

				const campaignSelector = new Campaign(this.element.querySelector('#campaign-selector'), campaignSelectorConfig);

				campaignSelector.onClickRow = (response) => {
					selection = response;
				};
			},
			onSelectCampaign: function () {
				self.props.setCampaign(selection);
				this.close();
			}
		};

		this.props.createModal(CampaignSelectorModal, {
			title: self.props.i18n.get('select_campaign')
		}).show();
	}

	onRemoveCampaign () {
		this.props.setCampaign({});
	}

	render () {
		return (
			<DeliveryOptionsComponent
				i18n={this.props.i18n}
				selectedNotificationSound={this.props.selectedNotificationSound}
				setNotificationSound={this.props.setNotificationSound}
				setInteractiveNotification={this.props.setInteractiveNotification}
				interactiveNotifications={this.props.interactiveNotifications}
				selectedInteractiveNotification={this.props.selectedInteractiveNotification}
				isIOSBadgeToggleEnabled={this.props.isIOSBadgeToggleEnabled}
				setIOSBadgeToggleValue={this.props.setIOSBadgeToggleValue}
				isSendWindowToggleEnabled={this.props.isSendWindowToggleEnabled}
				setSendWindowToggleValue={this.props.setSendWindowToggleValue}
				onAddCampaign={this.onAddCampaign}
				selectedCampaign={this.props.selectedCampaign}
				onRemoveCampaign={this.onRemoveCampaign}
				onSelectSendWindow={this.props.setSendWindowTime}
				sendWindowStartTime={this.props.sendWindowStartTime}
				sendWindowEndTime={this.props.sendWindowEndTime}
				onSelectSendWindowTimezone={this.props.setSendWindowTimezone}
				sendWindowTimeZone={this.props.sendWindowTimeZone}
				menuValidationState={this.props.menuValidationState}
			/>
		);
	}
}

DeliveryOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	deliveryOptionsStore: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired,
	selectedNotificationSound: PropTypes.string.isRequired,
	setNotificationSound: PropTypes.func.isRequired,
	setInteractiveNotification: PropTypes.func.isRequired,
	interactiveNotifications: PropTypes.array.isRequired,
	selectedInteractiveNotification: PropTypes.array.isRequired,
	isIOSBadgeToggleEnabled: PropTypes.bool.isRequired,
	setIOSBadgeToggleValue: PropTypes.func.isRequired,
	isSendWindowToggleEnabled: PropTypes.bool,
	setSendWindowToggleValue: PropTypes.func,
	fetchMobileAppDetail: PropTypes.func.isRequired,
	mobileAppDetail: PropTypes.object,
	setInteractiveNotifications: PropTypes.func.isRequired,
	setMobileAppDetail: PropTypes.func.isRequired,
	createModal: PropTypes.func.isRequired,
	setCampaign: PropTypes.func.isRequired,
	selectedCampaign: PropTypes.object.isRequired,
	setSendWindowTime: PropTypes.func,
	sendWindowStartTime: PropTypes.string,
	sendWindowEndTime: PropTypes.string,
	sendWindowTimeZone: PropTypes.object,
	setSendWindowTimezone: PropTypes.func,
	menuValidationState: PropTypes.string
};

const mapStateToProps = (state) => ({
	interactiveNotifications: state.MobileAppSelection.interactiveNotifications,
	selectedNotificationSound: state.DeliveryOptions.selectedNotificationSound,
	selectedInteractiveNotification: state.DeliveryOptions.selectedInteractiveNotification,
	isIOSBadgeToggleEnabled: state.DeliveryOptions.isIOSBadgeToggleEnabled,
	isSendWindowToggleEnabled: state.DeliveryOptions.isSendWindowToggleEnabled,
	mobileAppDetail: state.MobileAppSelection.mobileAppDetail,
	selectedCampaign: state.DeliveryOptions.selectedCampaign,
	sendWindowStartTime: state.DeliveryOptions.sendWindowStartTime,
	sendWindowEndTime: state.DeliveryOptions.sendWindowEndTime,
	sendWindowTimeZone: state.DeliveryOptions.sendWindowTimeZone
});

const mapDispatchToProps = (dispatch) => ({
	setNotificationSound: (event) => {
		dispatch(setNotificationSound(event.target.value));
	},
	setInteractiveNotification: (event, data) => {
		dispatch(setInteractiveNotification(data.selection));
	},
	setIOSBadgeToggleValue: (event, data) => {
		dispatch(setIOSBadgeToggleValue(data.checked));
	},
	setSendWindowToggleValue: (event, data) => {
		dispatch(setSendWindowToggleValue(data.checked));
	},
	fetchMobileAppDetail: (id) => dispatch(fetchMobileAppDetail(id)),
	setInteractiveNotifications: (notifications) => {
		dispatch(setInteractiveNotifications(notifications));
	},
	setMobileAppDetail: (detail) => {
		dispatch(setMobileAppDetail(detail));
	},
	setCampaign: (campaign) => {
		dispatch(setCampaign(campaign));
	},
	setSendWindowTime: (type, time) => {
		dispatch(setSendWindowTime({
			type, time
		}));
	},
	setSendWindowTimezone: (event, data) => {
		if (isEmpty(data.selection)) {
			return; // Disable combobox selection toggle
		}

		dispatch(setSendWindowTimezone(data.selection));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);

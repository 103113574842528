import React from 'react';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import PropTypes from 'prop-types';
import Tooltip from '../common/tooltip';

function IOSBadgeToggle ({
	i18n,
	isIOSBadgeToggleEnabled,
	setIOSBadgeToggleValue
}) {
	return (
		<div className="ios-badge-toggle-container">
			<div className="container-heading">{i18n.get('ios_badge_toggle_container_heading')}</div>
			<Tooltip
				i18n={i18n}
				cid="mp-message-inappbadge"
			/>
			<Checkbox
				id="ios-badge-toggle-checkbox"
				variant="toggle"
				labels={{
					toggleEnabled: i18n.get('enabled'),
					toggleDisabled: i18n.get('disabled')
				}}
				checked={isIOSBadgeToggleEnabled}
				onChange={setIOSBadgeToggleValue}
			/>
		</div>
	);
}

IOSBadgeToggle.propTypes = {
	i18n: PropTypes.object.isRequired,
	isIOSBadgeToggleEnabled: PropTypes.bool.isRequired,
	setIOSBadgeToggleValue: PropTypes.func.isRequired
};

export default IOSBadgeToggle;

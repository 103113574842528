module.exports = function(scm) {
    return {
        viewMessage: {
            text: scm.i18n.get('majik_ce_summary_view_message_btn'),
            onClick: function() {
                scm.show('messageDefinition', {action: 'edit-message'});
            }
        },
        editMessage: {
            text: scm.i18n.get('majik_ce_summary_edit_message_btn'),
            onClick: function() {
                scm.show('messageDefinition', {action: 'edit-message'});
            }
        },
        selectDifferentMessage: {
            text: scm.i18n.get('majik_select_different_message'),
            onClick: function() {
                scm.show('messageDefinition');
            }
        },
        createNewMessage: {
            text: scm.i18n.get('majik_ce_create_new_message'),
            onClick: function() {
                scm.show('messageDefinition', {action: 'new-message'});
            }
        },
        previewSubscribers: {
            text: scm.i18n.get('majik_ce_preview_subscribers'),
            onClick: function() {
                scm.show('messageDefinition', {action: 'preview-subscriber'});
            }
        }
    };
};

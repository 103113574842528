// const Postmonger = require('postmonger');
import Postmonger from 'postmonger';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_CULTURE_CODE } from '../constants';

const PostmongerStore = {
	connection: null,
	jbPayload: {},
	cultureCode: DEFAULT_CULTURE_CODE,
	devMode: false,

	getConnection: () => {
		if (!PostmongerStore.connection) {
			PostmongerStore.connection = new Postmonger.Session();
		}

		return PostmongerStore.connection;
	},

	entrySourceData: {},

	br: {},

	isBROn: (brName) => {
		if (isEmpty(PostmongerStore.br) || PostmongerStore.br[brName] === undefined) {
			return false;
		}

		return PostmongerStore.br[brName].data === 1;
	}
};

export default PostmongerStore;

import Previewer from '@salesforce-mc/mobile-previewer';

export default function (dom, config) {
	const { contentData, i18n, moment, contentName } = config;
	const device = 'iphone';
	const deviceView = 'lock-screen';
	const dateFormat = 'dddd, MMMM D';
	const timeFormat = 'h:mm';

	const parseContent = () => {
		const channel = (contentData.channel) ? contentData.channel.toLowerCase() : '';
		const date = moment().format(dateFormat);
		const time = moment().format(timeFormat);
		const data = {
			channel,
			device,
			deviceView,
			date,
			time,
			applicationIconUrl: '',
			applicationName: 'App Name',
			timestamp: i18n.get('now').toLowerCase(),
			title: contentData['display:title:display'] || '',
			subtitle: contentData['display:subtitle:display'] || '',
			message: contentData['display:message:display'] || '',
			mediaUrl: contentData['display:media:url:ios'] || '',
			action: i18n.get('press_for_more')
		};

		return data;
	};

	const renderPreviewer = () => {
		const getPage = () => {
			const data = parseContent();
			const previewer = new Previewer(data);

			return previewer.getPage();
		};

		const container = dom.querySelector('.previewer');
		const iframe = document.createElement('iframe');

		iframe.id = 'previewer';

		container.innerHTML = '';
		container.appendChild(iframe);

		const doc = iframe.contentWindow.document;

		doc.open();
		doc.write(getPage());
		doc.close();
	};

	const render = () => {
		dom.innerHTML = `
			<div class="wrapper">
				<div class="header">
					<div class="message-name">${contentName}</div>
					<div class="warning slds-text-color_inverse-weak">${i18n.get('detail_view_personalization_warning')}</div>
				</div>
				<div class="previewer"></div>
			</div>
		`;
	};

	const initialize = () => {
		render();
		renderPreviewer();
	};

	initialize();
}

const compile = require('./compile').compile;
const COMPILE_MODES = require('./constants/compile-modes');

function getHashFromString (str) {
	/*eslint-disable no-bitwise*/
	var hash = 0, charCode;
	if (str.length === 0) {
		return hash;
	}

	for (var i = 0; i < str.length; i++) {
		charCode = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + charCode;
		hash |= 0;
	}

	return hash;
}

function getContentHash (asset) {
	asset = asset || {};
	var hash = asset && asset.meta && asset.meta.contentHash;
	if (hash) {
		return hash;
	}

	return getHashFromString(compile(asset, null, COMPILE_MODES.INCLUDE_MARKERS));
}

function haveSameContent (asset1, asset2) {
	asset1 = asset1 || {};
	asset2 = asset2 || {};

	return getContentHash(asset1) === getContentHash(asset2) && _locksMatch(asset1, asset2);
}

function _locksMatch (asset1, asset2) {
	const slots1 = asset1.slots || {};
	const slots2 = asset2.slots || {};

	for (let key in slots1) {
		if (!slots2[key] || !!slots1[key].locked !== !!slots2[key].locked) {
			return false;
		}
	}
	return true;
}

function getMinimalComparableAsset (asset) {
	asset = asset || {};
	asset.slots = asset.slots || {};

	const result = {
		assetType: asset.assetType,
		id: asset.id || 0,
		name: asset.name || '',
		content: '',
		meta: {
			contentHash: getContentHash(asset)
		},
		slots: {}
	};

	for (let key in asset.slots) {
		result.slots[key] = {
			locked: asset.slots[key].locked || false
		};
	}

	return result;
}

module.exports = {
	haveSameContent: haveSameContent,
	getContentHash: getContentHash,
	getMinimalComparableAsset: getMinimalComparableAsset
};

var SummaryViewHtml = require('./templates/summary-view.html');
var InputComponent = require('./input-component');
var clearElement = require('./clear-element');

var ACTIVITY_NAME_MAX_LEN = 200;
var ACTIVITY_DESC_MAX_LEN = 1000;

module.exports = function SummaryDecorator(options) {
    var MAJIK = options.MAJIK;
    var scm = options.scm;
    var view = options.view;
    var templateHtml = MAJIK.Mustache.render(SummaryViewHtml, {
        majik_ce_activity_name: scm.i18n.get('majik_ce_activity_name'),
        majik_ce_activity_desc: scm.i18n.get('majik_ce_activity_desc')
    });

    this.element = document.createElement('div');
    this.element.classList.add('summary-view-container');
    this.element.style.display = 'flex';
    this.element.style.width = '100%';
    this.element.style.height = '100%';

    this.ui = null;
    this.activityNameInput = null;
    this.activityDescInput = null;
    this.state = {
        readonly: false,
        activityName: '',
        activityNameError: null,
        activityDescription: '',
        activityDescriptionError: null,
        isDetailViewVisible: false
    };

    this.onBeforeViewShow = function() {
        this.state.readonly = scm.isReadOnly();
        scm.element.appendChild(this.element);
        this.render();
    };

    this.onViewHide = function() {
        this.ui = null;
        this.activityNameInput = null;
        this.activityDescInput = null;
        this.state.isDetailViewVisible = false;
    };

    this.onReadonlyStateChange = function(e) {
        this.state.readOnly = e.isReadOnly;
        if (this.activityNameInput) {
            this.activityNameInput.setReadOnly(this.state.readOnly);
        }
        if (this.activityDescInput) {
            this.activityDescInput.setReadOnly(this.state.readOnly);
        }
    };

    this.render = function() {
        var el = this.element;

        // IE11 bug fix: if we don't clear the detail view,
        // then `el.innerHTML = templateHtml` will clobber everything attached
        // to the dom, including the user's custom detail view component
        clearElement(el.querySelector('#detail-view-container'));

        el.innerHTML = templateHtml;

        this.ui = {
            activityName: el.querySelector('.activity-name-container'),
            activityDesc: el.querySelector('.activity-description-container'),
            detailView: el.querySelector('#detail-view-container'),
            cardContainer: el.querySelector('#summary-card-container')
        };

        this.activityNameInput = new InputComponent({
            Mustache: MAJIK.Mustache,
            id: 'activity-name',
            label: scm.i18n.get('majik_ce_activity_name'),
            parent: this.ui.activityName,
            disabled: this.state.readonly,
            value: this.state.activityName,
            onCommit: this.onActivityNameCommit.bind(this),
            maxLength: ACTIVITY_NAME_MAX_LEN,
            required: options.isActivityNameRequired
        });

        this.activityDescInput = new InputComponent({
            Mustache: MAJIK.Mustache,
            id: 'activity-description',
            label: scm.i18n.get('majik_ce_activity_desc'),
            parent: this.ui.activityDesc,
            disabled: this.state.readonly,
            value: this.state.activityDescription,
            onCommit: this.onActivityDescCommit.bind(this),
            maxLength: ACTIVITY_DESC_MAX_LEN
        });

        if (this.state.activityNameError) {
            this.setActivityNameError(this.state.activityNameError);
        }

        if (this.state.activityDescriptionError) {
            this.setDescriptionError(this.state.activityDescriptionError);
        }

        this.updateDetailViewButtonVisibility();
        this.renderDetailView();
    };

    this.setActivityNameReadOnly = function(value) {
        if (this.activityNameInput) {
            this.activityNameInput.setReadOnly(Boolean(value));
        }
    };

    this.setDescriptionReadOnly = function(value) {
        if (this.activityDescInput) {
            this.activityDescInput.setReadOnly(Boolean(value));
        }
    };

    this.getActivityName = function() {
        return this.state.activityName;
    };

    this.setActivityName = function(value) {
        this.state.activityName = value;
        if (this.activityNameInput) {
            this.activityNameInput.value(value);
        }
    };

    this.setActivityNameError = function(value) {
        this.state.activityNameError = value;
        if (this.activityNameInput) {
            this.activityNameInput.setErrorMessage(value);
        }
    };

    this.getDescription = function() {
        return this.state.activityDescription;
    };

    this.setDescription = function(value) {
        this.state.activityDescription = value;
        if (this.activityDescInput) {
            this.activityDescInput.value(value);
        }
    };

    this.setDescriptionError = function(value) {
        this.state.activityDescriptionError = value;
        if (this.activityDescInput) {
            this.activityDescInput.setErrorMessage(value);
        }
    };

    this.onActivityNameCommit = function() {
        this.state.activityName = this.activityNameInput.value();
        invokeFunction(view, 'onActivityNameUpdated', {
            value: this.state.activityName
        });
    };

    this.onActivityDescCommit = function() {
        this.state.activityDescription = this.activityDescInput.value();
        invokeFunction(view, 'onDescriptionUpdated', {
            value: this.state.activityDescription
        });
    };

    this.toggleDetailView = function() {
        if (this.state.isDetailViewVisible) {
            this.hideDetailView();
        } else {
            this.showDetailView();
        }
    };

    this.showDetailView = function() {
        if (this.state.isDetailViewVisible === false) {
            this.state.isDetailViewVisible = true;
            this.expandMetadata();
            this.renderDetailView();
            invokeFunction(view, 'onDetailedViewOpened', {
                element: this.ui.detailView
            });
        }
    };

    this.hideDetailView = function() {
        if (this.state.isDetailViewVisible === true) {
            this.state.isDetailViewVisible = false;
            this.renderDetailView();
            this.collapseMetadata();
            invokeFunction(view, 'onDetailedViewClosed', {
                element: this.ui.detailView
            });
        }
    };

    this.renderDetailView = function() {
        if (this.state.isDetailViewVisible) {
            scm.resize(MAJIK.DRAWER_SIZE.FULL);
            scm.setFooterButtonText('detail-btn', scm.i18n.get('majik_ce_summary_view_btn'));
            this.element.setAttribute('data-detail', this.state.isDetailViewVisible);
            this.ui.detailView.style.display = 'block';
        } else {
            scm.resize(MAJIK.DRAWER_SIZE.LARGE);
            scm.setFooterButtonText('detail-btn', scm.i18n.get('majik_ce_detail_view_btn'));
            this.element.setAttribute('data-detail', this.state.isDetailViewVisible);
            this.ui.detailView.style.display = 'none';
        }
    };

    this.expandMetadata = function() {
        // this grab all scm-metadata elements that are collapsed
        // and expand them by clicking on the "show more" link
        var toggleLink = this.element.querySelectorAll('.scm-metadata-below.slds-hide + .scm-metadata-link a');
        if (toggleLink) {
            for (var i = 0, l = toggleLink.length; i < l; i++) {
                toggleLink[i].click();
            }
        }
    };

    this.collapseMetadata = function() {
        // this grab all scm-metadata elements that are expanded
        // and collapse them by clicking on the "show more" link
        var toggleLink = this.element.querySelectorAll('.scm-metadata-below:not(.slds-hide) + .scm-metadata-link a');
        if (toggleLink) {
            for (var i = 0, l = toggleLink.length; i < l; i++) {
                toggleLink[i].click();
            }
        }
    };

    this.updateDetailViewButtonVisibility = function() {
        // the detail button should only be visible if content is selected
        var messageDefinitionView = scm.config.views.messageDefinition;
        var isContentSelected = messageDefinitionView && messageDefinitionView.getContent();
        if (isContentSelected) {
            scm.showFooterButton('detail-btn');
        } else {
            scm.hideFooterButton('detail-btn');
        }
    };

    function invokeFunction(object, functionName, args) {
        if (typeof object[functionName] === 'function') {
            return object[functionName](args, scm);
        }
        return null;
    }
};

export const SET_NOTIFICATION_SOUND = 'SET_NOTIFICATION_SOUND';
export const SET_INTERACTIVE_NOTIFICATION = 'SET_INTERACTIVE_NOTIFICATION';
export const SET_IOS_BADGE_TOGGLE_VALUE = 'SET_IOS_BADGE_TOGGLE_VALUE';
export const SET_SEND_WINDOW_TOGGLE_VALUE = 'SET_SEND_WINDOW_TOGGLE_VALUE';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_SEND_WINDOW_TIME = 'SET_SEND_WINDOW_TIME';
export const SET_SEND_WINDOW_TIMEZONE = 'SET_SEND_WINDOW_TIMEZONE';

export const setNotificationSound = (sound) => ({
	type: SET_NOTIFICATION_SOUND,
	sound
});

export const setInteractiveNotification = (notification) => ({
	type: SET_INTERACTIVE_NOTIFICATION,
	notification
});

export const setIOSBadgeToggleValue = (value) => ({
	type: SET_IOS_BADGE_TOGGLE_VALUE,
	value
});

export const setSendWindowToggleValue = (value) => ({
	type: SET_SEND_WINDOW_TOGGLE_VALUE,
	value
});

export const setCampaign = (value) => ({
	type: SET_CAMPAIGN,
	value
});

export const setSendWindowTime = (time) => ({
	type: SET_SEND_WINDOW_TIME,
	time
});

export const setSendWindowTimezone = (timezone) => ({
	type: SET_SEND_WINDOW_TIMEZONE,
	timezone
});

import moment from 'moment-timezone';

const tzList = [
	{
		name: '(GMT) Casablanca *',
		gold: 'tzone_Casablanca',
		value: 'Morocco Standard Time',
		ianaName: 'Africa/Casablanca',
		timeZoneId: '2'
	},
	{
		name: '(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London *',
		gold: 'tzone_DublinEdinburghLisbonLondon',
		value: 'GMT Standard Time',
		ianaName: 'Europe/London',
		timeZoneId: '3'
	},
	{
		name: '(GMT) Monrovia, Reykjavik',
		gold: 'tzone_MonroviaReykjavik',
		value: 'Greenwich Standard Time',
		ianaName: 'Atlantic/Reykjavik',
		timeZoneId: '4'
	},
	{
		name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna *',
		gold: 'tzone_AmsterdamBerlinBernRomeStockholmVienna',
		value: 'W. Europe Standard Time',
		ianaName: 'Europe/Berlin',
		timeZoneId: '5'
	},
	{
		name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague *',
		gold: 'tzone_BelgradeBratislavaBudapestLjubljanaPrague',
		value: 'Central Europe Standard Time',
		ianaName: 'Europe/Budapest',
		timeZoneId: '6'
	},
	{
		name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris *',
		gold: 'tzone_BrusselsCopenhagenMadridParis',
		value: 'Romance Standard Time',
		ianaName: 'Europe/Paris',
		timeZoneId: '7'
	},
	{
		name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb *',
		gold: 'tzone_SarajevoSkopjeWarsawZagreb',
		value: 'Central European Standard Time',
		ianaName: 'Europe/Warsaw',
		timeZoneId: '8'
	},
	{
		name: '(GMT+01:00) West Central Africa',
		gold: 'tzone_WestCentralAfrica',
		value: 'W. Central Africa Standard Time',
		ianaName: 'Africa/Lagos',
		timeZoneId: '9'
	},
	{
		name: '(GMT+02:00) Amman *',
		gold: 'tzone_Amman',
		value: 'Jordan Standard Time',
		ianaName: 'Asia/Amman',
		timeZoneId: '10'
	},
	{
		name: '(GMT+02:00) Athens, Bucharest, Istanbul *',
		gold: 'tzone_AthensBucharestIstanbul',
		value: 'GTB Standard Time',
		ianaName: 'Europe/Bucharest',
		timeZoneId: '11'
	},
	{
		name: '(GMT+02:00) Beirut *',
		gold: 'tzone_Beirut',
		value: 'Middle East Standard Time',
		ianaName: 'Asia/Beirut',
		timeZoneId: '12'
	},
	{
		name: '(GMT+02:00) Cairo *',
		gold: 'tzone_Cairo',
		value: 'Egypt Standard Time',
		ianaName: 'Africa/Cairo',
		timeZoneId: '13'
	},
	{
		name: '(GMT+02:00) Harare, Pretoria',
		gold: 'tzone_HararePretoria',
		value: 'South Africa Standard Time',
		ianaName: 'Africa/Johannesburg',
		timeZoneId: '14'
	},
	{
		name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius *',
		gold: 'tzone_HelsinkiKyivRigaSofiaTallinnVilnius',
		tz: 'Europe/Zaporozhye',
		value: 'FLE Standard Time',
		ianaName: 'Europe/Kiev',
		timeZoneId: '15'
	},
	{
		name: '(GMT+02:00) Jerusalem *',
		gold: 'tzone_Jerusalem',
		value: 'Israel Standard Time',
		ianaName: 'Asia/Jerusalem',
		timeZoneId: '16'
	},
	{
		name: '(GMT+02:00) Minsk *',
		gold: 'tzone_Minsk',
		value: 'E. Europe Standard Time',
		ianaName: 'Europe/Minsk',
		timeZoneId: '17'
	},
	{
		name: '(GMT+02:00) Windhoek *',
		gold: 'tzone_Windhoek',
		value: 'Namibia Standard Time',
		ianaName: 'Africa/Windhoek',
		timeZoneId: '18'
	},
	{
		name: '(GMT+03:00) Baghdad *',
		gold: 'tzone_Baghdad',
		value: 'Arabic Standard Time',
		ianaName: 'Asia/Baghdad',
		timeZoneId: '19'
	},
	{
		name: '(GMT+03:00) Kuwait, Riyadh',
		gold: 'tzone_KuwaitRiyadh',
		value: 'Arab Standard Time',
		ianaName: 'Asia/Riyadh',
		timeZoneId: '20'
	},
	{
		name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd *',
		gold: 'tzone_MoscowStPetersburgVolgograd',
		value: 'Russian Standard Time',
		ianaName: 'Europe/Moscow',
		timeZoneId: '21'
	},
	{
		name: '(GMT+03:00) Nairobi',
		gold: 'tzone_Nairobi',
		value: 'E. Africa Standard Time',
		ianaName: 'Africa/Nairobi',
		timeZoneId: '22'
	},
	{
		name: '(GMT+03:00) Tbilisi',
		gold: 'tzone_Tbilisi',
		value: 'Georgian Standard Time',
		ianaName: 'Asia/Tbilisi',
		timeZoneId: '23'
	},
	{
		name: '(GMT+03:30) Tehran *',
		gold: 'tzone_Tehran',
		value: 'Iran Standard Time',
		ianaName: 'Asia/Tehran',
		timeZoneId: '24'
	},
	{
		name: '(GMT+04:00) Abu Dhabi, Muscat',
		gold: 'tzone_AbuDhabiMuscat',
		value: 'Arabian Standard Time',
		ianaName: 'Asia/Dubai',
		timeZoneId: '25'
	},
	{
		name: '(GMT+04:00) Baku *',
		gold: 'tzone_Baku',
		value: 'Azerbaijan Standard Time',
		ianaName: 'Asia/Baku',
		timeZoneId: '26'
	},
	{
		name: '(GMT+04:00) Caucasus Standard Time',
		gold: 'tzone_CaucasusStandardTime',
		value: 'Caucasus Standard Time',
		ianaName: 'Asia/Yerevan',
		timeZoneId: '27'
	},
	{
		name: '(GMT+04:00) Port Louis *',
		gold: 'tzone_PortLouis',
		value: 'Mauritius Standard Time',
		ianaName: 'Indian/Mauritius',
		timeZoneId: '28'
	},
	{
		name: '(GMT+04:00) Yerevan',
		gold: 'tzone_Yerevan',
		value: 'Caucasus Standard Time',
		ianaName: 'Asia/Yerevan',
		timeZoneId: '29'
	},
	{
		name: '(GMT+04:30) Kabul',
		gold: 'tzone_Kabul',
		value: 'Afghanistan Standard Time',
		ianaName: 'Asia/Kabul',
		timeZoneId: '30'
	},
	{
		name: '(GMT+05:00) Ekaterinburg *',
		gold: 'tzone_Ekaterinburg',
		value: 'Ekaterinburg Standard Time',
		ianaName: 'Asia/Yekaterinburg',
		timeZoneId: '31'
	},
	{
		name: '(GMT+05:00) Islamabad, Karachi *',
		gold: 'tzone_IslamabadKarachi',
		value: 'Pakistan Standard Time',
		ianaName: 'Asia/Karachi',
		timeZoneId: '32'
	},
	{
		name: '(GMT+05:00) Tashkent',
		gold: 'tzone_Tashkent',
		value: 'West Asia Standard Time',
		ianaName: 'Asia/Tashkent',
		timeZoneId: '33'
	},
	{
		name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
		gold: 'tzone_ChennaiKolkataMumbaiNewDelhi',
		value: 'India Standard Time',
		ianaName: 'Asia/Calcutta',
		timeZoneId: '34'
	},
	{
		name: '(GMT+05:30) Sri Jayawardenepura',
		gold: 'tzone_SriJayawardenepura',
		value: 'Sri Lanka Standard Time',
		ianaName: 'Asia/Colombo',
		timeZoneId: '35'
	},
	{
		name: '(GMT+05:45) Kathmandu',
		gold: 'tzone_Kathmandu',
		value: 'Nepal Standard Time',
		ianaName: 'Asia/Katmandu',
		timeZoneId: '36'
	},
	{
		name: '(GMT+06:00) Almaty, Novosibirsk *',
		gold: 'tzone_Novosibirsk',
		value: 'N. Central Asia Standard Time',
		ianaName: 'Asia/Novosibirsk',
		timeZoneId: '37'
	},
	{
		name: '(GMT+06:00) Astana, Dhaka',
		gold: 'tzone_Astana',
		value: 'Central Asia Standard Time',
		ianaName: 'Asia/Almaty',
		hasDST: false,
		offset: {
			string: '+06:00',
			num: 6
		},
		timeZoneId: '38'
	},
	{
		name: '(GMT+06:30) Yangon (Rangoon)',
		gold: 'tzone_Yangon(Rangoon)',
		value: 'Myanmar Standard Time',
		ianaName: 'Asia/Rangoon',
		timeZoneId: '39'
	},
	{
		name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
		gold: 'tzone_BangkokHanoiJakarta',
		value: 'SE Asia Standard Time',
		ianaName: 'Asia/Bangkok',
		timeZoneId: '40'
	},
	{
		name: '(GMT+07:00) Krasnoyarsk *',
		gold: 'tzone_Krasnoyarsk',
		value: 'North Asia Standard Time',
		ianaName: 'Asia/Krasnoyarsk',
		timeZoneId: '41'
	},
	{
		name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
		gold: 'tzone_BeijingChongqingHongKongUrumqi',
		value: 'China Standard Time',
		ianaName: 'Asia/Shanghai',
		timeZoneId: '42'
	},
	{
		name: '(GMT+08:00) Irkutsk, Ulaan Bataar *',
		gold: 'tzone_Irkutsk',
		value: 'North Asia East Standard Time',
		ianaName: 'Asia/Irkutsk',
		timeZoneId: '43'
	},
	{
		name: '(GMT+08:00) Kuala Lumpur, Singapore',
		gold: 'tzone_KualaLumpurSingapore',
		value: 'Singapore Standard Time',
		ianaName: 'Asia/Singapore',
		timeZoneId: '44'
	},
	{
		name: '(GMT+08:00) Perth *',
		gold: 'tzone_Perth',
		value: 'W. Australia Standard Time',
		ianaName: 'Australia/Perth',
		timeZoneId: '45'
	},
	{
		name: '(GMT+08:00) Taipei',
		gold: 'tzone_Taipei',
		value: 'Taipei Standard Time',
		ianaName: 'Asia/Taipei',
		timeZoneId: '46'
	},
	{
		name: '(GMT+09:00) Osaka, Sapporo, Tokyo',
		gold: 'tzone_OsakaSapporoTokyo',
		value: 'Tokyo Standard Time',
		ianaName: 'Asia/Tokyo',
		timeZoneId: '47'
	},
	{
		name: '(GMT+09:00) Seoul',
		gold: 'tzone_Seoul',
		value: 'Korea Standard Time',
		ianaName: 'Asia/Seoul',
		timeZoneId: '48'
	},
	{
		name: '(GMT+09:00) Yakutsk *',
		gold: 'tzone_Yakutsk',
		value: 'Yakutsk Standard Time',
		ianaName: 'Asia/Yakutsk',
		timeZoneId: '49'
	},
	{
		name: '(GMT+09:30) Adelaide *',
		gold: 'tzone_Adelaide',
		value: 'Cen. Australia Standard Time',
		ianaName: 'Australia/Adelaide',
		timeZoneId: '50'
	},
	{
		name: '(GMT+09:30) Darwin',
		gold: 'tzone_Darwin',
		value: 'AUS Central Standard Time',
		ianaName: 'Australia/Darwin',
		timeZoneId: '51'
	},
	{
		name: '(GMT+10:00) Brisbane',
		gold: 'tzone_Brisbane',
		value: 'E. Australia Standard Time',
		ianaName: 'Australia/Brisbane',
		timeZoneId: '52'
	},
	{
		name: '(GMT+10:00) Canberra, Melbourne, Sydney *',
		gold: 'tzone_CanberraMelbourneSydney',
		value: 'AUS Eastern Standard Time',
		ianaName: 'Australia/Sydney',
		timeZoneId: '53'
	},
	{
		name: '(GMT+10:00) Guam, Port Moresby',
		gold: 'tzone_GuamPortMoresby',
		value: 'West Pacific Standard Time',
		ianaName: 'Pacific/Guam',
		timeZoneId: '54'
	},
	{
		name: '(GMT+10:00) Hobart *',
		gold: 'tzone_Hobart',
		value: 'Tasmania Standard Time',
		ianaName: 'Australia/Hobart',
		timeZoneId: '55'
	},
	{
		name: '(GMT+10:00) Vladivostok *',
		gold: 'tzone_Vladivostok',
		value: 'Vladivostok Standard Time',
		ianaName: 'Asia/Vladivostok',
		timeZoneId: '56'
	},
	{
		name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
		gold: 'tzone_MagadanSolomonIsNewCaledonia',
		value: 'Central Pacific Standard Time',
		ianaName: 'Pacific/Guadalcanal',
		timeZoneId: '57'
	},
	{
		name: '(GMT+12:00) Auckland, Wellington *',
		gold: 'tzone_AucklandWellington',
		value: 'New Zealand Standard Time',
		ianaName: 'Pacific/Auckland',
		timeZoneId: '58'
	},
	{
		name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
		gold: 'tzone_FijiKamchatkaMarshallIs',
		value: 'Fiji Standard Time',
		ianaName: 'Pacific/Fiji',
		timeZoneId: '59'
	},
	{
		name: '(GMT+13:00) Nukualofa',
		gold: 'tzone_Nukualofa',
		value: 'Tonga Standard Time',
		ianaName: 'Pacific/Tongatapu',
		timeZoneId: '60'
	},
	{
		name: '(GMT-01:00) Azores *',
		gold: 'tzone_Azores',
		value: 'Azores Standard Time',
		ianaName: 'Atlantic/Azores',
		timeZoneId: '61'
	},
	{
		name: '(GMT-01:00) Cape Verde Is.',
		gold: 'tzone_CapeVerdeIs',
		value: 'Cape Verde Standard Time',
		ianaName: 'Atlantic/Cape_Verde',
		timeZoneId: '62'
	},
	{
		name: '(GMT-02:00) Mid-Atlantic *',
		gold: 'tzone_Mid-Atlantic',
		value: 'Mid-Atlantic Standard Time',
		ianaName: 'America/Halifax',
		timeZoneId: '63'
	},
	{
		name: '(GMT-03:00) Brasilia *',
		gold: 'tzone_Brasilia',
		value: 'E. South America Standard Time',
		ianaName: 'America/Sao_Paulo',
		timeZoneId: '64'
	},
	{
		name: '(GMT-03:00) Buenos Aires *',
		gold: 'tzone_BuenosAires',
		value: 'Argentina Standard Time',
		ianaName: 'America/Buenos_Aires',
		timeZoneId: '65'
	},
	{
		name: '(GMT-03:00) Georgetown',
		gold: 'tzone_Georgetown',
		value: 'SA Western Standard Time',
		ianaName: 'America/La_Paz',
		timeZoneId: '66'
	},
	{
		name: '(GMT-03:00) Greenland *',
		gold: 'tzone_Greenland',
		value: 'Greenland Standard Time',
		ianaName: 'America/Godthab',
		timeZoneId: '67'
	},
	{
		name: '(GMT-03:00) Montevideo *',
		gold: 'tzone_Montevideo',
		value: 'Montevideo Standard Time',
		ianaName: 'America/Montevideo',
		timeZoneId: '68'
	},
	{
		name: '(GMT-03:30) Newfoundland *',
		gold: 'tzone_Newfoundland',
		tz: 'America/St_Johns',
		value: 'Newfoundland Standard Time',
		ianaName: 'America/St_Johns',
		timeZoneId: '69'
	},
	{
		name: '(GMT-04:00) Atlantic Time (Canada) *',
		gold: 'tzone_AtlanticTime_Canada',
		value: 'Atlantic Standard Time',
		ianaName: 'America/Halifax',
		timeZoneId: '70'
	},
	{
		name: '(GMT-04:00) La Paz',
		gold: 'tzone_LaPaz',
		value: 'SA Western Standard Time',
		ianaName: 'America/La_Paz',
		timeZoneId: '71'
	},
	{
		name: '(GMT-04:00) Manaus *',
		gold: 'tzone_Manaus',
		value: 'Central Brazilian Standard Time',
		ianaName: 'America/Cuiaba',
		timeZoneId: '72'
	},
	{
		name: '(GMT-04:00) Santiago *',
		gold: 'tzone_Santiago',
		value: 'Pacific SA Standard Time',
		ianaName: 'America/Santiago',
		timeZoneId: '73'
	},
	{
		name: '(GMT-04:30) Caracas',
		gold: 'tzone_Caracas',
		value: 'Venezuela Standard Time',
		ianaName: 'America/Caracas',
		timeZoneId: '74'
	},
	{
		name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
		gold: 'tzone_BogotaLimaQuito',
		value: 'SA Pacific Standard Time',
		ianaName: 'America/Bogota',
		timeZoneId: '75'
	},
	{
		name: '(GMT-05:00) Eastern Time (US & Canada) *',
		gold: 'tzone_EasternTime_US_Canada',
		value: 'Eastern Standard Time',
		ianaName: 'America/New_York',
		timeZoneId: '76'
	},
	{
		name: '(GMT-05:00) Indiana (East)',
		gold: 'tzone_Indiana_East',
		value: 'US Eastern Standard Time',
		ianaName: 'America/Indianapolis',
		timeZoneId: '77'
	},
	{
		name: '(GMT-06:00) Central America',
		gold: 'tzone_CentralAmerica',
		value: 'Central America Standard Time',
		ianaName: 'America/Guatemala',
		timeZoneId: '78'
	},
	{
		name: '(GMT-06:00) Central Standard Time (No Daylight Savings)',
		gold: 'tzone_CentralTime_NoDaylightSavings',
		tz: 'America/Regina',
		value: 'Central Standard Time (no DST)',
		hasDST: false,
		ianaName: 'America/Chicago',
		timeZoneId: '1',
		offset: {
			string: '-06:00',
			num: -6
		}
	},
	{
		name: '(GMT-06:00) Central Time (US & Canada) *',
		gold: 'tzone_CentralTime_US_Canada',
		tz: 'America/Chicago',
		value: 'Central Standard Time',
		ianaName: 'America/Chicago',
		timeZoneId: '79'
	},
	{
		name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey - New *',
		gold: 'tzone_GuadalajaraMexicoCityMonterrey',
		value: 'Central Standard Time (Mexico)',
		ianaName: 'America/Mexico_City',
		timeZoneId: '80'
	},
	{
		name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey - Old',
		gold: 'tzone_GuadalajaraMexicoCityMonterreyNoDst',
		value: 'Central Standard Time (Mexico)',
		ianaName: 'America/Mexico_City',
		timeZoneId: '81'
	},
	{
		name: '(GMT-06:00) Saskatchewan',
		gold: 'tzone_Saskatchewan',
		value: 'Canada Central Standard Time',
		ianaName: 'America/Regina',
		timeZoneId: '82'
	},
	{
		name: '(GMT-07:00) Arizona',
		gold: 'tzone_Arizona',
		value: 'US Mountain Standard Time',
		ianaName: 'America/Phoenix',
		timeZoneId: '83'
	},
	{
		name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan - New *',
		gold: 'tzone_ChihuahuaLaPazMazatlan',
		value: 'Mountain Standard Time (Mexico)',
		ianaName: 'America/Chihuahua',
		timeZoneId: '84'
	},
	{
		name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan - Old',
		gold: 'tzone_ChihuahuaLaPazMazatlanNoDst',
		value: 'Mountain Standard Time (Mexico)',
		ianaName: 'America/Chihuahua',
		timeZoneId: '85'
	},
	{
		name: '(GMT-07:00) Mountain Time (US & Canada) *',
		gold: 'tzone_MountainTime_US_Canada',
		value: 'Mountain Standard Time',
		ianaName: 'America/Denver',
		timeZoneId: '86'
	},
	{
		name: '(GMT-08:00) Pacific Time (US & Canada) *',
		gold: 'tzone_PacificTime_US_Canada',
		value: 'Pacific Standard Time',
		ianaName: 'America/Los_Angeles',
		timeZoneId: '87'
	},
	{
		name: '(GMT-08:00) Tijuana, Baja California *',
		gold: 'tzone_TijuanaBajaCalifornia',
		value: 'Pacific Standard Time (Mexico)',
		ianaName: 'America/Santa_Isabel',
		timeZoneId: '88'
	},
	{
		name: '(GMT-09:00) Alaska *',
		gold: 'tzone_Alaska',
		value: 'Alaskan Standard Time',
		ianaName: 'America/Anchorage',
		timeZoneId: '89'
	},
	{
		name: '(GMT-10:00) Hawaii',
		gold: 'tzone_Hawaii',
		value: 'Hawaiian Standard Time',
		ianaName: 'Pacific/Honolulu',
		timeZoneId: '90'
	},
	{
		name: '(GMT-11:00) Midway Island, Samoa',
		gold: 'tzone_MidwayIslandSamoa',
		value: 'Samoa Standard Time',
		ianaName: 'Pacific/Apia',
		timeZoneId: '91'
	},
	{
		name: '(GMT-12:00) International Date Line West',
		gold: 'tzone_InternationalDateLineWest',
		value: 'Dateline Standard Time',
		ianaName: 'Etc/GMT+12',
		timeZoneId: '92'
	}
];

const TIMEZONES = tzList.map((tz) => {
	// check if this timezone DST falls in Jan1 or July1
	const isDST = moment.tz(tz.ianaName).month(1).date(1).isDST() || moment.tz(tz.ianaName).month(7).date(1).isDST();
	const curDate = moment.tz(tz.ianaName);

	return {
		...tz,
		hasDST: Object.prototype.hasOwnProperty.call(tz, 'hasDST') ? tz.hasDST : isDST,
		offset: Object.prototype.hasOwnProperty.call(tz, 'offset') ? tz.offset : {
			string: curDate.format('Z'),
			num: curDate.utcOffset() / 60
		}
	};
});

export default TIMEZONES;

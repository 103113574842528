import { handleActions } from 'redux-actions';
import { SET_MOBILEPUSH_REPORTING_NAME } from '../actions/advanced-options';
import initialState from './InitialState';

export default handleActions(
	{
		[SET_MOBILEPUSH_REPORTING_NAME]: (state, action) => ({
			...state,
			mobilePushReportingName: action.name
		})
	},
	{
		mobilePushReportingName: initialState.mobilePushReportingName
	}
);

import React, { useEffect } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import isEmpty from 'lodash/isEmpty';
import TimezoneUtils from '../../utilities/timezone';
import Tooltip from '../common/tooltip';

export const getTimezoneSelection = (selectedTimezone, i18n) => (
	isEmpty(selectedTimezone) ? [] : [{
		...selectedTimezone,
		label: i18n.get(selectedTimezone.label)
	}]
);

function TimezonePicker ({
	i18n,
	enabled,
	selectedTimezone,
	onSelectTimezone,
	validationState
}) {
	const timezonePickerRef = React.createRef();

	useEffect(() => { // Similar to componentDidUpdate
		if (timezonePickerRef) {
			// Add disabled attribute to Timezone Picker input component by default
			const timezonePickerInput = timezonePickerRef.current.querySelector('input');
			const timezoneFormEl = timezonePickerRef.current.querySelector('.slds-form-element');

			if (enabled) {
				timezonePickerInput.removeAttribute('disabled');
			} else {
				timezonePickerInput.setAttribute('disabled', 'disabled');
			}

			// Validate timezone picker
			if (validationState && validationState === 'error') {
				if (isEmpty(selectedTimezone) && !timezoneFormEl.classList.contains('slds-has-error')) {
					timezoneFormEl.classList.add('slds-has-error');
				}

				if (!isEmpty(selectedTimezone) && timezoneFormEl.classList.contains('slds-has-error')) {
					timezoneFormEl.classList.remove('slds-has-error');
				}
			}
		}
	});

	return (
		<div className="timezone-picker" ref={timezonePickerRef}>
			<Combobox
				id="timezone-dropdown"
				options={TimezoneUtils.getTimezonesForCombobox(i18n)}
				labels={{
					label: i18n.get('custom_timeframe_scheduler_timezone'),
					placeholderReadOnly: i18n.get('push_send_window_timezonepicker_placeholder')
				}}
				variant="readonly"
				selection={getTimezoneSelection(selectedTimezone, i18n)}
				events={{
					onSelect: onSelectTimezone
				}}
				required
				disabled={!enabled}
			/>
			<Tooltip
				i18n={i18n}
				cid="mp-message-sendwindow-timezone"
			/>
		</div>
	);
}

export default TimezonePicker;

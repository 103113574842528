import { fetchMessages, getMessageById, isFetching } from '../actions/messages';
import Datatable from '../components/common/datatable';
import { hasProperty } from '../utilities/helper';

export default function (dom, configs) {
	const { i18n, fetchOption, onSelectMessage, store } = configs;
	let { data } = configs;

	const onSelectTableRow = (e) => {
		if (isFetching()) {
			return;
		}

		const selectedRow = e.target.closest('tr');
		const selectedMessage = getMessageById(selectedRow.id);

		// DOM: Row selection (Only select non-selected rows)
		// 1. Remove 'selected' class from all rows
		// 2. Add 'selected' class to selected row
		if (!selectedRow.classList.contains('selected')) {
			Array.prototype.slice.call(dom.querySelectorAll('tbody tr')).forEach((row) => {
				row.classList.remove('selected');
			});
			selectedRow.classList.add('selected');
		}

		onSelectMessage(selectedMessage);

		// Print out metadata for debugging purpose
		console.log(store);
	};

	const renderMessageTable = () => {
		const container = dom.querySelector('#message-table');
		const tableOptions = {
			i18n,
			data,
			isFetching: isFetching(),
			selectedDataId: store.isLegacyContent && hasProperty(store.messageDefinition, 'content') ? store.messageDefinition.content.id : '',
			templateOptions: {
				headers: [
					{
						name: i18n.get('message'),
						sortable: true,
						orderby: 'name',
						sortdirection: 'DESC',
						defaultSort: false
					},
					{
						name: i18n.get('app'),
						sortable: false
					},
					{
						name: i18n.get('last_modified'),
						sortable: true,
						orderby: 'lastUpdated',
						sortdirection: 'DESC',
						defaultSort: true
					}
				],
				noItemsText: i18n.get('no_active_message'),
				noMoreItemsText: i18n.get('no_more_messages'),
				loadMoreItemsText: i18n.get('load_more')
			},
			events: {
				onSelectTableRow: onSelectTableRow
			}
		};

		this.messageTable = new Datatable(container, tableOptions, fetchMessages, fetchOption);
	};

	const render = () => {
		dom.innerHTML = '<div id="message-table"></div>';

		renderMessageTable();
	};

	// Public Method
	this.renderMessageTableWithData = (messageData) => {
		data = messageData;

		render();
	};

	this.clearSelection = () => {
		Array.prototype.slice.call(dom.querySelectorAll('tbody tr')).forEach((row) => {
			row.classList.remove('selected');
		});
	};

	const initialize = () => {
		render();
	};

	initialize();
}

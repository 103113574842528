var InputHtml = require('./templates/input.html');

var KEY_CODE = {
    ENTER: 13,
    ESCAPE: 27
};

module.exports = function InputComponent(options) {
    var internalValue = options.value || '';
    var Mustache = options.Mustache;
    this.element = document.createElement('div');
    this.element.classList.add('slds-form-element');
    this.options = options;

    this.initialize = function() {
        this.element.innerHTML = Mustache.render(InputHtml, {
            id: options.id,
            label: options.label,
            value: options.value || '',
            required: options.required
        });

        options.parent.appendChild(this.element);

        this.errorMessage = this.element.querySelector('.slds-form-element__help');
        this.input = this.element.querySelector('input');
        this.input.addEventListener('keydown', this.onInputKeyDown.bind(this));
        this.input.addEventListener('blur', this.onInputBlur.bind(this));
        this.input.addEventListener('paste', this.onInputPaste.bind(this));

        this.setReadOnly(options.disabled);
    };

    this.onInputKeyDown = function(e) {
        if (e.keyCode === KEY_CODE.ENTER) {
            this.commit();
            this.input.blur();
        } else if (e.keyCode === KEY_CODE.ESCAPE) {
            this.reset();
        } else {
            this.clip();
        }
    };

    this.onInputBlur = function() {
        this.commit();
    };

    this.onInputPaste = function() {
        this.clip();
    };

    this.setErrorMessage = function(error) {
        if (error) {
            this.errorMessage.classList.remove('slds-hide');
            this.errorMessage.innerText = error;
            this.element.classList.add('slds-has-error');
        } else {
            this.errorMessage.classList.add('slds-hide');
            this.element.classList.remove('slds-has-error');
        }
    };

    this.value = function(value) {
        if (value) {
            internalValue = value;
            this.input.value = value;
            this.clip();
        }
        return internalValue;
    };

    this.commit = function() {
        if (internalValue === this.input.value) {
            return; // do nothing if the value hasn't changed
        }

        internalValue = this.input.value;

        if (options.onCommit) {
            options.onCommit(this);
        }
    };

    this.reset = function() {
        this.input.value = internalValue;
    };

    this.clip = function() {
        var maxLength = options.maxLength;
        var value = this.input.value;

        if (!maxLength) {
            return; // nothing to do if a max length is not set
        }

        if (value.length > maxLength) {
            this.input.value = value.substring(0, maxLength);
        }
    };

    this.setReadOnly = function(value) {
        this.input.disabled = Boolean(value);
    };

    this.initialize();
};

var ContentBuilderSDK = require('@salesforce-mc/contentbuilder-sdk');
var ContentViewHtml = require('./templates/content-view.html');
var PreviewSubscriberModal = require('./preview-subscriber-modal');
var EditorModal = require('./editor-modal');
var copyObject = require('./copy-object');

var TABS = {
    LEGACY: 'legacy',
    CONTENT_BUILDER: 'content-builder'
};

var ACTIONS = {
    newMessage: 'new-message',
    editMessage: 'edit-message',
    previewSubscriber: 'preview-subscriber'
};

module.exports = function MessageDefinitionDecorator(args) {
    var thisCreateContentBuilderGrid = args.createContentBuilderGrid || createContentBuilderGrid;
    var thisCreateEditorModal = args.createEditorModal || createEditorModal;
    var thisCreatePreviewSubscriberModal = args.createPreviewSubscriberModal || createPreviewSubscriberModal;
    var contentBuilderOptions = args.contentBuilder;
    var showEditorInReadonlyMode = args.showEditorInReadonlyMode;
    var view = args.view;
    var scm = args.scm;
    var MAJIK = args.MAJIK;

    this.element = document.createElement('div');
    this.ui = null;
    this.content = null;
    this.showAction = null;
    this.hasRenderedLegacyContentArea = false;
    this.selectedTab = TABS.CONTENT_BUILDER;
    this.contentBuilderGrid = null;
    this.editorModal = null;

    this.onViewInitialized = function() {
        this.originalViewSize = view.size;
        this.element.classList.add('content-view');
        this.element.style.height = '100%';
        this.element.innerHTML = MAJIK.Mustache.render(ContentViewHtml, {
            majik_ce_create_message_link: scm.i18n.get('majik_ce_create_message_link'),
            majik_ce_edit_message_link: scm.i18n.get('majik_ce_edit_message_link'),
            majik_ce_legacy_content_tab: scm.i18n.get('majik_ce_legacy_content_tab'),
            majik_ce_content_builder_tab: scm.i18n.get('majik_ce_content_builder_tab')
        });

        // this view is persistent. we're safe to render in initialize
        this.render(scm.element);
    };

    this.onViewShow = function(options) {
        // The spoke buttons on the summary page can show this view with different action arguments
        // The showAction option determines if we show a modal or the normal grid view
        this.showAction = options.action || null;

        switch (this.showAction) {
        case ACTIONS.newMessage:
            this.showCreateMessageModal(options);
            return;
        case ACTIONS.editMessage:
            this.showEditMessageModal(options);
            return;
        case ACTIONS.previewSubscriber:
            this.showPreviewSubscribers(options);
            return;
        default:
            this.showContentGrid();
        }
    };

    this.showContentGrid = function() {
        if (scm.isReadOnly()) {
            if (Boolean(showEditorInReadonlyMode) === true) {
                this.showEditMessageModal();
                return;
            }
        }
        this.renderSelectedTab();
    };

    this.onViewHide = function() {
        // the modals should always close when the user is done with them but
        // we'll close them every time the view is hidden just to be 100% sure there are no leaks
        if (this.editorModal) {
            this.editorModal.close();
            this.editorModal = null;
        }
        if (this.previewSubscriberModal) {
            this.previewSubscriberModal.close();
            this.previewSubscriberModal = null;
        }
    };

    this.render = function(element) {
        element.appendChild(this.element);

        this.ui = {
            tabsContainer: element.querySelector('.slds-tabs_default'),
            serviceContainer: element.querySelector('#cbservice-container'),
            legacyTab: element.querySelector('#tab-legacy-content'),
            legacyTabLink: element.querySelector('#tab-legacy-content__link'),
            legacyTabItem: element.querySelector('#tab-legacy-content__item'),
            contentTab: element.querySelector('#tab-content-builder'),
            contentTabLink: element.querySelector('#tab-content-builder__link'),
            contentTabItem: element.querySelector('#tab-content-builder__item'),
            editMessageSpan: element.querySelector('.edit-message-link'),
            editMessageLink: element.querySelector('.edit-message-link a'),
            createMessageLink: element.querySelector('.create-message-link a'),
            createMessageSpan: element.querySelector('.create-message-link')
        };

        this.ui.legacyTabLink.addEventListener('click', this.onLegacyContentTabClicked.bind(this));
        this.ui.contentTabLink.addEventListener('click', this.onContentBuilderTabClicked.bind(this));
        this.ui.editMessageSpan.addEventListener('click', this.onEditMessageLinkClicked.bind(this));
        this.ui.createMessageSpan.addEventListener('click', this.onCreateMessageLinkClicked.bind(this));

        // if the user opts out of the legacy message selection then we need
        // to hide the legacy tab and set our default tab to content builder
        if (args.showLegacyMessageTab === false) {
            this.ui.legacyTabItem.style.display = 'none';
            this.selectedTab = TABS.CONTENT_BUILDER;
        }

        // the edit link should be visible when content is selected
        this.toggleEditMessageLinkVisible(Boolean(this.content));

        invokeFunction(view, 'onDecoratorRendered', {
            element: this.element
        });
    };

    this.getContent = function() {
        return this.content;
    };

    this.setContent = function(content) {
        var oldContent = this.content;
        this.content = content;
        if (oldContent !== content) {
            invokeFunction(view, 'onContentChanged', {
                content: this.content,
                previousValue: oldContent
            });
        }
        this.setContainerConfiguredAttribute();
        this.toggleEditMessageLinkVisible(Boolean(content));
    };

    this.showPreviewSubscribers = function(viewOptions) {
        viewOptions = viewOptions || {action: ACTIONS.previewSubscriber};
        // note to maintainer: do not remove arguments from this function call
        // some channels override the modal and depend on these properties
        this.previewSubscriberModal = thisCreatePreviewSubscriberModal(scm, {
            content: this.content,
            action: ACTIONS.previewSubscriber,
            viewOptions: viewOptions,
            isReadOnly: scm.isReadOnly(),
            contentBuilderOptions: contentBuilderOptions,
            onCreateSubscriberPreview: this.onCreateSubscriberPreview.bind(this),
            onClose: this.onPreviewSubscribersModalClose.bind(this)
        }, ContentBuilderSDK);
        this.previewSubscriberModal.show();
        scm.resize('scm-full');
        scm.hideMenu();
        invokeFunction(view, 'onPreviewSubscribersModalShow', {
            modal: this.previewSubscriberModal
        });
    };

    this.showCreateMessageModal = function(viewOptions) {
        viewOptions = viewOptions || {action: ACTIONS.newMessage};
        // note to maintainer: do not remove arguments from this function call
        // some channels override the modal and depend on these properties
        this.editorModal = thisCreateEditorModal(scm, {
            messageDefinitionView: this,
            action: ACTIONS.newMessage,
            viewOptions: viewOptions,
            isReadonly: scm.isReadOnly(),
            contentBuilderOptions: contentBuilderOptions,
            onClose: this.onCreateMessageModalClose.bind(this),
            onSave: this.onCreateMessageModalSave.bind(this),
            onSaveError: this.onContentBuilderEditorError.bind(this),
            onCreateContentBuilderEditor: this.onCreateContentBuilderEditor.bind(this)
        }, ContentBuilderSDK);
        this.editorModal.show();
        invokeFunction(view, 'onCreateMessageModalShow', {
            modal: this.editorModal
        });
    };

    this.showEditMessageModal = function(viewOptions) {
        viewOptions = viewOptions || {action: ACTIONS.editMessage};
        // note to maintainer: do not remove arguments from this function call
        // some channels override the modal and depend on these properties
        this.editorModal = thisCreateEditorModal(scm, {
            messageDefinitionView: this,
            action: ACTIONS.editMessage,
            viewOptions: viewOptions,
            isReadOnly: scm.isReadOnly(),
            content: this.content,
            contentBuilderOptions: contentBuilderOptions,
            onClose: this.onEditMessageModalClose.bind(this),
            onSave: this.onEditMessageModalSave.bind(this),
            onSaveError: this.onContentBuilderEditorError.bind(this),
            onCreateContentBuilderEditor: this.onCreateContentBuilderEditor.bind(this)
        }, ContentBuilderSDK);
        this.editorModal.show();
        invokeFunction(view, 'onEditMessageModalShow', {
            modal: this.editorModal
        });
    };

    this.onContentBuilderEditorError = function() {
        invokeFunction(view, 'onContentBuilderEditorError');
    };

    this.onEditMessageModalClose = function() {
        invokeFunction(view, 'onEditMessageModalClosed');
        this.maybeReturnToDefaultView();
    };

    this.onCreateMessageModalClose = function() {
        invokeFunction(view, 'onCreateMessageModalClosed');
        this.maybeReturnToDefaultView();
    };

    this.onPreviewSubscribersModalClose = function() {
        invokeFunction(view, 'onPreviewSubscribersModalClose');
        scm.resize(this.originalViewSize);
        scm.showDefaultView();
    };

    this.onCreateContentBuilderEditor = function(e) {
        invokeFunction(view, 'onCreateContentBuilderEditor', e);
    };

    this.onCreateSubscriberPreview = function(e) {
        invokeFunction(view, 'onCreateSubscriberPreview', e);
    };

    this.maybeReturnToDefaultView = function() {
        // if the new message or edit message modal closes
        // and we came from the summary view then we need to return there.
        // We should also return to the summary if the user edits or creates some content
        if (this.showAction || this.content) {
            scm.showDefaultView();
        }
    };

    this.onCreateMessageModalSave = function(content) {
        this.setContent(content);
        invokeFunction(view, 'onContentCreated', {
            content: content
        });
    };

    this.onEditMessageModalSave = function(content) {
        this.setContent(content);
        invokeFunction(view, 'onContentEdited', {
            content: content
        });
    };

    this.onCreateMessageLinkClicked = function(e) {
        e.preventDefault();
        var cancel = invokeFunction(view, 'onCreateMessageLinkClicked');
        if (cancel !== true) {
            this.showCreateMessageModal();
        }
    };

    this.onEditMessageLinkClicked = function(e) {
        e.preventDefault();
        var cancel = invokeFunction(view, 'onEditMessageLinkClicked');
        if (cancel !== true) {
            this.showEditMessageModal();
        }
    };

    this.onLegacyContentTabClicked = function() {
        if (this.selectedTab !== TABS.LEGACY) {
            this.selectedTab = TABS.LEGACY;
            this.renderSelectedTab();
            invokeFunction(view, 'onLegacyContentTabSelected');
        }
    };

    this.onContentBuilderTabClicked = function () {
        if (this.selectedTab !== TABS.CONTENT_BUILDER) {
            this.selectedTab = TABS.CONTENT_BUILDER;
            this.renderSelectedTab();
            invokeFunction(view, 'onContentBuilderTabSelected');
        }
    };

    this.onContentReady = function() {
        invokeFunction(view, 'onContentReady', {
            contentBuilderGrid: this.contentBuilderGrid
        });
    };

    this.onContentSelected = function(content) {
        this.setContent(content);
        invokeFunction(view, 'onContentSelected', {
            content: content,
            contentBuilderGrid: this.contentBuilderGrid
        });
    };

    this.onContentDeselected = function() {
        this.setContent(null);
        invokeFunction(view, 'onContentDeselected', {
            contentBuilderGrid: this.contentBuilderGrid
        });
    };

    this.toggleEditMessageLinkVisible = function(value) {
        if (this.ui) {
            if (value) {
                this.ui.editMessageSpan.classList.remove('slds-hide');
            } else {
                this.ui.editMessageSpan.classList.add('slds-hide');
            }
        }
    };

    this.refreshLegacyContentArea = function() {
        if (this.hasRenderedLegacyContentArea) {
            this.hasRenderedLegacyContentArea = false;
        }
        this.renderLegacyContentArea();
    };

    this.renderLegacyContentArea = function() {
        // the channel developer is responsible for rendering the legacy view
        if (!this.hasRenderedLegacyContentArea) {
            invokeFunction(view, 'onRenderLegacyContentArea', {
                element: this.ui.legacyTab
            });
            this.hasRenderedLegacyContentArea = true;
        }
    };

    this.refreshContentBuilderGrid = function() {
        // this function will allow users to destroy and re-render the grid
        if (this.contentBuilderGrid) {
            this.contentBuilderGrid.destroy();
            this.contentBuilderGrid = null;
        }
        this.renderContentBuilderGrid();
    };

    this.renderContentBuilderGrid = function() {
        if (this.contentBuilderGrid) {
            // we only render the grid once...
            // the message definition view is persistent, which means the grid is always in the dom
            return;
        }

        // We're going to bundle all of our SDK Grid args into an event argument, then trigger
        // a callback so channel developers can modify the arguments before the grid is created
        var event = {
            element: this.ui.serviceContainer,
            stack: contentBuilderOptions.stack,
            onReady: this.onContentReady.bind(this),
            options: copyObject(contentBuilderOptions.grid, {
                multi: false,
                showItemActions: false,
                onSelected: this.onContentSelected.bind(this),
                onDeselected: this.onContentDeselected.bind(this)
            })
        };

        // notify the channel dev that the grid will be created,
        // and give them the chance to modify the grid arguments
        invokeFunction(view, 'onCreateContentBuilderGrid', event);

        // create the grid
        this.contentBuilderGrid = thisCreateContentBuilderGrid(event);
    };

    this.getSelectedTab = function() {
        return this.selectedTab;
    };

    this.setSelectedTab = function(tab) {
        if (tab === TABS.LEGACY) {
            this.selectedTab = TABS.LEGACY;
        } else if (tab === TABS.CONTENT_BUILDER) {
            this.selectedTab = TABS.CONTENT_BUILDER;
        }
        this.renderSelectedTab();
    };

    this.renderSelectedTab = function() {
        if (this.selectedTab === TABS.LEGACY) {
            this.ui.legacyTabItem.classList.add('slds-is-active');
            this.ui.legacyTab.classList.add('slds-show');
            this.ui.legacyTab.classList.remove('slds-hide');

            this.ui.contentTabItem.classList.remove('slds-is-active');
            this.ui.contentTab.classList.add('slds-hide');
            this.ui.contentTab.classList.remove('slds-show');

            this.renderLegacyContentArea();

        } else if (this.selectedTab === TABS.CONTENT_BUILDER) {
            this.ui.legacyTabItem.classList.remove('slds-is-active');
            this.ui.legacyTab.classList.remove('slds-show');
            this.ui.legacyTab.classList.add('slds-hide');

            this.ui.contentTabItem.classList.add('slds-is-active');
            this.ui.contentTab.classList.remove('slds-hide');
            this.ui.contentTab.classList.add('slds-show');

            this.renderContentBuilderGrid();
        }
    };

    this.setContainerConfiguredAttribute = function() {
        // we have to show/hide the "Detailed View" button on the summary view with a css class
        var container = scm.config.element.querySelector('.scm-container');
        container.setAttribute('data-configured', Boolean(this.content));
    };

    function invokeFunction(object, functionName, arg) {
        if (typeof object[functionName] === 'function') {
            return object[functionName](arg, scm);
        }
        return null;
    }

};

function createContentBuilderGrid(options) {
    return new ContentBuilderSDK.Grid(
        options.element,
        options.stack,
        options.options,
        options.onReady
    );
}

function createEditorModal(scm, options) {
    var modalPrototype = EditorModal(scm, ContentBuilderSDK);
    return scm.createModal(modalPrototype, options);
}

function createPreviewSubscriberModal(scm, options) {
    var modalPrototype = PreviewSubscriberModal(scm, ContentBuilderSDK);
    return scm.createModal(modalPrototype, options);
}

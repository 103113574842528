var ContentBuilderSDK = require('@salesforce-mc/contentbuilder-sdk');
var AssetUtils = require('@salesforce-mc/assetutils');

module.exports = {
    MessageSummary: require('./message-summary/extension'),
    MessageDefinition: require('./message-definition/extension'),
    MessageActivity: require('./message-activity/extension'),
    services: {
        ContentBuilderSDK: ContentBuilderSDK,
        AssetUtils: AssetUtils
    }
};

import find from 'lodash.find';
import timezones from '../constants/timezone';

class TimezoneUtils {
	static getTimeZoneByName (name) {
		return find(TimezoneUtils.timeZones, (tz) => tz.name.indexOf(name) >= 0);
	}

	static getTimeZoneByNameOrValue (data) {
		return find(TimezoneUtils.timeZones, (tz) => data === tz.name || data === tz.value);
	}

	static getTimeZoneByValue (value) {
		return find(TimezoneUtils.timeZones, { value });
	}

	static getTimeZoneByGold (gold) {
		return find(TimezoneUtils.timeZones, { gold });
	}

	static getTimeZoneById (timeZoneId) {
		return find(TimezoneUtils.timeZones, { timeZoneId });
	}

	static getTimezoneByIanaName (ianaName) {
		return find(TimezoneUtils.timeZones, { ianaName });
	}

	static getTimezoneItemForComboboxById (timeZoneId) {
		const timeZone = TimezoneUtils.getTimeZoneById(timeZoneId);

		if (!timeZone) {
			return {};
		}

		return {
			id: timeZone.timeZoneId,
			label: timeZone.gold,
			value: timeZone.value,
			name: timeZone.ianaName,
			utcOffset: timeZone.offset.num
		};
	}

	static getTimezoneItemForComboboxByIanaName (ianaName) {
		const timeZone = TimezoneUtils.getTimezoneByIanaName(ianaName);

		if (!timeZone) {
			return {};
		}

		return {
			id: timeZone.timeZoneId,
			label: timeZone.gold,
			value: timeZone.value,
			name: timeZone.ianaName,
			utcOffset: timeZone.offset.num
		};
	}

	static getTimezonesForCombobox (i18n) {
		return TimezoneUtils.timeZones.map((timeZone) => ({
			id: timeZone.timeZoneId,
			label: i18n ? i18n.get(timeZone.gold) : timeZone.gold,
			value: timeZone.value,
			name: timeZone.ianaName,
			utcOffset: timeZone.offset.num
		}));
	}
}

TimezoneUtils.timeZones = [
	...timezones
];

export default TimezoneUtils;

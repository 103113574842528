import { handleActions } from 'redux-actions';
import { successActionType } from '@salesforce-mc/fetch-maint/middleware/actions';
import { SET_MOBILE_APP, FETCH_MOBILE_APPS, FETCH_MOBILE_APP_DETAIL, SET_INTERACTIVE_NOTIFICATIONS, SET_MOBILE_APP_DETAIL, UPDATE_CUSTOM_KEYS } from '../actions/mobile-app-selection';
import initialState from './InitialState';
import { hasProperty } from '../utilities/helper';

export const getComboboxItems = (items) => {
	const apps = items.map((app) => ({
		id: app.id,
		label: app.name,
		value: app.name
	}));

	return apps;
};

export const getNotificationComboboxItems = (app) => {
	if (!hasProperty(app, 'categories')) {
		return [];
	}

	const notifications = app.categories.map((category) => ({
		id: category.id,
		label: category.name,
		value: category.name,
		buttons: category.buttons
	}));

	return notifications;
};

export const getCustomKeys = (app) => {
	if (!hasProperty(app, 'keys')) {
		return [];
	}

	return app.keys.map((customKey) => ({
		key: customKey.key,
		value: ''
	}));
};

export default handleActions(
	{
		[SET_MOBILE_APP]: (state, action) => ({
			...state,
			selectedMobileApp: action.app
		}),
		[successActionType(FETCH_MOBILE_APPS)]: (state, action) => ({
			...state,
			mobileApps: getComboboxItems(action.payload.items)
		}),
		[successActionType(FETCH_MOBILE_APP_DETAIL)]: (state, action) => ({
			...state,
			mobileAppDetail: (({ iconUrl, ...others }) => ({ ...others }))(action.payload), // Store application data without iconUrl to reduce payload size
			interactiveNotifications: getNotificationComboboxItems(action.payload),
			customKeys: getCustomKeys(action.payload)
		}),
		[SET_INTERACTIVE_NOTIFICATIONS]: (state, action) => ({
			...state,
			interactiveNotifications: action.notifications
		}),
		[SET_MOBILE_APP_DETAIL]: (state, action) => ({
			...state,
			mobileAppDetail: action.detail,
			customKeys: getCustomKeys(action.detail)
		}),
		[UPDATE_CUSTOM_KEYS]: (state, action) => ({
			...state,
			customKeys: action.keys
		})
	},
	{
		selectedMobileApp: initialState.selectedMobileApp,
		mobileApps: initialState.mobileApps,
		mobileAppDetail: initialState.mobileAppDetail,
		interactiveNotifications: initialState.interactiveNotifications,
		customKeys: initialState.customKeys
	}
);

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Pill from '@salesforce/design-system-react/components/pill';

export default class Campaign extends React.Component {
	constructor (props) {
		super(props);

		this.campaignPill = React.createRef();
	}

	componentDidUpdate () {
		// Apply pill style
		if (this.campaignPill && this.campaignPill.current) {
			const el = this.campaignPill.current.root;

			el.style.background = `#${this.props.selectedCampaign.displayColor}`;
			el.style.borderColor = `#${this.props.selectedCampaign.displayColor}`;
		}
	}

	render () {
		const {
			i18n,
			onAddCampaign,
			onRemoveCampaign,
			selectedCampaign
		} = this.props;

		return (
			<div className="campaign-container">
				<div className="container-heading">{i18n.get('campaign')}</div>
				<div className="campaign">
					{!isEmpty(selectedCampaign) ? (
						<div className="selected-campaign">
							<Pill
								labels={{
									label: selectedCampaign.name,
									title: selectedCampaign.name,
									removeTitle: i18n.get('remove')
								}}
								onRemove={() => {
									onRemoveCampaign();
								}}
								variant="option"
								ref={this.campaignPill}
							/>
							<span
								className="btn-change-campaign link-btn"
								role="button"
								tabIndex={0}
								onClick={onAddCampaign}
								onKeyDown={onAddCampaign}
							>
								{i18n.get('change')}
							</span>
						</div>
					) : (
						<span
							className="btn-add-campaign link-btn"
							role="button"
							tabIndex={0}
							onClick={onAddCampaign}
							onKeyDown={onAddCampaign}
						>
							{i18n.get('add_campaign_association')}
						</span>
					)}
				</div>
			</div>
		);
	}
}

Campaign.propTypes = {
	i18n: PropTypes.object.isRequired,
	onAddCampaign: PropTypes.func.isRequired,
	onRemoveCampaign: PropTypes.func.isRequired,
	selectedCampaign: PropTypes.object.isRequired
};

/*
	configs:
	-  label: string (optional)
	-  id: string (optional)
	-  placeholder: string (optional)
	-  noOptionsFoundText: string (optional)
	-  required: bool (optional) (default is false)
	-  hasIconUrl: bool (optional) (default is false)
	-  options: array (required)
	-  selection: object (optional) (id, value, iconUrl, disabled) (iconUrl and disabled are optional)
	-  isFetching: bool (optional)
	-  disabled: bool (optional)

	events:
	-  onSelect (returns an object from the selected option)
*/

import { sanitizeHTML } from '../../../utilities/helper';
import Template from './combobox.html';
import './combobox.css';

export default function (dom, configs) {
	const comboboxId  = ((configs.id) && (configs.id.length > 0)) ? configs.id : new Date().getTime();

	const destoryLoader = () => {
		const loader = dom.querySelector('.combobox-loader-component');

		if (loader) {
			loader.parentNode.removeChild(loader);
		}
	};

	const setSelection = () => {
		const listContainer = dom.querySelector('ul');
		const { hasIconUrl, selection } = configs;
		const { id, value, title, iconUrl } = selection;

		if ((selection) && (Object.keys(selection).length !== 0)) {
			dom.querySelector('.slds-dropdown-trigger').classList.remove('slds-is-open');

			if (listContainer.querySelector('.slds-has-focus')) {
				listContainer.querySelector('.slds-has-focus').classList.remove('slds-has-focus');
			}

			if ((hasIconUrl) && (iconUrl) && (iconUrl.length)) {
				dom.querySelector('.slds-input').innerHTML = `<img src="${sanitizeHTML(iconUrl)}"> ${value}`;
			} else {
				dom.querySelector('.slds-input').innerHTML = value;
			}

			dom.querySelector('.slds-input').title = sanitizeHTML(title);

			listContainer.querySelector(`[id="${id}"]`).classList.add('slds-has-focus');

			if (!hasIconUrl) {
				const checkmark = `
					<span class="slds-icon_container slds-icon-utility-check slds-current-color">
						<svg class="slds-icon slds-icon_x-small" aria-hidden="true">
							<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
						</svg>
					</span>
				`;

				listContainer.querySelector(`[id="${id}"] > .slds-listbox__option-icon`).innerHTML = checkmark;
			}
		}
	};

	const addEvents = () => {
		const listContainer = dom.querySelector('ul');

		dom.querySelector('.slds-combobox__form-element').addEventListener('click', () => {
			dom.querySelector('.slds-dropdown-trigger').classList.add('slds-is-open');
			dom.querySelector('.slds-combobox__form-element').classList.add('slds-has-focus');
		});

		document.addEventListener('click', (event) => {
			if (event.target.closest(`#${comboboxId}`) === null) {
				dom.querySelector('.slds-dropdown-trigger').classList.remove('slds-is-open');
				dom.querySelector('.slds-combobox__form-element').classList.remove('slds-has-focus');
			}
		});

		listContainer.querySelectorAll('.slds-listbox__option').forEach((element, index) => {
			element.addEventListener('click', () => {
				const { hasIconUrl, options } = configs;

				if (!hasIconUrl) {
					listContainer.querySelector('.slds-icon-utility-check').parentNode.removeChild(listContainer.querySelector('.slds-icon-utility-check'));
				}

				if (listContainer.querySelector('.slds-has-focus')) {
					listContainer.querySelector('.slds-has-focus').classList.remove('slds-has-focus');
				}

				configs.selection = options[index];

				element.classList.add('slds-has-focus');

				setSelection();

				this.onSelect(options[index]);
			});
		});
	};

	const renderNoOptions = () => {
		const id = 'no-options-found';
		const value = sanitizeHTML(configs.noOptionsFoundText);
		const title = sanitizeHTML(configs.noOptionsFoundText);
		const list = `
			<li role="presentation" class="slds-listbox__item disabled">
				<div aria-selected="true" id="${id}" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small disabled" role="option">
					<span class="slds-media__body">
						<span class="slds-truncate" title="${title}">
							<span class="slds-assistive-text">Current Selection:</span> ${value}
						</span>
					</span>
				</div>
			</li>
		`;

		dom.querySelector('ul').innerHTML = list;
	};

	const renderOptions = () => {
		const listContainer = dom.querySelector('ul');
		const { hasIconUrl, options } = configs;

		let lists = '';

		options.forEach((option) => {
			const { id, value, disabled } = option;
			const title = sanitizeHTML(option.title);
			const iconUrl = sanitizeHTML(option.iconUrl);
			const media = ((hasIconUrl) && (iconUrl) && (iconUrl.length)) ? `<img src="${iconUrl}">` : '';
			const disabledClass = disabled ? 'disabled' : '';
			const list = `
				<li role="presentation" class="slds-listbox__item ${disabledClass}">
					<div aria-selected="true" id="${id}" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small ${disabledClass}" role="option">
						<span class="slds-media__figure slds-listbox__option-icon">${media}</span>
						<span class="slds-media__body slds-truncate">
							<span class="slds-truncate" title="${title}">
								<span class="slds-assistive-text">Current Selection:</span>${value}
							</span>
						</span>
					</div>
				</li>
			`;

			lists += list;
		});

		listContainer.innerHTML = lists;
	};

	const renderInput = () => {
		const { placeholder, isFetching } = configs;

		if (isFetching === true) {
			dom.querySelector('.combobox-loader').classList.remove('hidden');
		} else if (placeholder) {
			dom.querySelector('.slds-input').innerHTML = sanitizeHTML(placeholder);
		}

		dom.querySelector('.slds-combobox__form-element').id = comboboxId;
	};

	const renderLabel = () => {
		const { label, required } = configs;

		if (label) {
			dom.querySelector('label').htmlFor = comboboxId;
			dom.querySelector('label').innerHTML = sanitizeHTML(label) + dom.querySelector('label').innerHTML;
		}

		if (required) {
			dom.querySelector('abbr').classList.remove('hidden');
		}
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const initialize = () => {
		const { isFetching, options } = configs;

		render();
		renderLabel();
		renderInput();

		if (isFetching === true) {
			return;
		}

		if (options.length > 0) {
			renderOptions();
		} else {
			renderNoOptions();
		}

		destoryLoader();
		setSelection();
		addEvents();
	};

	initialize();
}

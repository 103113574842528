import moment from 'moment';
import checkResponseStatus from '../utilities/check-response-status';
import fetchOptions from '../constants/fetch-options';
import { hasProperty } from '../utilities/helper';
import PostmongerStore from '../utilities/postmonger';
import I18n from '../utilities/i18n';

const BASE_URL = '/fuelapi/push/v1/message/';
const i18n = I18n(PostmongerStore.cultureCode);
/**
 * @private
 * This is just a map to make finding messages easier.  It is populated when the messages are fetched.
 */
const messageIdMap = {};

/**
 * @private
 * This is a flag to determine if the fetch is in progress.
 */
let fetching = false;

// Set locale of moment for lastUpdated date format
moment.locale(i18n.getMomentLocale());

const fetchMessages = (params) => {
	let url = `${BASE_URL}?`;

	// App Sendable Status
	url += '&appSendableStatus=3';

	// Legacy Only Message
	url += '&legacyOnly=1';

	// Pagination
	url += `&$pageSize=${params.pageSize}`;
	url += `&$page=${params.page}`;

	// Sorting
	url += `&$orderBy=${params.orderby}%20${params.sortdirection}%20`;

	// Extra filter for message status and type (Send Method 3 = Interaction, Message Type 1 = Basic)
	url += '&$filter=statusid eq 2 and sendmethodid eq 3 and messageType eq 1';

	fetching = true;

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}

		return data;
	}).then((data) => {
		fetching = false;

		if (!data) {
			return {};
		}

		return {
			count: data.count,
			items: data.items.map((message) => {
				messageIdMap[message.id] = Object.assign({}, message);

				return {
					id: message.id,
					name: message.name,
					lastModified: moment(message.lastUpdated).format('lll'),
					appName: message.application.name,
					hasIcon: hasProperty(message.application, 'iconUrl') && message.application.iconUrl.length > 0,
					appIconSrc: hasProperty(message.application, 'iconUrl') ? message.application.iconUrl : '',
					selected: params.selectedDataId && params.selectedDataId === message.id,
					pageIndex: params.page
				};
			})
		};
	});
};

const getMessageById = (messageId) => messageIdMap[messageId] || {};

const isFetching = () => fetching;

export { fetchMessages, getMessageById, isFetching };

import { combineReducers } from 'redux';
import MobileAppSelection from './mobile-app-selection';
import DeliveryOptions from './delivery-options';
import AdvancedOptions from './advanced-options';

export default combineReducers({
	MobileAppSelection,
	DeliveryOptions,
	AdvancedOptions
});

var copyObject = require('./copy-object');

module.exports = function(scm, ContentBuilderSDK) {

    function newSubscriberPreview(options) {
        var contentBuilderOptions = options.contentBuilderOptions;
        var modalElement = options.element;
        var onReadyCallback = options.onReady;

        var event = {
            stack: contentBuilderOptions.stack,
            options: copyObject(contentBuilderOptions.previewSubscriber, {
                asset: options.content
            })
        };

        // notify the channel dev that the Subscriber Preview will be created,
        // and give them the chance to modify the editor arguments
        options.onCreateSubscriberPreview(event);

        return new ContentBuilderSDK.SubscriberPreview(
            modalElement,
            event.stack,
            event.options,
            onReadyCallback
        );
    }

    var PreviewSubscriberModal = {
        className: 'preview-subscriber-modal',

        onInitialize: function() {
            this.preview = newSubscriberPreview({
                element: this.element,
                content: this.options.content,
                isReadOnly: this.options.isReadOnly,
                contentBuilderOptions: this.options.contentBuilderOptions,
                onCreateSubscriberPreview: this.options.onCreateSubscriberPreview,
                onReady: this.onPreviewSubscriberReady.bind(this)
            });
        },

        onPreviewSubscriberReady: function() {
            //console.log('onPreviewSubscriberReady');
        },

        onClose: function() {
            this.preview.destroy();
            this.options.onClose();
        }
    };

    return PreviewSubscriberModal;
};

module.exports = function assignDefaultOptions(options, scm) {
    // the legacy content tab should be visible by default
    if (!Object.hasOwnProperty.call(options, 'preloadContentBuilder')) {
        options.preloadContentBuilder = false;
    }
    // the legacy content tab should be visible by default
    if (!Object.hasOwnProperty.call(options, 'showLegacyMessageTab')) {
        options.showLegacyMessageTab = true;
    }
    // the New Message button visible by default
    if (!Object.hasOwnProperty.call(options, 'showNewMessageButton')) {
        options.showNewMessageButton = true;
    }
    // the Preview Subscriber button should not be enabled by default
    if (!Object.hasOwnProperty.call(options, 'showPreviewSubscriber')) {
        options.showPreviewSubscriber = false;
    }
    // Users should not be able to edit or select content in readonly (by default)
    if (!Object.hasOwnProperty.call(options, 'showEditorInReadonlyMode')) {
        options.showEditorInReadonlyMode = true;
    }
    // there should be default contentBuilder builder options
    if (!Object.hasOwnProperty.call(options, 'contentBuilder')) {
        options.contentBuilder = {};
    }
    // default options for the content builder grid
    if (!options.contentBuilder.grid) {
        options.contentBuilder.grid = {
            defaultView: 'thumbnail',
            query: null
        };
    }
    // default options for the content builder editor
    if (!options.contentBuilder.editor) {
        options.contentBuilder.editor = {
            view: 'inApp',
            assetType: 230 // json-message
        };
    }
    // default options for the content builder subscriber preview
    if (!options.contentBuilder.previewSubscriber) {
        options.contentBuilder.previewSubscriber = {
            view: 'inApp',
            previewData: null,
            recipientData: null,
            dataSource: null
        };
    }
    // default stack option. defaults to SDK's endpoint.stackKey
    if (!options.contentBuilder.stack) {
        if (scm.sdk && scm.sdk.endpoints) {
            options.contentBuilder.stack = scm.sdk.endpoints.stackKey || 'localhost';
        }
    }
};

const checkResponseStatus = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response.json();
	}

	const error = new Error(response.statusText);

	error.response = response;

	throw error;
};

export default checkResponseStatus;

import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import fetchMiddleware from './middleware-fetch';
import rootReducer from '../reducers';

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
}

const middleware = [fetchMiddleware, thunkMiddleware];

export default (initialState = {}) => {
	const enhancer = composeEnhancers(applyMiddleware(...middleware));
	const store = createStore(rootReducer, initialState, enhancer);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			const nextReducer = rootReducer;
			store.replaceReducer(nextReducer);
		});
	}

	return store;
};

import { handleActions } from 'redux-actions';
import { SET_NOTIFICATION_SOUND, SET_INTERACTIVE_NOTIFICATION, SET_IOS_BADGE_TOGGLE_VALUE, SET_CAMPAIGN, SET_SEND_WINDOW_TOGGLE_VALUE, SET_SEND_WINDOW_TIME, SET_SEND_WINDOW_TIMEZONE } from '../actions/delivery-options';
import initialState from './InitialState';
import TimezoneUtils from '../utilities/timezone';

export default handleActions(
	{
		[SET_NOTIFICATION_SOUND]: (state, action) => ({
			...state,
			selectedNotificationSound: action.sound
		}),
		[SET_INTERACTIVE_NOTIFICATION]: (state, action) => ({
			...state,
			selectedInteractiveNotification: action.notification
		}),
		[SET_IOS_BADGE_TOGGLE_VALUE]: (state, action) => ({
			...state,
			isIOSBadgeToggleEnabled: action.value
		}),
		[SET_SEND_WINDOW_TOGGLE_VALUE]: (state, action) => ({
			...state,
			isSendWindowToggleEnabled: action.value
		}),
		[SET_CAMPAIGN]: (state, action) => ({
			...state,
			selectedCampaign: action.value
		}),
		[SET_SEND_WINDOW_TIME]: (state, action) => {
			if (action.time.type === 'start') {
				return ({
					...state,
					sendWindowStartTime: action.time.time
				});
			}

			return ({
				...state,
				sendWindowEndTime: action.time.time
			});
		},
		[SET_SEND_WINDOW_TIMEZONE]: (state, action) => ({
			...state,
			sendWindowTimeZone: TimezoneUtils.getTimezoneItemForComboboxById(action.timezone[0].id) // Keep the prop in fresh object form
		})
	},
	{
		selectedNotificationSound: initialState.selectedNotificationSound,
		selectedInteractiveNotification: initialState.selectedInteractiveNotification,
		isIOSBadgeToggleEnabled: initialState.isIOSBadgeToggleEnabled,
		isSendWindowToggleEnabled: initialState.isSendWindowToggleEnabled,
		selectedCampaign: initialState.selectedCampaign,
		sendWindowStartTime: initialState.sendWindowStartTime,
		sendWindowEndTime: initialState.sendWindowEndTime,
		sendWindowTimeZone: initialState.sendWindowTimeZone
	}
);

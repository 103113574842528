module.exports = function createViewFunctions(summaryView, decorator) {

    // lets the user set the activity name input field on the summary view
    summaryView.setActivityName = function(value) {
        decorator.setActivityName(value);
    };

    // lets the user set an error state on the activity name input
    summaryView.setActivityNameError = function(value) {
        decorator.setActivityNameError(value);
    };

    summaryView.setActivityNameReadOnly = function(value) {
        decorator.setActivityNameReadOnly(value);
    };

    summaryView.getActivityName = function(value) {
        decorator.getActivityName(value);
    };

    summaryView.setDescriptionReadOnly = function(value) {
        decorator.setDescriptionReadOnly(value);
    };

    // lets the user set the description input field on the summary view
    summaryView.setDescription = function(value) {
        decorator.setDescription(value);
    };

    // lets the user set an error state on the activity name input
    summaryView.setDescriptionError = function(value) {
        decorator.setDescriptionError(value);
    };

    summaryView.getDescription = function(value) {
        decorator.getDescription(value);
    };

    // hide or show the detail view
    summaryView.toggleDetailView = function() {
        decorator.toggleDetailView();
    };

};

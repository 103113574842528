module.exports = function createCustomViewFunctions(messageDefinitionView, decorator) {

    // allow the user to re-render the content builder grid
    messageDefinitionView.refreshContentBuilderGrid = function() {
        return decorator.refreshContentBuilderGrid();
    };

    // allow the user to re-render the legacy content area
    messageDefinitionView.refreshLegacyContentArea = function() {
        return decorator.refreshLegacyContentArea();
    };

    // add a function to the view to get the selected content
    messageDefinitionView.getContent = function() {
        return decorator.getContent();
    };

    // allow the user to set the content manually (required when using legacy content)
    messageDefinitionView.setContent = function(content) {
        decorator.setContent(content);
    };

    // set the selected tab
    messageDefinitionView.setSelectedTab = function(tab) {
        decorator.setSelectedTab(tab);
    };

    // get the selected tab
    messageDefinitionView.getSelectedTab = function() {
        return decorator.getSelectedTab();
    };

    // show the create message model
    messageDefinitionView.showCreateMessageModal = function(options) {
        return decorator.showCreateMessageModal(options);
    };

    // show the edit message model
    messageDefinitionView.showEditMessageModal = function(options) {
        return decorator.showEditMessageModal(options);
    };

};

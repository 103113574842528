import React, { useEffect } from 'react';
import Timepicker from '@salesforce/design-system-react/components/time-picker';
import moment from 'moment';

function WindowPicker ({
	i18n,
	startTime,
	endTime,
	onSelectTime,
	enabled,
	validationState
}) {
	moment.locale(i18n.getMomentLocale());
	const timePickerRef = React.createRef();
	const isFirstUpdate = React.useRef(enabled);

	useEffect(() => {
		const startTimePickerInput = timePickerRef.current.querySelector('#startTime input.slds-input');
		const endTimePickerInput = timePickerRef.current.querySelector('#endTime input.slds-input');
		const startTimeFormEl = timePickerRef.current.querySelector('#startTime .slds-form-element');
		const endTimeFormEl = timePickerRef.current.querySelector('#endTime .slds-form-element');

		if (isFirstUpdate.current) {
			// Add readonly and disabled attribute to Time Picker input components
			startTimePickerInput.setAttribute('readonly', 'readonly');
			endTimePickerInput.setAttribute('readonly', 'readonly');
		}
		isFirstUpdate.current = !enabled; // Apply readOnly attribute only once

		// Update time picker input state
		if (enabled) {
			startTimePickerInput.removeAttribute('disabled');
			endTimePickerInput.removeAttribute('disabled');
		} else {
			startTimePickerInput.setAttribute('disabled', 'disabled');
			endTimePickerInput.setAttribute('disabled', 'disabled');
		}

		// Validate time pickers
		if (validationState && validationState === 'error') {
			// Start time picker
			if (startTime.length === 0 && !startTimeFormEl.classList.contains('slds-has-error')) {
				startTimeFormEl.classList.add('slds-has-error');
			}

			if (startTime.length > 0 && startTimeFormEl.classList.contains('slds-has-error')) {
				startTimeFormEl.classList.remove('slds-has-error');
			}

			// End time picker
			if (endTime.length === 0 && !endTimeFormEl.classList.contains('slds-has-error')) {
				endTimeFormEl.classList.add('slds-has-error');
			}

			if (endTime.length > 0 && endTimeFormEl.classList.contains('slds-has-error')) {
				endTimeFormEl.classList.remove('slds-has-error');
			}
		}
	});

	const startTimePickerProps = {
		stepInMinutes: 30,
		label: i18n.get('custom_timeframe_scheduler_start_time'),
		required: true,
		placeholder: i18n.get('push_send_window_timepicker_placeholder'),
		onDateChange: (date, time) => {
			onSelectTime('start', time);
		},
		formatter: (date) => moment(date).format('LT'),
		id: 'start',
		disabled: !enabled
	};

	// Set start time value
	if (startTime && startTime.length > 0) {
		startTimePickerProps.value = moment(startTime, 'LT').toDate();
		startTimePickerProps.strValue = startTime;
	} else {
		startTimePickerProps.value = new Date(null);
		startTimePickerProps.strValue = '';
	}

	const endTimePickerProps = {
		stepInMinutes: 30,
		label: i18n.get('custom_timeframe_scheduler_end_time'),
		required: true,
		placeholder: i18n.get('push_send_window_timepicker_placeholder'),
		onDateChange: (date, time) => {
			onSelectTime('end', time);
		},
		formatter: (date) => moment(date).format('LT'),
		id: 'end',
		disabled: !enabled
	};

	// Set end time value
	if (endTime && endTime.length > 0) {
		endTimePickerProps.value = moment(endTime, 'LT').toDate();
		endTimePickerProps.strValue = endTime;
	} else {
		endTimePickerProps.value = new Date(null);
		endTimePickerProps.strValue = '';
	}

	return (
		<div className="window-picker slds-grid" ref={timePickerRef}>
			<div id="startTime">
				<Timepicker
					{...startTimePickerProps}
				/>
			</div>
			<div id="endTime">
				<Timepicker
					{...endTimePickerProps}
				/>
			</div>
		</div>
	);
}

export default WindowPicker;

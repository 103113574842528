var MessageDefinitionDecorator = require('./message-definition-decorator');
var configureView = require('./configure-view');
var loadContent = require('./load-content');
var assignDefaultOptions = require('./assign-default-options');
var createViewFunctions = require('./create-view-functions');
var log = require('../log');

module.exports = function MessageDefinitionExtension(scm, options, services) {
    var thisCreateDecorator = options.createDecorator || createDecorator;
    var thisConfigureView = options.configureView || configureView;
    var thisLoadContent = options.loadContent || loadContent;
    var thisAssignDefaultOptions = options.assignDefaultOptions || assignDefaultOptions;
    var thisCreateViewFunctions = options.createViewFunctions || createViewFunctions;
    var delegates = {};
    var messageDefinitionView = null;
    var decorator = null;

    delegates.onPreInitialize = function() {
        log('message definition is initializing');

        // assign any options that the user did not set
        thisAssignDefaultOptions(options, scm);

        // configure the view's header, footer, spoke, etc.
        messageDefinitionView = thisConfigureView(scm, options);

        // all of the interesting ui logic is in the decorator class
        // the decorator does owns all of the html rendering, element callbacks, etc.
        decorator = thisCreateDecorator({
            // the decorator will render everything into this view
            view: messageDefinitionView,
            MAJIK: services.MAJIK,
            scm: scm,
            contentBuilder: options.contentBuilder,
            showLegacyMessageTab: options.showLegacyMessageTab,
            showEditorInReadonlyMode: options.showEditorInReadonlyMode,

            // these factory functions are for dependency injection
            // they're mostly required for unit testing but we'll expose the option
            // to the channel developer in case they want to override something
            createContentBuilderGrid: options.createContentBuilderGrid,
            createPreviewSubscriberModal: options.createPreviewSubscriberModal,
            createEditorModal: options.createEditorModal
        });

        log('message definition decorator created');

        // add new functions to the view to allow the developer to interact with the decorator
        thisCreateViewFunctions(messageDefinitionView, decorator);
    };

    // called whenever a view initializes
    delegates.onLoad = function(accept, reject) {
        log('message definition is loading');

        // We use the onLoad callback to load an existing activity's asset from content builder.
        thisLoadContent({
            scm: scm,
            onLoadDone: accept,
            onLoadError: reject,
            preloadContentBuilder: options.preloadContentBuilder,
            getAssetId: options.getAssetId, // user must implement this function, we don't know how they store the asset ID
            contentBuilderOptions: options.contentBuilder,
            useContentBuilderAPI: options.useContentBuilderAPI,
            onAssetLoaded: function(asset) {
                log('setting content builder asset');
                decorator.setContent(asset);
            }
        });
    };

    // called whenever a view initializes
    delegates.onViewInitialized = function(e) {
        if (e.view === messageDefinitionView) {
            decorator.onViewInitialized();
        }
    };

    // called whenever a view is shown
    delegates.onViewShow = function(e) {
        if (e.view === messageDefinitionView) {
            decorator.onViewShow(e.options);
        }
    };

    // called whenever a view is hidden
    delegates.onViewHide = function(e) {
        if (e.view === messageDefinitionView) {
            decorator.onViewHide();
        }
    };

    // SCM will wire up these delegates inside the extension manager
    return delegates;
};

function createDecorator(options) {
    return new MessageDefinitionDecorator(options);
}

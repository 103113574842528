import React from 'react';
import PropTypes from 'prop-types';
import NotificationSound from './notification-sound';
import InteractiveNotification from './interactive-notification';
import IOSBadgeToggle from './ios-badge-toggle';
import SendWindow from './send-window';
import Campaign from './campaign';
import PostmongerStore from '../../utilities/postmonger';

function DeliveryOptions ({
	i18n,
	selectedNotificationSound,
	setNotificationSound,
	setInteractiveNotification,
	interactiveNotifications,
	selectedInteractiveNotification,
	isIOSBadgeToggleEnabled,
	setIOSBadgeToggleValue,
	isSendWindowToggleEnabled,
	setSendWindowToggleValue,
	onAddCampaign,
	onRemoveCampaign,
	selectedCampaign,
	onSelectSendWindow,
	sendWindowStartTime,
	sendWindowEndTime,
	onSelectSendWindowTimezone,
	sendWindowTimeZone,
	menuValidationState
}) {
	return (
		<div>
			<span className="section-subheading">{i18n.get('delivery_options_section_subheading')}</span>
			<NotificationSound
				i18n={i18n}
				selectedNotificationSound={selectedNotificationSound}
				setNotificationSound={setNotificationSound}
			/>
			{interactiveNotifications.length > 0 ? (
				<InteractiveNotification
					i18n={i18n}
					setInteractiveNotification={setInteractiveNotification}
					interactiveNotifications={interactiveNotifications}
					selectedInteractiveNotification={selectedInteractiveNotification}
				/>
			) : ''}
			<IOSBadgeToggle
				i18n={i18n}
				isIOSBadgeToggleEnabled={isIOSBadgeToggleEnabled}
				setIOSBadgeToggleValue={setIOSBadgeToggleValue}
			/>
			{PostmongerStore.isBROn('push_jb_send_window') ? (
				<SendWindow
					i18n={i18n}
					isSendWindowToggleEnabled={isSendWindowToggleEnabled}
					setSendWindowToggleValue={setSendWindowToggleValue}
					onSelectSendWindow={onSelectSendWindow}
					startTime={sendWindowStartTime}
					endTime={sendWindowEndTime}
					onSelectTimezone={onSelectSendWindowTimezone}
					timezone={sendWindowTimeZone}
					validationState={menuValidationState}
				/>
			) : '' }
			<Campaign
				i18n={i18n}
				onAddCampaign={onAddCampaign}
				onRemoveCampaign={onRemoveCampaign}
				selectedCampaign={selectedCampaign}
			/>
		</div>
	);
}

DeliveryOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	selectedNotificationSound: PropTypes.string.isRequired,
	setNotificationSound: PropTypes.func.isRequired,
	setInteractiveNotification: PropTypes.func.isRequired,
	interactiveNotifications: PropTypes.array.isRequired,
	selectedInteractiveNotification: PropTypes.array.isRequired,
	isIOSBadgeToggleEnabled: PropTypes.bool.isRequired,
	setIOSBadgeToggleValue: PropTypes.func.isRequired,
	isSendWindowToggleEnabled: PropTypes.bool,
	setSendWindowToggleValue: PropTypes.func,
	onAddCampaign: PropTypes.func.isRequired,
	onRemoveCampaign: PropTypes.func.isRequired,
	selectedCampaign: PropTypes.object.isRequired,
	onSelectSendWindow: PropTypes.func,
	sendWindowStartTime: PropTypes.string,
	sendWindowEndTime: PropTypes.string,
	onSelectSendWindowTimezone: PropTypes.func,
	sendWindowTimeZone: PropTypes.object,
	menuValidationState: PropTypes.string
};

export default DeliveryOptions;

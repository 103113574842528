export default function betterValidationExtension (scm, options) {
	const { saveButtonId } = options;

	const ValidationState = {
		error: 'error',
		warning: 'warning'
	};

	function invokeViewFunction (view, functionName, args) {
		if (view && typeof view[functionName] === 'function') {
			return view[functionName](args);
		}
		return null;
	}

	function invokeOnValidateFunction (view) {
		const state = invokeViewFunction(view, 'onValidate') || {};
		if (state.errors) return ValidationState.error;
		if (state.warnings) return ValidationState.warning;
		return null;
	}

	function isViewConfigured (view) {
		if (view && typeof view.configured === 'function') {
			return !!view.configured();
		}
		return false;
	}

	function setValidationState (view, state) {
		if (view.menu) {
			view.menu.validationState = state;
		}
		if (view.spoke) {
			view.spoke.validationState = state;
		}
	}

	function updateViewValidationState (view) {
		const status = invokeOnValidateFunction(view);
		setValidationState(view, status);
	}

	function resetMenuValidationStates () {
		scm.views().forEach((view) => {
			setValidationState(view, null);
		});
	}

	function updateConfiguredViews () {
		resetMenuValidationStates();
		scm.views().filter(isViewConfigured).forEach(updateViewValidationState);
		scm.refreshMenu();
	}

	scm.validation = {
		update: updateConfiguredViews
	};

	function onBeforeViewHide (e) {
		if (e.view !== scm.defaultView()) {
			updateConfiguredViews();
		}
	}

	function onViewInitialized (e) {
		if (e.view === scm.defaultView()) {
			updateConfiguredViews();
		}
	}

	function isViewRequired (view) {
		return view.menu && view.menu.required;
	}

	function isViewNotConfigured (view) {
		return !isViewConfigured(view);
	}

	function areAllRequiredViewsConfigured () {
		const anyUnconfigured = scm.views().filter(isViewRequired).some(isViewNotConfigured);

		return anyUnconfigured === false;
	}

	function maybeToggleDoneButtonState () {
		if (areAllRequiredViewsConfigured()) {
			scm.enableFooterButton(saveButtonId);
		} else {
			scm.disableFooterButton(saveButtonId);
		}
	}

	function onViewShow (e) {
		if (e.view === scm.defaultView()) {
			maybeToggleDoneButtonState();
		}
	}

	function isDefaultViewDoneButton (e) {
		const isDoneButton = e.buttonId === saveButtonId;
		const isDefaultView = e.view === scm.defaultView();
		return isDefaultView && isDoneButton;
	}

	function validateForSubmission (view) {
		const isRequired = isViewRequired(view);
		const isNotConfigured = isViewNotConfigured(view);
		if (isRequired && isNotConfigured) {
			scm.setMenuItemValidationState(view.name, ValidationState.error);
			return ValidationState.error;
		}
		const state = invokeOnValidateFunction(view);
		scm.setMenuItemValidationState(view.name, state);
		return state;
	}

	function doDoneButtonValidation (e) {
		invokeViewFunction(scm.defaultView, 'onBeforeValidateAllViews');

		const results = scm.views().map(validateForSubmission);

		const errorCount = results.reduce((sum, state) => {
			if (state === ValidationState.error) {
				// eslint-disable-next-line no-param-reassign
				sum++;
			}
			return sum;
		}, 0);

		if (errorCount === 0) {
			return; // no errors, continue execution
		}

		e.cancel = true; // validation failed. cancel the click callback

		const doneButtonElement = scm.config.element.querySelector(`#${saveButtonId}`);

		scm.showPopover({
			target: doneButtonElement,
			position: 'over',
			type: 'error',
			title: scm.i18n.get('majik_validation_alert_title'),
			text: scm.i18n.get('majik_validation_alert_text'),
			offset: {
				left: -20
			}
		});
	}

	function onFooterButtonClicked (e) {
		if (isDefaultViewDoneButton(e)) {
			doDoneButtonValidation(e);
		}
	}

	return {
		onViewShow,
		onBeforeViewHide,
		onViewInitialized,
		onFooterButtonClicked
	};
}

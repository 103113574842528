import McFetch from '@salesforce-mc/fetch-maint';
import fetchMiddlewareFn from '@salesforce-mc/fetch-maint/middleware/index';

const fetchApi = new McFetch({
	applicationName: 'MobilePush Push Activity',
	defaultOptions: {
		credentials: 'include'
	}
});

const fetchMiddleware = fetchMiddlewareFn(fetchApi);

export default fetchMiddleware;

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@salesforce-mc/assetutils"), require("@salesforce-mc/contentbuilder-sdk"));
	else if(typeof define === 'function' && define.amd)
		define(["@salesforce-mc/assetutils", "@salesforce-mc/contentbuilder-sdk"], factory);
	else if(typeof exports === 'object')
		exports["MAJIKMessageActivity"] = factory(require("@salesforce-mc/assetutils"), require("@salesforce-mc/contentbuilder-sdk"));
	else
		root["MAJIKMessageActivity"] = factory(root["@salesforce-mc/assetutils"], root["@salesforce-mc/contentbuilder-sdk"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__9330__, __WEBPACK_EXTERNAL_MODULE__3466__) {
return 
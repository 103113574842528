import React from 'react';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Tooltip from '../common/tooltip';
import TimezonePicker from './timezone-picker';
import WindowPicker from './window-picker';

function SendWindow ({
	i18n,
	classNames,
	isSendWindowToggleEnabled,
	setSendWindowToggleValue,
	startTime,
	endTime,
	onSelectSendWindow,
	onSelectTimezone,
	timezone,
	validationState
}) {
	return (
		<div className="send-window-container slds-size_1-of-2">
			<div className="container-heading">{ i18n.get('push_send_window') }</div>
			<Tooltip
				i18n={i18n}
				cid="mp-message-sendwindow"
			/>
			<Checkbox
				id="send-window-toggle-checkbox"
				variant="toggle"
				labels={{
					toggleEnabled: i18n.get('enabled'),
					toggleDisabled: i18n.get('disabled')
				}}
				checked={isSendWindowToggleEnabled}
				onChange={setSendWindowToggleValue}
			/>
			<div className={classNames}>
				<WindowPicker
					i18n={i18n}
					enabled={isSendWindowToggleEnabled}
					startTime={startTime}
					endTime={endTime}
					onSelectTime={onSelectSendWindow}
					validationState={validationState}
				/>
				<TimezonePicker
					i18n={i18n}
					enabled={isSendWindowToggleEnabled}
					selectedTimezone={timezone}
					onSelectTimezone={onSelectTimezone}
					validationState={validationState}
				/>
			</div>
		</div>
	);
}

export default SendWindow;

export const SET_MOBILE_APP = 'SET_MOBILE_APP';
export const FETCH_MOBILE_APPS = 'FETCH_MOBILE_APPS';
export const FETCH_MOBILE_APP_DETAIL = 'FETCH_MOBILE_APP_DETAIL';
export const SET_INTERACTIVE_NOTIFICATIONS = 'SET_INTERACTIVE_NOTIFICATIONS';
export const SET_MOBILE_APP_DETAIL = 'SET_MOBILE_APP_DETAIL';
export const UPDATE_CUSTOM_KEYS = 'UPDATE_CUSTOM_KEYS';

export const setMobileApp = (app) => ({
	type: SET_MOBILE_APP,
	app
});

export const fetchMobileApps = {
	type: FETCH_MOBILE_APPS,
	fetch: {
		url: '/fuelapi/push-internal/v1/application?&$pageSize=1000&$page=1&$orderBy=name%20ASC'
	}
};

export const fetchMobileAppDetail = (id) => ({
	type: FETCH_MOBILE_APP_DETAIL,
	fetch: {
		url: `/fuelapi/push-internal/v1/application/${id}`
	}
});

export const setInteractiveNotifications = (notifications) => ({
	type: SET_INTERACTIVE_NOTIFICATIONS,
	notifications
});

export const setMobileAppDetail = (detail) => ({
	type: SET_MOBILE_APP_DETAIL,
	detail
});

export const updateCustomKeys = (keys) => ({
	type: UPDATE_CUSTOM_KEYS,
	keys
});

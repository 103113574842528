import './datatable.scss';
import Header from './header';
import Body from './body';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { html } from '../../../utilities/helper';

export default function (dom, tableOptions, fetchData, fetchOptions) {
	let pageIndex = fetchOptions.page;
	let fetching = false;
	let maxDataCount;
	let selectedRowId = tableOptions.selectedDataId;

	const onStartFetch = (isAdditionalFetch) => {
		fetching = true;

		if (isAdditionalFetch) {
			dom.querySelector('tbody').classList.add('additional-fetching');
		} else {
			dom.querySelector('tbody').classList.add('fetching');
		}
	};

	const onEndFetch = (isAdditionalFetch) => {
		fetching = false;

		if (isAdditionalFetch) {
			dom.querySelector('tbody').classList.remove('additional-fetching');
		} else {
			dom.querySelector('tbody').classList.remove('fetching');
		}
	};

	const hasMoreData = () => dom.querySelectorAll('tbody tr.slds-hint-parent').length < maxDataCount;

	const addLoadMoreItemsRow = () => {
		if (fetching) {
			return;
		}

		this.body.appendAdditionalRow({
			id: 'load-more-items-row',
			text: tableOptions.templateOptions.loadMoreItemsText,
			onSelect: () => {
				const sortedHeaderCell = dom.querySelector('thead .slds-is-sorted');
				const { orderby, sortdirection } = sortedHeaderCell.dataset;
				const newFetchOptions = {
					...fetchOptions,
					pageSize: DEFAULT_PAGE_SIZE,
					page: ++pageIndex,
					orderby,
					sortdirection,
					selectedDataId: selectedRowId
				};

				// Fetch additional data
				// 1. Add loader row during fetch
				// 2. Append additional rows
				// 3. Append no more items row or load more items row
				onStartFetch(true);
				this.body.appendLoaderRow();
				fetchData(newFetchOptions).then((data) => {
					onEndFetch(true);
					maxDataCount = data.count;

					this.body.appendRows({
						items: data.items,
						pageIndex
					});

					if (hasMoreData()) {
						addLoadMoreItemsRow();
					} else if (maxDataCount !== 0 && !document.body.contains(dom.querySelector('tbody #no-more-items-row'))) {
						this.body.appendAdditionalRow({
							id: 'no-more-items-row',
							text: tableOptions.templateOptions.noMoreItemsText
						});
					}
				});
			}
		});
	};

	const renderBody = (items) => {
		const container = dom.querySelector('tbody');
		const configs = {
			i18n: tableOptions.i18n,
			templateOptions: {
				items: items || [],
				noItemsText: tableOptions.templateOptions.noItemsText,
				pageIndex
			},
			events: {
				onSelectTableRow: (e) => {
					selectedRowId = e.target.closest('tr').id;
					tableOptions.events.onSelectTableRow(e);
				}
			}
		};

		this.body = new Body(container, configs);

		if (!items) {
			return;
		}

		if (hasMoreData()) {
			addLoadMoreItemsRow();
		} else if (maxDataCount !== 0 && !document.body.contains(dom.querySelector('tbody #no-more-items-row'))) {
			this.body.appendAdditionalRow({
				id: 'no-more-items-row',
				text: tableOptions.templateOptions.noMoreItemsText
			});
		}
	};

	const onSelectTableHeader = (e) => {
		const headerEl = e.target.closest('th');
		const { orderby } = headerEl.dataset;
		const sortdirection = headerEl.dataset.sortdirection === 'DESC' ? 'ASC' : 'DESC'; // Toggle sort direction
		const newFetchOptions = {
			...fetchOptions,
			orderby,
			sortdirection,
			selectedDataId: selectedRowId
		}; // Option with sort data

		// Update sort direction in DOM
		headerEl.dataset.sortdirection = sortdirection;

		// Reset page index
		pageIndex = fetchOptions.page;

		// Update class names
		// 1. Remove 'sorted' class from all header cells
		// 2. Add 'sorted' class to selected header cell
		if (!headerEl.classList.contains('slds-is-sorted')) {
			dom.querySelectorAll('thead tr th').forEach((cell) => {
				cell.classList.remove('slds-is-sorted');
			});
			headerEl.classList.add('slds-is-sorted');
		}

		renderBody(); // Render Spinner first and fetch data
		onStartFetch();
		fetchData(newFetchOptions).then((data) => {
			onEndFetch();
			maxDataCount = data.count;
			renderBody(data.items);
		});
	};

	const renderHeader = () => {
		const headerEl = dom.querySelector('thead');
		const headerOptions = {
			templateOptions: {
				headers: tableOptions.templateOptions.headers
			},
			events: {
				onSelectTableHeader: onSelectTableHeader
			}
		};

		this.header = new Header(headerEl, headerOptions);
	};

	const render = () => {
		const template = html`
			<table class="slds-table slds-table_bordered slds-table_fixed-layout slds-table_resizable-cols" role="grid">
				<thead></thead>
				<tbody></tbody>
			</table>
		`;

		dom.insertAdjacentHTML('beforeend', template);

		renderHeader();

		// Render empty body with spinner first
		onStartFetch();
		renderBody();

		// If data is stil fetching, skip render
		if (tableOptions.isFetching) {
			return;
		}

		onEndFetch();
		maxDataCount = tableOptions.data.count;
		renderBody(tableOptions.data.items);
	};

	const initialize = () => {
		render();
	};

	initialize();
}

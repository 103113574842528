import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../common/tooltip';
import CustomTooltip from '../common/custom-tooltip';

function ReportingId ({ i18n }) {
	return (
		<div className="reporting-id-container">
			<div className="container-heading">{i18n.get('reporting_id_heading')}</div>
			<Tooltip
				i18n={i18n}
				cid="mp-messages-reportingid"
				arrowPosition="left"
			/>
			<CustomTooltip
				shouldSetInnerHTML
				linkText={i18n.get('majik_reporting_id_help_label')}
				tooltipText={i18n.get('reporting_id_help_content')}
			/>
		</div>
	);
}

ReportingId.propTypes = {
	i18n: PropTypes.object.isRequired
};

export default ReportingId;

var SummaryDecorator = require('./summary-decorator');
var configureView = require('./configure-view');
var createViewFunctions = require('./create-view-functions');
var assignDefaultOptions = require('./assign-default-options');
var log = require('../log');

module.exports = function SummaryViewExtension(scm, options, services) {
    var thisCreateDecorator = options.createDecorator || createDecorator;
    var thisConfigureView = options.configureView || configureView;
    var thisAssignDefaultOptions = options.assignDefaultOptions || assignDefaultOptions;
    var thisCreateViewFunctions = options.createViewFunctions || createViewFunctions;
    var delegates = {};
    var journeyState = null;
    var decorator = null;
    var summaryView = null;

    // this delegate fires as soon as all extensions are loaded
    delegates.onPreInitialize = function() {
        log('summary view is initializing');

        // assign any options that the user did not set
        thisAssignDefaultOptions(options);

        // setup configuration for the header, footer, etc.
        summaryView = thisConfigureView(scm, options);

        // all of the interesting ui logic is in this class
        decorator = thisCreateDecorator({
            isActivityNameRequired: options.isActivityNameRequired || false,
            MAJIK: services.MAJIK,
            view: summaryView, // the decorator will render everything into this view
            scm: scm
        });

        log('summary view decorator created');

        // add new functions to the view to allow the developer to interact with the decorator
        thisCreateViewFunctions(summaryView, decorator);
    };

    // this delegate fires while scm is in its loading state
    delegates.onLoad = function(done) {
        log('summary view is loading');

        // these options will appear in the header's context picker if the feature is enabled
        scm.setContextOptions([
            {
                id: 'analytics-context',
                text: scm.i18n.get('majik_ce_analytics'),
                onClick: function() {
                    scm.applyConfiguration('analytics');
                }
            },
            {
                id: 'activity-context',
                text: scm.i18n.get('majik_ce_activity_summary'),
                selected: true,
                onClick: function() {
                    scm.applyConfiguration('default');
                }
            }
        ]);

        log('request interaction from Journey Builder...');

        // we'll need the journey's state in the onLoadComplete delegate
        scm.sdk.requestInteraction(function(e) {
            log('Journey Builder responded with interaction data');
            journeyState = e;
            done();
        });
    };

    // this delegate after scm has finished loading but before the first view is shown
    delegates.onLoadComplete = function() {
        log('completed loading extensions');

        // we're going to configure the context picker that shows up in the header.
        // the context picker should only appear if:
        //  1. The "analytics" configuration exists
        //  2. The Journey state is running, stopped, or paused
        //  3. If the channel dev enabled the context switching feature

        var status = journeyState.status;
        var isToggleEnabled = Boolean(options.showAnalyticsToggle);
        var isAnalyticsEnabled = scm.hasConfiguration('analytics');
        var isJourneyActivated = status === 'RUNNING' || status === 'STOPPED' || status === 'PAUSED';

        // short circuit if we should not show the context picker
        if (!isToggleEnabled || !isAnalyticsEnabled || !isJourneyActivated) {
            log('analytics extension is not enabled');
            hideContextSwitcher(scm);
            return;
        }

        log('analytics extension has been loaded');

        // make sure the context picker is visible in the summary header
        summaryView.header.showContext = true;

        // calling this function programmatically selects the context picker value
        scm.selectContext('analytics-context');
    };

    // this delegate fires before each view is shown
    delegates.onBeforeViewShow = function(e) {
        if (e.view === summaryView) {
            decorator.onBeforeViewShow();
        }
    };

    // this delegate fires when each view is hidden
    delegates.onViewHide = function(e) {
        if (e.view === summaryView) {
            decorator.onViewHide();
        }
    };

    // this delegate fires when scm's read-only state changes
    delegates.onReadonlyStateChange = function(e) {
        decorator.onReadonlyStateChange(e);
    };

    // all of these delegates will be registered with the scm instance
    return delegates;
};

function createDecorator(options) {
    return new SummaryDecorator(options);
}

function hideContextSwitcher(scm) {
    var switcher = scm.config.element.querySelector('.scm-common-header-switcher');
    if (switcher) {
        switcher.style.display = 'none';
    }
}

var MessageDefinition = require('../message-definition/extension');
var MessageSummary = require('../message-summary/extension');
var ErrorView = require('../error-view/extension');
var log = require('../log');

// The MessageActivity extension is a wrapper extension. It dynamically registers all of the extensions for
// Hub-And-Spoke, the messaging summary view, the message definition view, and the generic error view
module.exports = function MessageActivity(scm, options, services) {
    var MAJIK = services.MAJIK;

    log('MessageActivity extension registered');

    // this extension generates the generic error view
    scm.registerExtension({
        extension: ErrorView
    });

    // this extension generates the summary view for the channel dev
    scm.registerExtension({
        extension: MessageSummary,
        options: options.summaryView
    });

    // this extension generates the message definition view and edit/create modals
    scm.registerExtension({
        extension: MessageDefinition,
        options: options.messageDefinition
    });

    // the hub and spoke extension lets us use the `view.spoke` property
    // it uses the spokes to generate the html on the summary view.
    scm.registerExtension({
        extension: MAJIK.extensions.SCMHubAndSpoke,
        options: {
            autoConfigure: true,
            spokeListSelector: '#summary-hub-spokes'
        }
    });

};

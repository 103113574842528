import { html } from '../../../../utilities/helper';
import '../datatable.scss';

export default function (dom, configs) {
	const renderTableLoader = () => html`
		<div class="loader">
		  <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_large">
			 <div class="slds-spinner__dot-a"></div>
			 <div class="slds-spinner__dot-b"></div>
		  </div>
		</div>
	`;

	const renderRow = ({ selected, id, name, appName, hasIcon, appIconSrc, lastModified, pageIndex }) => html`
		<tr class="slds-hint-parent ${selected ? 'selected' : ''}" id=${id} data-messagename='${name}' data-pageindex='${pageIndex}'>
			<td class="selection-col slds-text-align_right" role="gridcell">
				<span class="check-icon slds-icon_container null slds-icon__svg--default">
					<svg class="slds-icon slds-icon--x-small" aria-hidden="true">
						<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
					</svg>
				</span>
			</td>
			<td class="message" role="gridcell">
				<div class="slds-truncate" title='${name}'>${name}</div>
			</td>
			<td class="app" role="gridcell">
				<div class="slds-truncate" title='${appName}'>
					${hasIcon ? `{<img class="icon" src="${appIconSrc}" />}` : ''}
					<span class="name">${appName}</span>
				</div>
			</td>
			<td class="last-modified" role="gridcell">
				<div class="slds-truncate" title='${lastModified}'>${lastModified}</div>
			</td>
		</tr>
	`;

	const renderNoItemRow = (noItemsText) => html`
		<tr id="no-items-row" class="additional-row">
			<td colspan="4">
				${noItemsText}
			</td>
		</tr>
	`;

	const renderRows = ({ items, noItemsText }) => `
		${renderTableLoader()}
		${items.length > 0 ? items.map((item) => renderRow(item)).join('') : renderNoItemRow(noItemsText)}
	`;

	const render = () => {
		dom.innerHTML = renderRows(configs.templateOptions);
	};

	const addEvents = (pageIndex) => {
		// Listener for row click
		dom.querySelectorAll(`.slds-table tbody tr.slds-hint-parent[data-pageindex="${pageIndex}"]`).forEach((row) => row.addEventListener('click', configs.events.onSelectTableRow));
	};

	const deleteRow = (selector) => {
		const row = dom.querySelector(selector);

		if (!row) {
			return;
		}

		row.parentNode.removeChild(row);
	};

	const deleteLoaders = () => {
		deleteRow('#loader-row');
		dom.querySelectorAll('.loader').forEach((e) => e.parentNode.removeChild(e)); // Clean up unused spinners
	};

	// Public Methods
	this.appendLoaderRow = () => {
		deleteRow('#load-more-items-row');

		dom.insertAdjacentHTML('beforeend', html`
			<tr id="loader-row">
				<td colspan="4">
					<div class="loader-row-container">
					  <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_inline slds-spinner_x-small">
						 <div class="slds-spinner__dot-a"></div>
						 <div class="slds-spinner__dot-b"></div>
					  </div>
					  <span>${configs.i18n.get('loading')}</span>
					</div>
				</td>
			</tr>
		`);
	};

	this.appendAdditionalRow = ({ id, text, onSelect }) => {
		deleteLoaders();

		dom.insertAdjacentHTML('beforeend', html`
			<tr id=${id} class="additional-row">
				<td colspan="4">
					${text}
				</td>
			</tr>
		`);

		if (onSelect && typeof onSelect === 'function') {
			dom.querySelector(`#${id}`).addEventListener('click', onSelect);
		}
	};

	this.appendRows = (templateOptions) => {
		deleteLoaders();

		dom.insertAdjacentHTML('beforeend', renderRows(templateOptions));

		addEvents(templateOptions.pageIndex);
	};

	const initialize = () => {
		render();
		addEvents(configs.templateOptions.pageIndex);
	};

	initialize();
}

/*
	config options:
	-  type: string (optional) (info | warning | error | offline (default: error)
	-  message: string (optional)
	-  close: bool (optional) (default: true)
*/

import Template from './alert.html';
import './alert.css';

export default function (dom, configs) {
	const { type, message, close } = configs;
	
	const addEvents = () => {
		dom.querySelector('.slds-notify__close').addEventListener('click', () => {
			dom.innerHTML = '';
		});
	};
	
	const setControls = () => {
		const alertType = type || 'error';
		
		switch (alertType) {
			case 'info':
				dom.querySelector('.slds-notify').classList.add('slds-theme_info');
				dom.querySelector('.slds-icon').innerHTML = '<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#user"></use>';
				break;
				
			case 'warning':
				dom.querySelector('.slds-notify').classList.add('slds-theme_warning');
				dom.querySelector('.slds-icon').innerHTML = '<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>';
				break;
				
			case 'offline':
				dom.querySelector('.slds-notify').classList.add('slds-theme_offline');
				dom.querySelector('.slds-icon').innerHTML = '<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#offline"></use>';
				break;
				
			default:
				dom.querySelector('.slds-notify').classList.add('slds-theme_error');
				dom.querySelector('.slds-icon').innerHTML = '<use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>';
		}
		
		if (message) {
			dom.querySelector('h2').innerHTML = message;
		}
		
		if ((close) && (close === false)) {
			dom.querySelector('.slds-notify__close').remove();
		}
	};
		
	const render = () => {
		dom.innerHTML = Template;
	};
	
	const initialize = () => {
		render();
		setControls();
		addEvents();
	};
	
	initialize();
}

var copyObject = require('./copy-object');

module.exports = function(scm, ContentBuilderSDK) {
    function newContentBuilderSDKEditor(options) {
        var modalElement = options.element;
        var onInitializeCallback = options.onInitialize;
        var contentBuilderOptions = options.contentBuilderOptions;
        var content = options.content || null;

        // We're going to bundle all of our SDK Editor args into an event argument, then trigger
        // a callback so channel developers can modify the arguments before the editor is created.
        var event = {
            stack: contentBuilderOptions.stack,

            // include the options that were passed in via scm.show() in case the caller passed
            // additional info that will drive logic in the onCreateContentBuilderEditor() callback
            viewOptions: options.viewOptions,

            // We're going to copy whatever arguments the user gives us in the extension options. That way all
            // config can be given by the user and this majik extension wont have to change there are updates to the SDK
            options: copyObject(contentBuilderOptions.editor, {
                view: null,
                showActions: true,
                readOnly: options.isReadOnly,
                isDirty: options.onDirtyStateChange,
                asset: {
                    assetType: {
                        id: contentBuilderOptions.editor.assetTypeId
                    }
                },
                onExit: options.onClose,
                onSave: options.onSaveClicked
            })
        };

        // If options.content has a value then we're editing content, otherwise we
        // are creating new content and we shouldn't change event.options.asset
        if (content) {
            event.options.asset = content;
        }

        // notify the channel dev that the editor will be created,
        // and give them the chance to modify the editor arguments
        options.onCreateContentBuilderEditor(event);

        // create the editor
        return new ContentBuilderSDK.Editor(
            modalElement,
            event.stack,
            event.options,
            onInitializeCallback
        );
    }

    var EditorModal = {
        className: 'edit-message-modal',
        closeButtonClass: 'slds-hide',

        onInitialize: function() {
            this.onDirtyStateChange = this.onDirtyStateChange.bind(this);
            this.isDirty = true; // set to true for now. will update when content builder sdk dirty state is working
            this.lastSaved = null;
            this.editorService = newContentBuilderSDKEditor({
                viewOptions: this.options.viewOptions,
                isReadOnly: this.options.isReadOnly,
                element: this.element,
                content: this.options.content || null,
                contentBuilderOptions: this.options.contentBuilderOptions,
                onDirtyStateChange: this.onDirtyStateChange,
                onInitialize: this.onEditorInitialize.bind(this),
                onCreateContentBuilderEditor: this.options.onCreateContentBuilderEditor,
                onClose: this.close,
                onSaveClicked: this.onSaveClicked.bind(this)
            });
        },

        onEditorInitialize: function() {
            //console.log('onEditorInitialize');
        },

        onSaveClicked: function(status, data) {
            if (status === 'SUCCESS') {
                this.options.onSave(data.asset);
            } else if (status === 'ERROR') {
                this.options.onSaveError();
            }
        },

        onDirtyStateChange: function(e) {
            this.isDirty = e.isDirty;
            this.lastSaved = e.lastSaved;
        },

        onClose: function() {
            this.editorService.destroy();
            this.options.onClose();
        }

    };

    return EditorModal;
};

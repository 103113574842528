var ErrorViewHtml = require('./templates/error-view.html');

module.exports = function ErrorView(scm, options, services) {
    var Mustache = services.MAJIK.Mustache;

    if (!scm.config.onLoadError) {
        scm.config.onLoadError = function () {
            scm.show('error');
        };
    }

    // Add a new view to display a generic error.
    // this should only be shown when there's a fatal error that the activity cant recover from.
    scm.config.views.error = {
        header: {
            text: '',
            onClick: 'scm:close'
        },
        menu: {
            hidden: true,
            invisible: true
        },
        footer: {
            cancel: {
                text: scm.i18n.get('majik_hs_cancel_button')
            }
        },
        onShow: function(scmm, opts) {
            var mainText = opts.mainText || scm.i18n.get('majik_ce_error_view_main_text');
            var subText = opts.subText || scm.i18n.get('majik_ce_error_view_sub_text');

            scm.element.innerHTML = Mustache.render(ErrorViewHtml, {
                majik_ce_error_view_main_text: mainText,
                majik_ce_error_view_sub_text: subText,
                majik_ce_refresh_activity: scm.i18n.get('majik_ce_refresh_activity')
            });

            var refreshButton = scm.element.querySelector('#refresh');
            refreshButton.addEventListener('click', function() {
                scm.reload();
            });
        }
    };
};

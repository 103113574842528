(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@salesforce-mc/securehtmlutils"));
	else if(typeof define === 'function' && define.amd)
		define(["@salesforce-mc/securehtmlutils"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@salesforce-mc/securehtmlutils")) : factory(root["@salesforce-mc/securehtmlutils"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__424__) {
return 
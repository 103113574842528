import MobileApplication from '../components/message-configuration/mobile-application';
import { hasProperty } from '../utilities/helper';

export default function (dom, configs) {
	const { i18n, store, sectionKey } = configs;

	const renderMobileApplication = () => {
		const container = dom.querySelector('#mobile-application-container');

		this.mobileapplication = new MobileApplication(container, configs);
	};

	const renderHeader = () => {
		const subHeading = i18n.get('message_configuration_section_subheading');

		dom.querySelector('#subheader-container').innerHTML = subHeading;
	};

	const render = () => {
		dom.innerHTML = `
			<div class="message-configuration">
				<div id="subheader-container" class="section-subheading"></div>
				<div id="mobile-application-container">
			</div>
		`;
	};

	const initialize = () => {
		if (!hasProperty(store[sectionKey], 'payload')) {
			store[sectionKey].payload = {};
		}

		render();
		renderHeader();
		renderMobileApplication();
	};

	initialize();

	this.reRenderMobileApplication = (applications) => {
		configs.isFetching = false;
		configs.applications = applications || [];

		renderMobileApplication();
	};
}

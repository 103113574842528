import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */

class CustomTooltip extends React.Component {
	constructor (props) {
		super(props);
		this.state = { isTooltipActive: false };
	}

	handleClickLink = (e) => {
		e.preventDefault();
		this.setState({ isTooltipActive: true });
	};

	handleBlurLink = () => {
		this.setState({ isTooltipActive: false });
	};

	render () {
		const { linkText, tooltipText, shouldSetInnerHTML } = this.props;
		const { isTooltipActive } = this.state;
		return (
			<div id="custom-tooltip-container">
				<div className="slds-form-element help-label">
					<a href="#" onClick={this.handleClickLink} onBlur={this.handleBlurLink}>{linkText}</a>
				</div>
				<div className="help-tooltip">
					<div
						id="custom-tooltip"
						className={`tooltip-template slds-popover slds-popover_small slds-popover_tooltip slds-nubbin_top-left ${isTooltipActive ? 'slds-rise-into-ground' : 'slds-fall-into-ground'}`}
						role="tooltip"
					>
						{shouldSetInnerHTML
							? (<div className="slds-popover__body" dangerouslySetInnerHTML={{ __html: tooltipText }} />)
							: (<div className="slds-popover__body">{tooltipText}</div>)}
					</div>
				</div>
			</div>
		);
	}
}

CustomTooltip.propTypes = {
	shouldSetInnerHTML: PropTypes.bool,
	linkText: PropTypes.string,
	tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

CustomTooltip.defaultProps = {
	shouldSetInnerHTML: false
};

export default CustomTooltip;

const COMPILE_MODES = require('./constants/compile-modes');

function getReferences (content, type) {
	var typeMarker = new RegExp(`<div[^>]*?(data-type="${ type }"|data-key="([^"])*?")[^>]*?(data-type="${ type }"|data-key="([^"])*?")[^>]*?(/|>[^<]*?</div)>`, 'g');
	return content.match(typeMarker) || [];
}

function _calculateAutoMode (asset) {
	if (asset && asset.assetType && asset.assetType.id && [5, 207, 208, 209, 205].indexOf(asset.assetType.id) !== -1) {
		return COMPILE_MODES.SUPER;
	}
	return COMPILE_MODES.FULL;
}

function getView (asset, view) {
	if (view && asset.views) {
		const assetView  = asset.views[view];
		let emptyView = {};
		if (assetView && Object.keys(assetView).length > 0) {
			return {
				view: asset.views[view],
				name: view
			}
		} else if (assetView && Object.keys(assetView).length === 0) {
			//store the empty view to return it later if we dont have any other
			emptyView = {
				view: asset.views[view],
				name: view
			}
		}
		for (const viewItem in asset.views) {
			if (asset.views.hasOwnProperty(viewItem) && Object.keys(asset.views[viewItem]).length > 0) {
				if (viewItem.toLowerCase() === view.toLowerCase()) {
					return {
						view: asset.views[viewItem],
						name: viewItem
					}
				}
			}
		}
		if (emptyView.name) {
			return emptyView;
		}
	}
	return undefined;
}

function compile (asset, channel, mode, callback, referenceKey) {
	var isAmp = mode === COMPILE_MODES.PREVIEW_AMP;
	var content;
	asset = asset || {};
	asset.slots = asset.slots || {};
	asset.blocks = asset.blocks || {};

	if (typeof(callback) === 'function') {
		asset = callback(asset, referenceKey);
	}

	if (mode === COMPILE_MODES.PREVIEW || isAmp) {
		mode = _calculateAutoMode(asset);
	}

	if (mode === COMPILE_MODES.FULL) {
		content = asset.superContent || asset.content || asset.design;
	} else if (mode === COMPILE_MODES.SUPER) {
		content = asset.superContent || asset.content;
	} else {
		content = asset.content;
	}
	if (content) {
		['slot', 'block'].forEach(function (type) {
			var references = getReferences(content, type);
			var types = `${type }s`;
			references.forEach(function (reference) {
				var refKey = reference.split('data-key="')[1].split('"')[0];
				var objAsset = asset[types] && (asset[types][refKey] || asset[types][(refKey.charAt(0).toLowerCase() + refKey.slice(1))] || asset[types][refKey.toLowerCase()]);
				if (objAsset) {
					content = content.replace(reference, function (ref) {
						return (mode === COMPILE_MODES.INCLUDE_MARKERS ? ref : '') + compile(objAsset, channel, mode, callback, refKey);
					});
				}
			});
		});
		return content;
	} else if (asset.views) {
		if (channel) {
			const viewObj = getView(asset, channel);
			if (viewObj) {
				return compile(viewObj.view, viewObj.name, mode, callback);
			}
		}

		if (isAmp && asset.views.amp) {
			return compile(asset.views.amp, channel, mode, callback);
		}

		if (asset.views.html) {
			return compile(asset.views.html, channel, mode, callback);
		}
	}
	return '';
}

module.exports = {
	compile: compile,
	getReferences: getReferences,
	getView: getView
};

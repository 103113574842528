const SecureHtmlUtils = require('@salesforce-mc/securehtmlutils');
const compile = require('./compile').compile;

function _calculateCanvasWidth (thumbnailInfo) {
	var canvasWidth;
	var assetType = thumbnailInfo.asset && thumbnailInfo.asset.assetType;

	if (assetType && assetType.hasOwnProperty('id') && assetType.id && (assetType.id === 230)) {
		canvasWidth = _calculateCanvasWidthByChannel(thumbnailInfo.channel);
	} else {
		canvasWidth = _calculateCanvasWidthByAssetType(assetType);
	}

	return canvasWidth;
}

function _calculateCanvasWidthByChannel (channel) {
	var canvasWidth = 800;

	if (typeof channel === 'string' && channel.toLowerCase() === 'sms') {
		// SMS messages need 292px
		canvasWidth = 292;
	}

	return canvasWidth;
}

/*
 * @desc  Return width of asset based on asset's type
 *
 * @param assetType object
 */
function _calculateCanvasWidthByAssetType (assetType) {
	var canvasWidth = 800;
	if (assetType) {
		/*
		 * Using .endsWith() would be so much easier
		 * Since that is ES6 we should use something else for the time being (lastIndexOf)
		 */
		if (assetType.hasOwnProperty('name') && assetType.name && (assetType.name.lastIndexOf('block', assetType.name.length - 5)) === (assetType.name.length - 5)) {
			// block canvas width is 400px
			canvasWidth = 400;
			if (assetType.hasOwnProperty('id') && assetType.id && (assetType.id === 213)) {
				// except layout blocks. They get 600px
				canvasWidth = 600;
			}
		} else if (assetType.hasOwnProperty('id') && assetType.id && [205, 206].indexOf(assetType.id) !== -1) {
			// web pages and templates need 1280 px
			canvasWidth = 1280;
		}
	}
	return canvasWidth;
}


/**
 * @typedef thumbnailInfo
 * @type {object}
 * @property {object} asset - Model of asset
 * @property {number} thumbnailWidth - The width of the thumbnail to be create
 * @property {number} thumbnailHeight - The height of the thumbnail to be create
 * @property {number} canvasWidth - The width of the asset.
 * @property {number} canvasHeight - The height of the asset. (only needed by generateZoomableThumbnail or generateZoomToFitThumbnail)
 * @property {string} channel - Asset channel - used with jsonmessage assetType to determine thumbnail content and canvas width
 * @property {string} thumbnailType - Either [ '' | 'zoomToFit' | 'verticalScrollbar' } determines what type of thumbnail to generate
 */

/**
 * @typedef scalingInfo
 * @type {object}
 * @property {number} thumbnailWidth - The width of the thumbnail to be create
 * @property {number} thumbnailHeight - The height of the thumbnail to be create
 * @property {number} canvasWidth - The width of the asset.
 * @property {number} canvasHeight - The height of the asset.
 * @property {number} scaleRatio - The ratio to use to for scaling.
 * @property {boolean} yScrollbarVisible  - whether vertical scrolling should be used
 */

/**
 * Calculates the scaling ratio to use on the thumbnail.
 * Zoom to fit needs the larger of either the height or
 * the width to be used for the scaling ratio
 * so that entire asset is visible.
 *
 * @param {Object} @type {thumbnailInfo} - contains dimensions of thumbnail and canvas
 * @param {Object} HTML Element - which contains the thumbnail, svg, and iframe
 *
 * @returns {Object} @type {scalingInfo}
 */
function _calculateZoomToFitScaling (thumbnailInfo, div) {
	var thumbnailWidth = thumbnailInfo.thumbnailWidth;
	var thumbnailHeight = thumbnailInfo.thumbnailHeight;

	var iframeElContainer = div.getElementsByTagName('iframe')[0];
	var canvasWidth = thumbnailInfo.canvasWidth;
	var canvasHeight = thumbnailInfo.canvasHeight;
	var scaleRatio, scaledHeight, scalingInfo;

	if (iframeElContainer.readyState === 'complete') {
		canvasWidth = iframeElContainer.contentDocument &&
		iframeElContainer.contentDocument.body &&
		iframeElContainer.contentDocument.body.clientWidth
		canvasHeight = iframeElContainer.contentDocument &&
		iframeElContainer.contentDocument.body &&
		iframeElContainer.contentDocument.body.clientHeight;
	}

	if (isNaN(canvasHeight)) {
		canvasHeight = 0;
	}

	if (isNaN(canvasWidth)) {
		canvasWidth = _calculateCanvasWidth(thumbnailInfo);
	}


	if (canvasWidth > canvasHeight) {
		scaleRatio = thumbnailWidth / canvasWidth;
	} else if (canvasHeight > 0) {
		scaleRatio = thumbnailHeight / canvasHeight;
	} else {
		scaleRatio = 0;
	}
	scaledHeight = scaleRatio > 0 ? (thumbnailHeight / scaleRatio) : 0;

	scalingInfo = {
		thumbnailWidth: thumbnailWidth,
		thumbnailHeight: thumbnailHeight,
		canvasWidth: canvasWidth,
		canvasHeight: Math.max(canvasHeight, scaledHeight),
		scaleRatio: Math.round(scaleRatio * 1000) / 1000,
		yScrollbarVisible: false
	};

	return scalingInfo;
}

/**
 * Calculates the scaling ratio to use on the thumbnail.
 * Scale to width will use the width to for the scaling ratio, and only
 * the top a the asset may be visible (if height is much larger than width)
 *
 * @param {Object} @type {thumbnailInfo} - contains dimensions of thumbnail and canvas
 * @param {Object} HTML Element - which contains the thumbnail, svg, and iframe
 *
 * @returns {Object} @type {scalingInfo}
 */
function _calculateWidthScaling (thumbnailInfo, div) {
	var thumbnailWidth = thumbnailInfo.thumbnailWidth;
	var thumbnailHeight = thumbnailInfo.thumbnailHeight;
	var iframeElContainer = div.getElementsByTagName('iframe')[0];
	var canvasWidth = iframeElContainer && iframeElContainer.contentDocument && iframeElContainer.contentDocument.body && iframeElContainer.contentDocument.body.clientWidth || thumbnailInfo.canvasWidth;
	var canvasHeight = iframeElContainer && iframeElContainer.contentDocument && iframeElContainer.contentDocument.body && iframeElContainer.contentDocument.body.clientHeight || thumbnailInfo.canvasHeight;
	var scaleRatio, scaledHeight, scalingInfo;

	if (isNaN(canvasHeight)) {
		canvasHeight = 0;
	}

	if (isNaN(canvasWidth)) {
		canvasWidth = _calculateCanvasWidth(thumbnailInfo);
	}

	if (canvasWidth > canvasHeight) {
		scaleRatio = thumbnailWidth / canvasWidth;
	} else if (canvasHeight > 0) {
		scaleRatio = thumbnailHeight / canvasHeight;
	} else {
		scaleRatio = 0;
	}
	scaledHeight = scaleRatio > 0 ? (thumbnailHeight / scaleRatio) : 0;

	// The canvas height minimum should be enought to fill thumbnail
	scalingInfo = {
		thumbnailWidth: thumbnailWidth,
		thumbnailHeight: thumbnailHeight,
		canvasWidth: canvasWidth,
		canvasHeight: Math.max(canvasHeight, scaledHeight),
		scaleRatio: Math.round(scaleRatio * 1000) / 1000,
		yScrollbarVisible: false
	};

	return scalingInfo;
}

/**
 * Calculates the scrollbar width
 *
 * @returns {number} scrollbarWidth
 */
function _getScrollBarWidth () {
	var inner = document.createElement('p');
	inner.style.width = '100%';
	inner.style.height = '200px';

	var outer = document.createElement('div');
	outer.style.position = 'absolute';
	outer.style.top = '0px';
	outer.style.left = '0px';
	outer.style.visibility = 'hidden';
	outer.style.width = '200px';
	outer.style.height = '150px';
	outer.style.overflow = 'hidden';
	outer.appendChild(inner);

	document.body.appendChild(outer);
	var w1 = inner.offsetWidth;
	outer.style.overflow = 'scroll';
	var w2 = inner.offsetWidth;
	if (w1 === w2) {
		w2 = outer.clientWidth;
	}

	document.body.removeChild(outer);

	var scrollbarWidth = w1 - w2;

	return (scrollbarWidth);
}

/**
 * Calculates the scaling ratio to use on the thumbnail.
 * Scale to width with vertical scrolling will use the width to for the scaling ratio, and
 * if the asset is to long to view, make a scrollbar visible.
 *
 * @param {Object} @type {thumbnailInfo} - contains dimensions of thumbnail and canvas
 * @param {Object} HTML Element - which contains the thumbnail, svg, and iframe
 *
 * @returns {Object} @type {scalingInfo}
 */
function _calculateWidthVerticalScrollingScaling (thumbnailInfo, div) {
	var thumbnailWidth = thumbnailInfo.thumbnailWidth;
	var thumbnailHeight = thumbnailInfo.thumbnailHeight;
	var iframeElContainer = div.getElementsByTagName('iframe')[0];
	var canvasWidth = iframeElContainer.contentDocument && iframeElContainer.contentDocument.body && iframeElContainer.contentDocument.body.clientWidth || thumbnailInfo.canvasWidth;
	var canvasHeight = iframeElContainer.contentDocument && iframeElContainer.contentDocument.body && iframeElContainer.contentDocument.body.clientHeight || thumbnailInfo.canvasHeight;

	if (isNaN(canvasWidth)) {
		canvasWidth = _calculateCanvasWidth(thumbnailInfo);
	}

	var scaleRatio;
	var scalingInfo;

	var scrollbarWidth = _getScrollBarWidth();
	var padding = 8;

	if ((canvasWidth + scrollbarWidth + padding) > canvasHeight) {
		scalingInfo = _calculateZoomToFitScaling(thumbnailInfo, div);
	} else {
		scaleRatio = (thumbnailWidth - scrollbarWidth) / canvasWidth;
		scalingInfo = {
			thumbnailWidth: thumbnailWidth,
			thumbnailHeight: thumbnailHeight,
			canvasWidth: canvasWidth,
			canvasHeight: Math.max(canvasHeight, thumbnailHeight / scaleRatio),
			scaleRatio: Math.round(scaleRatio * 1000) / 1000,
			yScrollbarVisible: true
		};
	}

	return scalingInfo;
}

/**
 * Scales a generated thumbnail (HTML Elements returned by generateScalableThumbnail)
 * by a given scaling factor and accounts for a scrollbar
 *
 * @param {Object} HTML Element - which contains the thumbnail, svg, and iframe
 * @param {Object} @type {scalingInfo}
 */
function _scaleThumbnail (div, scalingInfo) {
	var scaleRatio = scalingInfo.scaleRatio;
	var thumbnail = div.getElementsByClassName('asset-thumbnail')[0];
	if (!thumbnail) {
		return;
	}
	thumbnail.style['overflow-y'] = scalingInfo.yScrollbarVisible ? 'scroll' : 'hidden';

	var scaledX = Math.floor(scalingInfo.canvasWidth * scaleRatio);
	var scaledY = Math.floor(scalingInfo.canvasHeight * scaleRatio);

	var svg = div.getElementsByTagName('svg')[0];
	var group = div.getElementsByTagName('g')[0];
	var overlay = div.getElementsByClassName('localthumbOverlay')[0];

	// bug in webkit and unit testing that doesn't allow getting foreignObject by tag name
	var foreignObject = div.getElementsByClassName('foreignobject-workaround')[0];

	svg.style.width = `${scaledX }px`;
	svg.style.height = `${scaledY }px`;

	group.setAttribute('transform', `scale(${ scaleRatio })`);

	foreignObject.setAttribute('width', scalingInfo.canvasWidth);
	foreignObject.setAttribute('height', scalingInfo.canvasHeight);

	if (scalingInfo.yScrollbarVisible) {
		overlay.style.width = `${scaledX }px`;
	} else {
		overlay.style.width = '';
	}
}

function _isEmptyDynamicDefaultContent (asset) {
	const isDynamicContent = asset && asset.assetType && asset.assetType.id === 201;
	const defaultContent = isDynamicContent &&
		asset.meta &&
		asset.meta.options &&
		asset.meta.options.dynamicContent &&
		asset.meta.options.dynamicContent.views &&
		asset.meta.options.dynamicContent.views.html &&
		asset.meta.options.dynamicContent.views.html.defaultContent &&
		asset.meta.options.dynamicContent.views.html.defaultContent.textContent;

	return isDynamicContent && !defaultContent;
}

function _getEmptyDynamicDefaultThumbnail (thumbnailWidth, thumbnailHeight) {
	const div = document.createElement('div');
	div.classList.add('asset-thumbnail-empty-dynamic');
	div.style.width = `${thumbnailWidth}px`;
	div.style.height = `${thumbnailHeight}px`;

	return div;
}

/**
 * Create a div with an iframe that gets instantiated once added to DOM.
 * the div is to be used with the scaling functions to create different thumbnails
 *
 * @param {Object} @type {thumbnailInfo}
 * @param {requestCallback} onloadCallback - called when iframe holding thumbnail canvas is loaded
 */
function _generateScalableThumbnail (thumbnailInfo, onloadCallback) {
	var asset = thumbnailInfo.asset;
	var thumbnailWidth = thumbnailInfo.thumbnailWidth;
	var thumbnailHeight = thumbnailInfo.thumbnailHeight;
	var namespace = 'http://www.w3.org/2000/svg';

	thumbnailWidth = thumbnailWidth || thumbnailHeight || 80;
	thumbnailHeight = thumbnailHeight || thumbnailWidth;

	if (_isEmptyDynamicDefaultContent(asset)) { // dynamic content
		return _getEmptyDynamicDefaultThumbnail(thumbnailWidth, thumbnailHeight);
	}

	// Check for ForeignObject support (IE11 will let us create one, but won't display it)
	if (!document.implementation.hasFeature('w3.org/TR/SVG11/feature#Extensibility', '1.1')) {
		return false;
	}

	// Check for text-only message; compile based on text channel
	var isText = (asset && asset.assetType && asset.assetType.id === 209);
	var channel = isText ? 'text' : (thumbnailInfo.channel || null);

	// compiled asset html gets dumped in a scaled iframe
	var html = SecureHtmlUtils.sanitizeHtml(compile(asset, channel, 'preview'), { removeAmpAttributes: true });
	if (!html) {
		return false;
	}

	var div = document.createElement('div');
	div.style.position = 'relative';

	div.innerHTML =
		`<div class="asset-thumbnail" style="width: ${ thumbnailWidth }px;height: ${ thumbnailHeight }px;overflow-x:hidden;overflow-y:hidden;">` +
		`<svg style="height:100%" xmlns="${ namespace }">` +
		`<g>` +
		`<foreignObject class="foreignobject-workaround">` +
		`<iframe scrolling="no" class="localthumb" width="100%" height="100%" style="display: none; border: none;" tabindex="-1"></iframe>` +
		`</foreignObject>` +
		`</g>` +
		`</svg>` +
		`</div>` +
		`<div class="localthumbOverlay fuelux3" style="height:${ thumbnailHeight }px;position:absolute;top:0;left:0;right:0;"></div>`;

	var iframe = div.getElementsByClassName('localthumb')[0];

	function onload () {
		iframe.removeEventListener('load', onload);
		var iframeElementContainer = iframe.contentDocument;
		var iframeBody;
		iframeElementContainer.open();
		iframeElementContainer.writeln(html);
		iframeElementContainer.close();

		if (isText) {
			// Text based emails don't have any markup so we need to format them using white-space
			iframeBody = iframeElementContainer.getElementsByTagName('body')[0];
			iframeBody.style.whiteSpace = 'pre-wrap';
		}

		var scalingInfo = onloadCallback(thumbnailInfo, div);
		_scaleThumbnail(div, scalingInfo);

		// Hiding then showing this iframe in a different execution thread works around a chrome specific bug.
		// Keep this in mind before simplifying this code. See jira ticket content-4037.
		var bailout = 0;

		function checkReady () {
			setTimeout(function () {
				if (iframeElementContainer.readyState === 'complete') {
					iframe.setAttribute('style', 'border: none;');
				} else {
					bailout++;
					if (bailout < 100) {
						checkReady();
					}
				}
			}, 100);
		}

		if (window.chrome && window.navigator.vendor === 'Google Inc.') {
			checkReady();
		} else {
			iframe.setAttribute('style', 'border: none;');
		}
	}

	iframe.addEventListener('load', onload);
	return div;
}

/**
 * Creates a thumbnail of a given asset. There are three types of thumbnails that
 * can be generated by this function:
 *  1) default - asset is scaled to be max width of the thumbnail with no vertical scrolling
 *  2) 'zoomToFit' - asset is scaled to fit inside the thumbnail
 *  3) 'verticalScrollbar' - asset is scaled to be max width of the thumbnail and vertical scrolling is added if needed
 *
 * This type of thumbnail generated is determined by the thumbnailType string in thumbnailInfo.
 *
 * @param {Object} @type {thumbnailInfo}
 *
 * @returns {boolean|Object} - false or HTML Element
 */
function generateThumbnail (thumbnailInfo) {
	var thumbnailType = _calculateWidthScaling;
	if (thumbnailInfo.thumbnailType === 'zoomToFit') {
		thumbnailType = _calculateZoomToFitScaling;
	} else if (thumbnailInfo.thumbnailType === 'verticalScrollbar') {
		thumbnailType = _calculateWidthVerticalScrollingScaling;
	}

	var result = _generateScalableThumbnail(thumbnailInfo, thumbnailType);
	var scalingInfo;
	if (result) {
		scalingInfo = thumbnailType(thumbnailInfo, result);
		_scaleThumbnail(result, scalingInfo);
	}

	return result;
}

/**
 * showMagnificationZoomableThumbnail should be called when the magnification icon
 * should be visible on a previously created Zoomable Thumbnail
 *
 * @param {Object}  HTMLElement that was previously created to display zoomable thumbnail
 *
 */
function showMagnificationZoomableThumbnail (el) {
	el.getElementsByTagName('button')[0].style.display = '';
}

function _handleWheelEvent (event) {
	event.stopPropagation();
	event.preventDefault();

	var thumbnail = this.parentElement.parentElement.getElementsByClassName('asset-thumbnail')[0];
	var scrollTo = event.deltaY + thumbnail.scrollTop;
	thumbnail.scrollTop = scrollTo;
}

/**
 * Changes the thumbnail view to zoom to fit
 *
 * The icon needs to change from zoom out to zoom in
 * The thumbnail needs to change from max width with scroll to zoom to fit
 *
 * @param {btn} HTMLElement
 * @param {Object} @type {thumbnailInfo}
 * @param {div} HTMLElement of the thumbnail
 */
function _changeToZoomToFitView (btn, thumbnailInfo, div) {
	btn.setAttribute('data-zoom', 'false');
	btn.getElementsByClassName('fuelux-icon-zoom-in')[0].style.display = '';
	btn.getElementsByClassName('fuelux-icon-zoom-out')[0].style.display = 'none';
	var scalingInfo = _calculateZoomToFitScaling(thumbnailInfo, div);
	_scaleThumbnail(div, scalingInfo);

	// scroll event should not be hijacked if scrollbar is not enabled
	var overlay = div.getElementsByClassName('localthumbOverlay')[0];
	overlay.removeEventListener('wheel', _handleWheelEvent);
}

/**
 * hideMagnificationZoomableThumbnail should be called when the magnification icon
 * should be hidden on a previously created Zoomable Thumbnail
 *
 * When the Magnification is hidden, the thumbnail show go to zoom to fix
 *
 * @param {Object}  HTMLElement that was previously created to display zoomable thumbnail
 *
 */
function hideMagnificationZoomableThumbnail (el) {
	var thumbnail = el.getElementsByClassName('asset-thumbnail')[0];
	var btn = el.getElementsByTagName('button')[0];
	var foreignObject = el.getElementsByClassName('foreignobject-workaround')[0];
	var thumbnailInfo = {
		thumbnailWidth: parseInt(thumbnail.style.width),
		thumbnailHeight: parseInt(thumbnail.style.height),
		canvasWidth: foreignObject.getAttribute('width')
	};

	if (btn && btn.getAttribute('data-zoom') === 'true') {
		_changeToZoomToFitView(btn, thumbnailInfo, el);
	}

	if (btn) {
		btn.style.display = 'none';
	}
}

/**
 * Changes the thumbnail view to max width with scrollbar
 *
 * The icon needs to change from zoom in to zoom out
 * The thumbnail needs to change from zoom to fit to max width with scrollbar
 *
 * @param {btn} HTMLElement
 * @param {Object} @type {thumbnailInfo}
 * @param {div} HTMLElement of the thumbnail
 */
function _changeToWidthVerticalScrollingView (btn, thumbnailInfo, div) {
	btn.setAttribute('data-zoom', 'true');
	btn.getElementsByClassName('fuelux-icon-zoom-in')[0].style.display = 'none';
	btn.getElementsByClassName('fuelux-icon-zoom-out')[0].style.display = '';
	var scalingInfo = _calculateWidthVerticalScrollingScaling(thumbnailInfo, div);
	_scaleThumbnail(div, scalingInfo);

	// hijack scroll event so it goes to thumbnail scrollbar
	if (scalingInfo.yScrollbarVisible) {
		var overlay = div.getElementsByClassName('localthumbOverlay')[0];
		overlay.addEventListener('wheel', _handleWheelEvent);
	}
}

/**
 * Creates a Zoomable thumbnail of a given asset.
 * The thumbnail has 2 views.
 * 1) zoom to fit (starting view)
 * 2) zoom to width with scroll bar (if magnification selected)
 *
 * When the thumbnail detects a mouse over, a small magnification button is displayed
 * in the upper left corner. If the button is pressed view 2 is displayed. The magnification
 * button turns into a zooom out button.
 * If the zoom out button is pressed, view 1 is displayed.
 *
 * When the thumbnail detects a mouse out event, the zoom button is hidden
 * and view 1 is displayed.
 *
 * @param {Object} @type {thumbnailInfo}
 *
 * @returns {boolean|Object} - false or HTMLElement
 */
function generateZoomableThumbnail (thumbnailInfo) {
	thumbnailInfo.thumbnailType = 'zoomToFit';
	var result = generateThumbnail(thumbnailInfo);

	if (result) {
		result.getElementsByClassName('localthumbOverlay')[0].innerHTML =
			'<button type="button" id="magnification" class="btn btn-default">' +
			'<span class="fuelux-icon fuelux-icon-zoom-in"></span>' +
			'<span class="fuelux-icon fuelux-icon-zoom-out" style="display: none"></span>' +
			'</button>';

		result.getElementsByTagName('button')[0].setAttribute('data-zoom', false);

		result.getElementsByTagName('button')[0].onclick = function (e) {
			// do not select something if the button was clicked
			e.stopPropagation();

			var div = this.parentElement.parentElement;
			if (this.getAttribute('data-zoom') === 'true') {
				_changeToZoomToFitView(this, thumbnailInfo, div);
			} else {
				_changeToWidthVerticalScrollingView(this, thumbnailInfo, div);
			}
		};
	}

	return result;
}

module.exports = {
	generateThumbnail: generateThumbnail,
	generateZoomableThumbnail: generateZoomableThumbnail,
	showMagnificationZoomableThumbnail: showMagnificationZoomableThumbnail,
	hideMagnificationZoomableThumbnail: hideMagnificationZoomableThumbnail,
	unitTest: {
		_changeToZoomToFitView: _changeToZoomToFitView,
		_changeToWidthVerticalScrollingView: _changeToWidthVerticalScrollingView,
		_calculateWidthScaling: _calculateWidthScaling,
		_calculateCanvasWidth: _calculateCanvasWidth,
		_generateScalableThumbnail: _generateScalableThumbnail,
		_calculateZoomToFitScaling: _calculateZoomToFitScaling,
		_scaleThumbnail: _scaleThumbnail
	}
};


// React stuff
import React from 'react';
import { hot } from 'react-hot-loader';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Majik stuff
import MAJIK from '@salesforce-mc/majik';
import ActivitySDK from '@salesforce-mc/activitysdk';
import MAJIKMessageActivity from '@salesforce-mc/majik-message-activity';
import MAJIKChannelAnalytics from '@salesforce-mc/majik-channel-analytics';

// Utilities
import isEmpty from 'lodash/isEmpty';
import find from 'lodash.find';
import moment from 'moment';
import MCLib from '@salesforce-mc/mc-lib';

// Icon
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import appIcon from '!raw-loader!../../static/images/push_icon_with_background.svg'; // eslint-disable-line import/no-unresolved
import messageCardAppIcon from '!raw-loader!../../static/images/push_icon.svg'; // eslint-disable-line import/no-unresolved

import I18n from '../utilities/i18n';
import PostmongerStore from '../utilities/postmonger';
import { hasProperty, generateUUID, sanitizeHTML } from '../utilities/helper';
import TimezoneUtils from '../utilities/timezone';

// Containers
import PushMessageTable from './push-message-table';
import MessageConfiguration from './message-configuration';
import DeliveryOptions from './delivery-options';
import AdvancedOptions from './advanced-options';
import SummaryDetail from './summary-detail';

// Actions
import { fetchApplications, fetchApplicationDetail } from '../actions/applications';
import { fetchMessages } from '../actions/messages';

import betterValidationExtension from '../utilities/better-validation-extension';

// Constants
import {
	MAJIK_DONE_BUTTON_ID,
	MAJIK_DETAIL_BUTTON_ID,
	DEFAULT_PAGE_SIZE,
	MAJIK_SIZE,
	MAJIK_MESSAGE_DEFINITION_TAB
} from '../constants'; // eslint-disable-line import/no-duplicates

// Mock data for edit flow. Uncomment to use
// import { LEGACY_CONTENT_MOCK_DATA } from '../constants'; // eslint-disable-line import/no-duplicates
// import { CONTENT_BUILDER_MOCK_DATA } from '../constants'; // eslint-disable-line import/no-duplicates

const observeAndUnmountReact = (target) => {
	// Observe dom removal and when the wrapper DOM element is removed, unmount React component
	const observer = new MutationObserver((mutations) => {
		// Check for removed target
		mutations.forEach((mutation) => {
			const nodes = Array.from(mutation.removedNodes);
			if (nodes.indexOf(target) > -1) {
				// DOM element is removed
				ReactDOM.unmountComponentAtNode(target);
			}
		});
	});
	observer.observe(document.body, {
		subtree: true,
		childList: true
	});
};

const isMessageSelected = (store) => {
	// Check if any type of message is selected
	if (!isEmpty(store) && hasProperty(store, 'messageDefinition')) {
		return hasProperty(store.messageDefinition, 'content') && !isEmpty(store.messageDefinition.content);
	}

	return false;
};

const isApplicationSelected = (store) => {
	if (!store || !hasProperty(store, 'messageConfiguration') || !hasProperty(store.messageConfiguration, 'payload')) {
		return false;
	}

	const { payload } = store.messageConfiguration;

	return hasProperty(payload, 'application') && hasProperty(payload.application, 'id') && hasProperty(payload.application, 'name') && payload.application.id.length > 0 && payload.application.name.length > 0;
};

let contentBuilderModeValidationState = null;

const applyLegacyContentMode = ({ config, refreshMenu }) => {
	if (!hasProperty(config, 'views') || !hasProperty(config.views, 'messageConfig') || !hasProperty(config.views.messageConfig, 'spoke') || config.views.messageConfig.spoke.hidden) {
		return; // If legacy mode is already applied, skip it.
	}

	// 1. Hide spoke views
	config.views.messageConfig.spoke.hidden = true;
	config.views.deliveryOptions.spoke.hidden = true;
	config.views.advancedOptions.spoke.hidden = true;

	// 2. Hide menu items
	config.views.messageConfig.menu.invisible = true;
	config.views.deliveryOptions.menu.invisible = true;
	config.views.advancedOptions.menu.invisible = true;

	// 3. Update required view
	config.views.messageConfig.spoke.required = false;
	config.views.messageConfig.menu.required = false;

	// 4. Save content builder mode validation state
	contentBuilderModeValidationState = config.views.deliveryOptions.spoke.validationState;

	// 5. Set content builder mode validation state to default
	config.views.deliveryOptions.spoke.validationState = null;
	config.views.deliveryOptions.menu.validationState = null;

	refreshMenu();
};

const applyContentBuilderMode = ({ config, refreshMenu }) => {
	// Skip if legacy mode has not been applied
	if (!hasProperty(config, 'views') || !hasProperty(config.views, 'messageConfig') || !hasProperty(config.views.messageConfig, 'spoke') || !config.views.messageConfig.spoke.hidden) {
		return;
	}

	// 1. Show spoke views
	config.views.messageConfig.spoke.hidden = false;
	config.views.deliveryOptions.spoke.hidden = false;
	config.views.advancedOptions.spoke.hidden = false;

	// 2. Show menu items
	config.views.messageConfig.menu.invisible = false;
	config.views.deliveryOptions.menu.invisible = false;
	config.views.advancedOptions.menu.invisible = false;

	// 3. Update required view
	config.views.messageConfig.spoke.required = true;
	config.views.messageConfig.menu.required = true;

	// 4. Resotre content builder mode validation state
	config.views.deliveryOptions.spoke.validationState = contentBuilderModeValidationState;
	config.views.deliveryOptions.menu.validationState = contentBuilderModeValidationState;

	refreshMenu();
};

const sectionKeys = [
	'messageDefinition',
	'messageConfiguration',
	'deliveryOptions',
	'advancedOptions'
];

const isDetailViewDataAvailable = (content) => content && !isEmpty(content) && hasProperty(content, 'views') && hasProperty(content.views, 'push') && hasProperty(content.views.push, 'meta') && hasProperty(content.views.push.meta, 'options');

class App extends React.Component {
	constructor (props) {
		super(props);

		this.activitySDK = new ActivitySDK();
		this.cultureCode = PostmongerStore.cultureCode;
		this.i18n = I18n(this.cultureCode);

		moment.locale(this.i18n.getMomentLocale());

		this.GUIDForMobilePushReportingName = generateUUID().replace(/-/g, '');

		// Majik Views
		this.createSummaryView = this.createSummaryView.bind(this);
		this.createMessageDefinitionView = this.createMessageDefinitionView.bind(this);
		this.createMessageConfigView = this.createMessageConfigView.bind(this);
		this.createDeliveryOptionsView = this.createDeliveryOptionsView.bind(this);
		this.createAdvancedOptionsView = this.createAdvancedOptionsView.bind(this);

		// Initialize window variable for q2 test
		window.push = {};

		// Localization map for Majik
		this.localization = {
			// HubAndSpoke extension keys:
			majik_hs_show_more: this.i18n.get('majik_button_show_more'),
			majik_hs_show_less: this.i18n.get('majik_button_show_less'),
			majik_hs_cancel_button: this.i18n.get('majik_button_cancel'),
			majik_hs_summary_button: this.i18n.get('majik_button_summary'),
			majik_hs_spoke_view_button: this.i18n.get('majik_button_view'),
			majik_hs_spoke_edit_button: this.i18n.get('majik_button_edit'),
			majik_hs_close_message_confirm: this.i18n.get('majik_button_confirm'),
			majik_hs_close_message_cancel: this.i18n.get('majik_button_cancel'),
			majik_hs_close_message_title: this.i18n.get('majik_label_close_message_title'),
			majik_hs_close_message_body: this.i18n.get('majik_label_close_message_body'),

			// ContentView extension keys:
			majik_ce_message_definition_header: this.i18n.get('push_notification_message_definition_header'),
			majik_ce_message_definition: this.i18n.get('majik_label_message_definition'),
			majik_ce_message_definition_menu: this.i18n.get('majik_label_message_definition_menu'),
			majik_ce_create_message_link: this.i18n.get('majik_button_create_message_link'),
			majik_ce_edit_message_link: this.i18n.get('majik_button_edit_message_link'),
			majik_ce_legacy_content_tab: this.i18n.get('mobile_push_messages'),
			majik_ce_content_builder_tab: this.i18n.get('majik_button_content_builder_messages'),
			majik_ce_activity_name: this.i18n.get('majik_label_activity_name'),
			majik_ce_activity_desc: this.i18n.get('majik_label_activity_description'),
			majik_ce_summary_menu: this.i18n.get('majik_button_summary'),
			majik_ce_detail_view_btn: this.i18n.get('majik_button_detail_view'),
			majik_ce_summary_view_btn: this.i18n.get('majik_button_summary_view'),
			majik_ce_done_btn: this.i18n.get('majik_button_done'),
			majik_ce_summary_header: this.i18n.get('push_notification_activity_summary'),
			majik_ce_summary_select_message_btn: this.i18n.get('majik_button_select_message'),
			majik_ce_summary_new_message_btn: this.i18n.get('majik_button_new_message'),
			majik_ce_summary_edit_message_btn: this.i18n.get('majik_button_edit_message'),
			majik_ce_summary_call_to_action_lg: this.i18n.get('majik_label_summary_call_to_action_lg'),
			majik_ce_summary_call_to_action_sm: this.i18n.get('majik_label_summary_call_to_action_sm'),
			majik_ce_save_and_return: this.i18n.get('majik_button_save_and_return'),
			majik_ce_save_message: this.i18n.get('majik_button_save_message'),
			majik_ce_message_properties: this.i18n.get('majik_label_message_properties'),
			majik_ce_edit_properties: this.i18n.get('majik_button_edit_properties'),
			majik_ce_view_properties: this.i18n.get('majik_button_view_properties'),
			majik_select_different_message: this.i18n.get('majik_button_select_different_message'),
			majik_ce_create_new_message: this.i18n.get('majik_button_create_new_message'),
			majik_ce_refresh_activity: this.i18n.get('majik_button_refresh_activity'),
			majik_ce_close_editor_message_body: this.i18n.get('majik_label_close_message_body'),
			majik_ce_preview_subscribers: this.i18n.get('majik_button_subscriber_preview'),
			majik_ce_error_view_main_text: this.i18n.get('majik_label_error_view_main_text'),
			majik_ce_error_view_sub_text: this.i18n.get('majik_label_error_view_sub_text'),
			majik_ca_push_dock_header: this.i18n.get('push_notification_analytics'),
			majik_ce_analytics: this.i18n.get('majik_ce_analytics'),
			majik_ce_activity_summary: this.i18n.get('majik_ce_activity_summary'),
			majik_ca_push_details_header: this.i18n.get('majik_ca_push_details_header'),
			majik_ca_push_dock_menu: this.i18n.get('majik_ca_push_dock_menu'),
			majik_ca_push_details_menu: this.i18n.get('majik_ca_push_details_menu'),
			majik_validation_alert_text: this.i18n.get('majik_validation_alert_text'),
			majik_validation_alert_title: this.i18n.get('majik_validation_alert_title')
		};
	}

	initializeMCLib (endpoints, callback) {
		// In QA1SS1, endpoints.stackHost will be `mc.s1.qa1.exacttarget.com`
		const stackHostProtocol = 'https://';
		const allowedOrigin =  stackHostProtocol + endpoints.stackHost;

		this.mcLib = new MCLib({
			mc: window.top,
			allowedOrigin: allowedOrigin,
			callback: () => {
				if (!this.mcLib) {
					callback();

					return;
				}

				this.mcLib.registerLogoutUrl(`${window.location.origin}/logout`);
				this.mcLib.getBusinessRules((data) => { // eslint-disable-line no-unused-vars
					PostmongerStore.br = data.businessRules;
					callback();
				});
			}
		});
	}

	fetchApps () { // eslint-disable-line react/no-unused-class-component-methods
		this.isFetching = true;

		fetchApplications().then((data) => {
			this.applications = data.items;
			this.isFetching = false;

			if (this.messageConfiguration) {
				this.messageConfiguration.reRenderMobileApplication(this.applications, this.isFetching);
			}
		});
	}

	fetchLegacyMessages () { // eslint-disable-line react/no-unused-class-component-methods
		const { activity } = this.activitySDK;
		this.defaultLegacyMessageFecthOption = {
			pageSize: DEFAULT_PAGE_SIZE,
			page: 1, // Start from 1
			orderby: 'lastUpdated',
			sortdirection: 'DESC',
			selectedDataId: hasProperty(activity, 'configurationArguments') && hasProperty(activity.configurationArguments, 'pushMessageId') ? activity.configurationArguments.pushMessageId : ''
		};

		fetchMessages(this.defaultLegacyMessageFecthOption).then((data) => {
			this.legacyMessageData = data; // eslint-disable-line react/no-unused-class-component-methods

			if (this.pushMessageTable) {
				this.pushMessageTable.renderMessageTableWithData(data);
			}
		});
	}

	refreshLegacyContentView () { // eslint-disable-line react/no-unused-class-component-methods
		if (!this.pushMessageTable) {
			return;
		}

		this.pushMessageTable.clearSelection();
	}

	renderLegacyMessageCard (cardOptions, content, scm) { // eslint-disable-line react/no-unused-class-component-methods
		// Message card rendering for legacy push messages
		cardOptions.metadata.values = [];

		if (hasProperty(content, 'lastUpdated')) {
			cardOptions.metadata.values.push({
				text: this.i18n.get('majik_label_metadata_last_modified'),
				value: moment(content.lastUpdated).format('LL')
			});
		}

		if (hasProperty(content, 'application') && hasProperty(content.application, 'name')) {
			cardOptions.metadata.values.push({
				text: this.i18n.get('mobile_app_selection_dropdown_label'),
				value: sanitizeHTML(content.application.name)
			});
		}

		if (scm.isReadOnly()) {
			// Update card button options - Do not show any action button
			cardOptions.primaryButtonText = '';
			cardOptions.dropdownList = [];
		} else {
			// Update card button options - Only Select Different Message and Create New Message
			cardOptions.primaryButtonText = this.i18n.get('majik_button_select_different_message');

			cardOptions.onPrimaryButtonClicked = () => {
				scm.show('messageDefinition');
			};

			cardOptions.dropdownList = [{
				text: this.i18n.get('majik_button_create_new_message'),
				onClick: () => {
					scm.show('messageDefinition', {
						action: 'new-message'
					});
				}
			}];
		}
	}

	renderAssetMessageCard (cardOptions, content, scm) { // eslint-disable-line react/no-unused-class-component-methods
		// Message card rendering for content builder push messages
		cardOptions.metadata.values = [];

		if (hasProperty(content, 'modifiedDate')) {
			cardOptions.metadata.values.push({
				text: this.i18n.get('majik_label_metadata_last_modified'),
				value: moment(content.modifiedDate).format('LL')
			});
		}

		if (hasProperty(content, 'createdBy') && hasProperty(content.createdBy, 'name')) {
			cardOptions.metadata.values.push({
				text: this.i18n.get('majik_label_metadata_created_by'),
				value: sanitizeHTML(content.createdBy.name)
			});
		}

		if (hasProperty(content, 'createdDate')) {
			cardOptions.metadata.values.push({
				text: this.i18n.get('majik_label_metadata_created_date'),
				value: moment(content.createdDate).format('LL')
			});
		}

		if (scm.isReadOnly()) {
			// Update card button options - Only show subscriber preview
			cardOptions.primaryButtonText = this.i18n.get('majik_button_subscriber_preview');

			cardOptions.onPrimaryButtonClicked = () => {
				scm.show('messageDefinition', {
					action: 'preview-subscriber'
				});
			};

			cardOptions.dropdownList = [];
		}
	}

	createSummaryView (scm) {
		const { i18n } = this;

		return {
			onInitialize: function () {
				const { store, sdk } = scm;

				if (hasProperty(sdk.activity, 'name')) {
					this.setActivityName(sdk.activity.name);
					store.activityName = sdk.activity.name;
				}

				if (hasProperty(sdk.activity, 'metaData') && hasProperty(sdk.activity.metaData, 'globals') && hasProperty(sdk.activity.metaData.globals, 'description')) {
					this.setDescription(sdk.activity.metaData.globals.description);
					store.activityDescription = sdk.activity.metaData.globals.description;
				}

				scm.xt.setDirtyState(true); // Enable cancel confirmation dialog

				// Initialize store
				if (!hasProperty(store, 'messageDefinition')) {
					sectionKeys.forEach((key) => {
						store[key] = {};
					});

					console.log(store);
				}

				this.updateDoneButton();
			},
			onActivityNameUpdated: function (e) {
				// Callback when the activity name input is updated.
				scm.store.activityName = e.value;

				this.updateDoneButton();
			},
			isConfigured: () => {
				const { store } = scm;
				let isConfigured;

				// Check if activity name is blank
				isConfigured = hasProperty(store, 'activityName') && store.activityName.trim().length !== 0;

				if (hasProperty(store, 'isLegacyContent') && store.isLegacyContent) {
					return isConfigured;
				}

				// Check content selection
				isConfigured = isConfigured && isMessageSelected(store);

				// Check Reporting Name
				isConfigured = isConfigured && hasProperty(store.advancedOptions, 'mobilePushReportingName') && store.advancedOptions.mobilePushReportingName.length > 0;

				// Check Application data
				isConfigured = isConfigured && hasProperty(store.messageConfiguration, 'application') && hasProperty(store.messageConfiguration.application, 'id') && hasProperty(store.messageConfiguration.application, 'name') && store.messageConfiguration.application.id.length > 0 && store.messageConfiguration.application.name.length > 0;

				return isConfigured;
			},
			updateDoneButton: function () {
				if (this.isConfigured()) {
					scm.enableFooterButton(MAJIK_DONE_BUTTON_ID);
				} else {
					scm.disableFooterButton(MAJIK_DONE_BUTTON_ID);
				}
			},
			onDescriptionUpdated: (e) => {
				// Callback when the activity description input is updated.
				scm.store.activityDescription = e.value;
			},
			onDetailViewClicked: function () {
				this.toggleDetailView();
			},
			onDetailedViewOpened: function (e, args) {
				const { store } = args;
				const { content } = store.messageDefinition;
				const { element } = e;

				if (isDetailViewDataAvailable(content)) {
					this.summaryDetailView = new SummaryDetail(element, { // eslint-disable-line react/no-unused-class-component-methods
						i18n,
						moment,
						contentData: (content.views.push.meta.options.customBlockData.data) ? content.views.push.meta.options.customBlockData.data : content.views.push.meta.options.customBlockData,
						contentName: sanitizeHTML(content.name)
					});
				}
			},
			onDoneClicked: (args) => {
				const { sdk, close, xt, store, isReadOnly } = args;
				const { activity } = sdk;
				const messageDefinitionStore = store.messageDefinition;
				const messageConfigurationStore = store.messageConfiguration;
				const deliveryOptionsStore = store.deliveryOptions;
				const advancedOptionsStore = store.advancedOptions;

				if (isReadOnly()) {
					// On read only mode, just close the activity without saving
					xt.setDirtyState(false); // Disable cancel confirmation dialog on close
					close();
					return;
				}

				store.isReportingNameSupported = false; // Since reporting name is no longer supported, we set this flag to false by default. This flag is used to determine whether the activity was created before or after the reporting name deprecation.

				// Save Activity Name - If it's empty, set it with selected content's name
				if (!store.activityName || store.activityName.length === 0) {
					store.activityName = messageDefinitionStore.content.name;
				}
				activity.name = store.activityName;

				// Save Activity Description
				if (store.activityDescription) {
					sdk.setGlobals('description', store.activityDescription);
				} else {
					sdk.setGlobals('description', '');
				}

				// Save data to configurationArguments
				if (store.isLegacyContent) {
					// For Legacy content
					activity.configurationArguments = {
						...activity.configurationArguments,
						pushMessageId: messageDefinitionStore.content.id,
						name: messageDefinitionStore.content.name,
						application: {
							id: messageDefinitionStore.content.application.id,
							name: messageDefinitionStore.content.application.name
						}
					};

					// Clean up content builder payload if any
					delete activity.configurationArguments.assetId;
					delete activity.configurationArguments.mobilePushReportingName;
					delete activity.configurationArguments.iosBadge;
					delete activity.configurationArguments.sound;
					delete activity.configurationArguments.interactiveNotification;
					delete activity.configurationArguments.keys;
					delete activity.configurationArguments.startDate;
					delete activity.configurationArguments.campaignId;
					delete activity.configurationArguments.sendWindowStartTime;
					delete activity.configurationArguments.sendWindowEndTime;
					delete activity.configurationArguments.sendWindowTimeZone;

					if (hasProperty(activity.metaData, 'sections') && hasProperty(activity.metaData.sections, 'message-definition')) {
						delete activity.metaData.sections['message-definition'];
					}

					// Store application's id and name data only
					messageDefinitionStore.content.application = (({ id, name }) => ({ id, name }))(messageDefinitionStore.content.application);
				} else {
					// For Content Builder Content
					// Save required properties
					activity.configurationArguments = {
						...activity.configurationArguments,
						// Save Message Definition
						assetId: messageDefinitionStore.content.id,
						name: messageDefinitionStore.content.name,
						// Save Message Configuration
						application: {
							id: messageConfigurationStore.payload.application.id,
							name: messageConfigurationStore.payload.application.name
						},
						// Save Delivery Options
						iosBadge: deliveryOptionsStore.payload.iosBadge,
						sound: deliveryOptionsStore.payload.sound,
						// Save Advanced Options
						mobilePushReportingName: advancedOptionsStore.payload.mobilePushReportingName
					};

					// Clean up legacy content
					delete activity.configurationArguments.pushMessageId;

					// Save optional properties
					// Delivery options
					if (hasProperty(deliveryOptionsStore.payload, 'interactiveNotification')) {
						activity.configurationArguments.interactiveNotification = deliveryOptionsStore.payload.interactiveNotification;
					} else {
						delete activity.configurationArguments.interactiveNotification;
					}

					if (hasProperty(deliveryOptionsStore.payload, 'campaignId')) {
						activity.configurationArguments.campaignId = deliveryOptionsStore.payload.campaignId;
					} else {
						delete activity.configurationArguments.campaignId;
					}

					if (hasProperty(PostmongerStore.isBROn('push_jb_send_window') && deliveryOptionsStore.payload, 'sendWindowStartTime') && hasProperty(deliveryOptionsStore.payload, 'sendWindowEndTime') && hasProperty(deliveryOptionsStore.payload, 'sendWindowTimeZone')) {
						activity.configurationArguments.sendWindowStartTime = deliveryOptionsStore.payload.sendWindowStartTime;
						activity.configurationArguments.sendWindowEndTime = deliveryOptionsStore.payload.sendWindowEndTime;
						activity.configurationArguments.sendWindowTimeZone = deliveryOptionsStore.payload.sendWindowTimeZone;
					} else {
						delete activity.configurationArguments.sendWindowStartTime;
						delete activity.configurationArguments.sendWindowEndTime;
						delete activity.configurationArguments.sendWindowTimeZone;
					}

					// Advanced options
					if (hasProperty(advancedOptionsStore.payload, 'keys')) {
						activity.configurationArguments.keys = advancedOptionsStore.payload.keys;
					} else {
						delete activity.configurationArguments.keys;
					}

					// Save startDate with current time
					activity.configurationArguments.startDate = moment().toISOString(); // Current time

					// Update contentType in metaData for Analytics
					activity.metaData.sections = {
						...activity.metaData.sections,
						'message-definition': {
							contentType: 'asset'
						}
					};

					// Delete Content from metadata. It will be fetched during edit flow.
					delete messageDefinitionStore.content;

					// Delete mobileAppDetail from metadata. It will be fetched during edit flow.
					delete messageConfigurationStore.mobileAppDetail;
				}

				// Save store to metaData
				activity.metaData = activity.metaData || {};
				activity.metaData.store = store;

				console.log(activity.configurationArguments); // Debugging purpose
				console.log(activity.metaData.store); // Debugging purpose

				sdk.saveAndClose();

				xt.setDirtyState(false); // Disable cancel confirmation dialog on 'Done'

				close();
			},
			onShow: function (args) {
				// Done Button State update
				const { disableFooterButton, hideFooterButton, store } = args;

				// Render activity name and description
				this.setActivityName(store.activityName);
				this.setDescription(store.activityDescription);

				this.updateDoneButton();

				if (store.isLegacyContent) {
					// If legacy message is selected, disable detail view button and enable Done button right away
					hideFooterButton(MAJIK_DETAIL_BUTTON_ID);
				} else if (isMessageSelected(store)) {
					if (!isApplicationSelected(store) || !isDetailViewDataAvailable(store.messageDefinition.content)) {
						disableFooterButton(MAJIK_DETAIL_BUTTON_ID);
					}
				}

				// Save configurationArguments to window.push for testing purpose.
				if (hasProperty(args.sdk, 'activity') && hasProperty(args.sdk.activity, 'configurationArguments') && !isEmpty(args.sdk.activity.configurationArguments)) {
					window.push.JBPayload = args.sdk.activity.configurationArguments;
				}
			}
		};
	}

	createMessageDefinitionView () {
		const self = this;
		const viewIndex = 0;
		const sectionKey = sectionKeys[viewIndex];

		return {
			onCreateMessageModalShow: function (e, args) {
				// Callback when the create message modal is shown
				console.log('MessageDefinitionView.onShowCreateMessageModal %o', e);

				const { resize } = args;

				resize(MAJIK_SIZE.FULL);
			},
			onEditMessageModalShow: function (e, args) {
				// Callback when the edit message modal is shown
				console.log('MessageDefinitionView.onShowEditMessageModal %o', e);

				const { resize } = args;

				resize(MAJIK_SIZE.FULL);
			},
			onShow: function (args) {
				const { resize, store } = args;
				// const { activity } = sdk;

				const selectedTab = this.getSelectedTab();

				if (selectedTab === MAJIK_MESSAGE_DEFINITION_TAB.CONTENT_BUILDER && !!store.isLegacyContent) {
					this.setSelectedTab(MAJIK_MESSAGE_DEFINITION_TAB.LEGACY);
					resize(MAJIK_SIZE.DEFAULT);
					this.size = MAJIK_SIZE.DEFAULT; // eslint-disable-line react/no-unused-class-component-methods

					// Hide Edit Message Link button
					document.querySelector('.edit-message-link').classList.add('slds-hide');
				} else if (selectedTab === MAJIK_MESSAGE_DEFINITION_TAB.LEGACY && !store.isLegacyContent) {
					this.setSelectedTab(MAJIK_MESSAGE_DEFINITION_TAB.CONTENT_BUILDER);
					resize(MAJIK_SIZE.FULL);
					this.size = MAJIK_SIZE.FULL; // eslint-disable-line react/no-unused-class-component-methods
				}
			},
			onLegacyContentTabSelected: function (e, args) {
				const { store, resize } = args;

				// Resize SCM to its original size from full screen
				resize(MAJIK_SIZE.DEFAULT);

				this.size = MAJIK_SIZE.DEFAULT; // eslint-disable-line react/no-unused-class-component-methods

				if (!store.isLegacyContent && store.shouldRefreshLegacyContentTabView) {
					store.shouldRefreshLegacyContentTabView = false;

					self.refreshLegacyContentView();
				}
			},
			onContentBuilderTabSelected: function (e, args) {
				const { store, resize } = args;

				// Resize SCM to its original size from full screen
				resize(MAJIK_SIZE.FULL);

				this.size = MAJIK_SIZE.FULL; // eslint-disable-line react/no-unused-class-component-methods

				if (store.isLegacyContent && store.shouldRefreshContentBuilderTabView) {
					store.shouldRefreshContentBuilderTabView = false;

					this.refreshContentBuilderGrid();
				}
			},
			onRenderLegacyContentArea: function (e, args) {
				const { store } = args;
				const { element } = e;
				const containerId = 'push-message-datatable';

				// Render Legacy Push Message Table
				element.innerHTML = `<div id="${containerId}"></div>`;

				self.pushMessageTable = new PushMessageTable(element.querySelector(`#${containerId}`), {
					i18n: self.i18n,
					store,
					data: self.legacyMessageData || {},
					fetchOption: self.defaultLegacyMessageFecthOption,
					onSelectMessage: (message) => this.onLegacyContentSelected(args, message)
				});
			},
			onContentChanged: (e, args) => {
				const { sdk, xt, store } = args;
				const { activity } = sdk;

				if (e.content) {
					const { content } = e;
					store.isLegacyContent = !hasProperty(content, 'assetType');

					// save activity name with content name
					store.activityName = content.name;

					/* Initialize Default Data */
					if (!hasProperty(activity, 'configurationArguments')) {
						activity.configurationArguments = {};
					}

					if (store.isLegacyContent) {
						applyLegacyContentMode(args);

						// Should refresh Content Builder View later on if previously content builder content was selected
						if (store[sectionKey].content && hasProperty(store[sectionKey].content, 'assetType')) {
							store.shouldRefreshContentBuilderTabView = true;
						}

						// save content to store
						store[sectionKey].content = e.content;
					} else {
						applyContentBuilderMode(args);

						const isMessagePreloading = (!hasProperty(store, sectionKey) || !hasProperty(store[sectionKey], 'content')) && hasProperty(activity.configurationArguments, 'assetId'); // Check if message is being pre-loaded by Majik via getAssetId()

						if (isMessagePreloading) {
							// Just save loaded content to store and exit
							if (!hasProperty(store, 'messageDefinition')) {
								store.messageDefinition = {
									content: content
								};
							} else {
								store.messageDefinition.content = content;
							}

							return;
						}

						// Should refresh Legacy Content View later on if previously legacy content was selected
						if (store[sectionKey].content && !hasProperty(store[sectionKey].content, 'assetType')) {
							store.shouldRefreshLegacyContentTabView = true;
						}

						// On select content
						xt.enableSpoke('messageConfig');

						if (isApplicationSelected(store)) {
							// Enable other spokes only when message-configuration view is configured
							xt.enableSpoke('deliveryOptions');
							xt.enableSpoke('advancedOptions');
						}

						// save content to store
						store[sectionKey].content = e.content;

						/* Initial Data Setup */
						// For Delivery Options
						if (!hasProperty(store.deliveryOptions, 'payload')) {
							store.deliveryOptions.payload = {};
						}

						if (!hasProperty(store.deliveryOptions.payload, 'sound')) {
							store.deliveryOptions.payload.sound = 'default';
							store.deliveryOptions.selectedNotificationSound = this.i18n.get('notification_sound_options_default');
						}

						if (!hasProperty(store.deliveryOptions.payload, 'iosBadge')) {
							store.deliveryOptions.payload.iosBadge = false;
							store.deliveryOptions.isIOSBadgeToggleEnabled = this.i18n.get('disabled');
						}

						// For Advanced Options
						if (!hasProperty(store.advancedOptions, 'payload')) {
							store.advancedOptions.payload = {};
						}

						if (hasProperty(store.advancedOptions.payload, 'mobilePushReportingName') && store.advancedOptions.payload.mobilePushReportingName.indexOf(e.content.name) !== -1) {
							return; // Skip if the reporting name has already been generated with the selected content
						}

						store.advancedOptions.payload.mobilePushReportingName = `${e.content.name}-${this.GUIDForMobilePushReportingName}`;
						store.advancedOptions.mobilePushReportingName = `${e.content.name}-${this.GUIDForMobilePushReportingName}`;
					}
				} else {
					// On deselect content
					delete store[sectionKey].content;

					xt.disableSpoke('messageConfig');
					xt.disableSpoke('deliveryOptions');
					xt.disableSpoke('advancedOptions');
				}
			},
			onLegacyContentSelected: function (scm, message) {
				this.setContent(message);

				// Hide Edit Message Link button
				document.querySelector('.edit-message-link').classList.add('slds-hide');
			},
			onRenderMessageCard: function (e, args) {
				const { store } = args;
				const { cardOptions, content } = e;

				// The icon that appears in the card header
				cardOptions.cardIconHtml = messageCardAppIcon;

				if (store.isLegacyContent) {
					self.renderLegacyMessageCard(cardOptions, content, args);
				} else {
					self.renderAssetMessageCard(cardOptions, content, args);
				}
			},
			onCreateSubscriberPreview: function (e, args) {
				const { store } = args;
				const messageConfigurationStore = store.messageConfiguration;
				const { options } = e;

				// Set application data option if application has been selected
				if (isApplicationSelected(store)) {
					options.appId = messageConfigurationStore.application.id;
					options.previewData = messageConfigurationStore.application;
				}

				// Temporarily comment out the work until Content Builder supports datasource option
				// // Set dataSource option if data extension entry source has been configured
				// if (!isEmpty(PostmongerStore.entrySourceData) && hasProperty(PostmongerStore.entrySourceData, 'categoryFullPath') && PostmongerStore.entrySourceData.categoryFullPath === 'Data Extensions' && hasProperty(PostmongerStore.entrySourceData, 'dataExtensionId')) {
				// 	options.dataSource = {
				// 		type: 'dataExtension',
				// 		id: PostmongerStore.entrySourceData.dataExtensionId
				// 	};
				// }
			},
			onCreateContentBuilderEditor: function (e, args) {
				const { store } = args;
				const messageConfigurationStore = store.messageConfiguration;
				const { options } = e;

				// Set application data option if application has been selected
				if (isApplicationSelected(store)) {
					options.appId = messageConfigurationStore.application.id;
					options.previewData = messageConfigurationStore.application;
				}
			}
		};
	}

	createMessageConfigView (scm) {
		const viewIndex = 1;
		const sectionKey = sectionKeys[viewIndex];

		return {
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('inapp_message_configuration'),
				required: true,
				disabled: true,
				description: () => {
					if (isMessageSelected(scm.store)) {
						return '';
					}

					return `<div class="slds-text-color_weak">${this.i18n.get('inapp_message_configuration_description')}</div>`;
				},
				metadata: (element, args) => {
					if (!isMessageSelected(args.store)) {
						return;
					}

					const { xt, store } = args;
					const { application } = store[sectionKey];
					const metaDataValues = [];

					// Mobile App Name
					metaDataValues.push({
						text: this.i18n.get('mobile_app_selection_dropdown_label'),
						value: application ? application.value : '',
						title: application ? application.title : ''
					});

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('message_configuration_section_heading')
			},
			onShow: (args) => {
				const { element, sdk, xt, store, showLoadMask, hideLoadMask } = args;
				const { activity } = sdk;

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				const container = element.querySelector(`#${sectionKey}`);

				this.messageConfiguration = new MessageConfiguration(container, {
					sectionKey,
					xt,
					i18n: this.i18n,
					applications: this.applications || [],
					isFetching: this.isFetching,
					store,
					showLoadMask,
					hideLoadMask
				});
			},
			configured: () => {
				const { store } = scm;

				return hasProperty(store.messageConfiguration, 'application') && hasProperty(store.messageConfiguration.application, 'id') && hasProperty(store.messageConfiguration.application, 'name') && store.messageConfiguration.application.id.length > 0 && store.messageConfiguration.application.name.length > 0;
			}
		};
	}

	createDeliveryOptionsView (scm) {
		const viewIndex = 2;
		const sectionKey = sectionKeys[viewIndex];

		return {
			menu: {
				text: this.i18n.get('inapp_delivery_options_menu')
			},
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('inapp_delivery_options'),
				required: false,
				disabled: true,
				description: () => {
					const { views } = scm;

					if (isMessageSelected(scm.store) && isApplicationSelected(scm.store)) {
						const spokeValidationState = find(views(), {
							name: sectionKey
						}).spoke.validationState;

						if (spokeValidationState === 'error') {
							return `<div>${this.i18n.get('finish_configuration')}</div>`;
						}
						return '';
					}

					return `<div class="slds-text-color_weak">${this.i18n.get('inapp_delivery_options_description')}</div>`;
				},
				metadata: (element, args) => {
					if (!isMessageSelected(args.store) || !isApplicationSelected(args.store)) {
						return;
					}

					const spokeValidationState = find(args.views(), {
						name: sectionKey
					}).spoke.validationState;

					if (spokeValidationState === 'error') {
						return;
					}

					const { xt, store } = args;
					const { selectedNotificationSound, selectedInteractiveNotification, isIOSBadgeToggleEnabled, selectedCampaign, sendWindowStartTime, isSendWindowToggleEnabled, sendWindowEndTime, sendWindowTimeZone } = store[sectionKey];
					const metaDataValues = [];

					// Add notification sound section
					metaDataValues.push({
						text: this.i18n.get('notification_sound'),
						value: selectedNotificationSound
					});

					// Selectively add interactive notification section
					if (selectedInteractiveNotification) {
						metaDataValues.push({
							text: this.i18n.get('interactive_notification'),
							value: sanitizeHTML(selectedInteractiveNotification[0].value)
						});
					}

					// Add ios badge section
					metaDataValues.push({
						text: this.i18n.get('ios_badge'),
						value: isIOSBadgeToggleEnabled
					});

					// Add Campaign section
					if (!isEmpty(selectedCampaign)) {
						metaDataValues.push({
							text: this.i18n.get('campaign'),
							value: sanitizeHTML(selectedCampaign.name)
						});
					}

					// Add send window section
					if (PostmongerStore.isBROn('push_jb_send_window')) {
						if (isSendWindowToggleEnabled && sendWindowStartTime && sendWindowEndTime && sendWindowTimeZone) {
							metaDataValues.push({
								text: this.i18n.get('push_send_window_start_time'),
								value: sanitizeHTML(sendWindowStartTime)
							});

							metaDataValues.push({
								text: this.i18n.get('push_send_window_end_time'),
								value: sanitizeHTML(sendWindowEndTime)
							});

							metaDataValues.push({
								text: this.i18n.get('push_send_window_timezone'),
								value: sanitizeHTML(TimezoneUtils.getTimeZoneById(sendWindowTimeZone.id).name)
							});
						}
					}

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('delivery_options_section_heading')
			},
			configured: () => {
				const { store } = scm;
				const { isSendWindowToggleEnabled } = store[sectionKey];

				if (store.isLegacyContent || !PostmongerStore.isBROn('push_jb_send_window')) {
					return true;
				}

				return isSendWindowToggleEnabled;
			},
			onValidate: () => {
				const { store } = scm;
				const { isSendWindowToggleEnabled, sendWindowStartTime, sendWindowEndTime, sendWindowTimeZone } = store[sectionKey];

				if (!isSendWindowToggleEnabled || store.isLegacyContent || !PostmongerStore.isBROn('push_jb_send_window')) {
					return {
						errors: false
					};
				}

				const isValid = isSendWindowToggleEnabled && sendWindowStartTime.length > 0 && sendWindowEndTime.length > 0 && !isEmpty(sendWindowTimeZone);

				return {
					errors: !isValid
				};
			},
			onShow: (args) => {
				const { element, sdk, store, createModal, views } = args;
				const { activity } = sdk;

				const menuValidationState = find(views(), {
					name: sectionKey
				}).menu.validationState;

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				observeAndUnmountReact(element.firstChild);

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				ReactDOM.render(
					<Provider store={this.props.route.store}>
						<IconSettings iconPath="/assets/icons">
							<DeliveryOptions
								i18n={this.i18n}
								deliveryOptionsStore={store}
								sectionKey={sectionKey}
								createModal={createModal}
								menuValidationState={menuValidationState}
							/>
						</IconSettings>
					</Provider>,
					document.getElementById(sectionKey)
				);
			}
		};
	}

	createAdvancedOptionsView (scm) {
		const viewIndex = 3;
		const sectionKey = sectionKeys[viewIndex];

		return {
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('inapp_advanced_options'),
				required: false,
				disabled: true,
				description: () => {
					if (isMessageSelected(scm.store) && isApplicationSelected(scm.store)) {
						return '';
					}

					return `<div class="slds-text-color_weak">${this.i18n.get('inapp_advanced_options_description')}</div>`;
				},
				metadata: (element, args) => {
					if (!isMessageSelected(args.store) || !isApplicationSelected(args.store)) {
						return;
					}

					const { xt, store } = args;
					const { mobilePushReportingName, customKeys } = store[sectionKey];
					const metaDataValues = [];

					const generateCustomKeysMetadata = (keys) => {
						if (!keys) {
							return '';
						}

						let result = '';

						keys.forEach((customKey) => {
							result += `${customKey.key}: ${customKey.value}, `;
						});

						return result.substring(0, result.length - 2); // Remove trailing comma and space
					};

					// Mobile Push Reporting Name
					if (scm.isReadOnly() && !(typeof (store.isReportingNameSupported) !== 'undefined' && store.isReportingNameSupported === false)) {
						metaDataValues.push({
							text: this.i18n.get('mobilepush_reporting_name'),
							value: sanitizeHTML(mobilePushReportingName)
						});
					}

					if (customKeys) {
						// Start Date
						metaDataValues.push({
							text: this.i18n.get('custom_keys'),
							value: sanitizeHTML(generateCustomKeysMetadata(customKeys))
						});
					}

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('advanced_options_section_heading')
			},
			onShow: (args) => {
				const { element, sdk, store } = args;
				const { activity } = sdk;

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				observeAndUnmountReact(element.firstChild);

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				ReactDOM.render(
					<Provider store={this.props.route.store}>
						<IconSettings iconPath="/assets/icons">
							<AdvancedOptions
								advancedOptionsStore={store}
								i18n={this.i18n}
								sectionKey={sectionKey}
							/>
						</IconSettings>
					</Provider>,
					document.getElementById(sectionKey)
				);
			}
		};
	}

	componentDidMount () {
		const self = this;

		const majikConfig = {
			sdk: this.activitySDK,
			element: this.majik,
			defaultView: 'summary',
			localization: this.localization,
			navIcon: () => (appIcon),
			headerIcon: () => (appIcon),
			sldsPath: '/assets',
			views: {
				summary: this.createSummaryView,
				messageDefinition: this.createMessageDefinitionView,
				messageConfig: this.createMessageConfigView,
				deliveryOptions: this.createDeliveryOptionsView,
				advancedOptions: this.createAdvancedOptionsView
			},
			extensions: [
				{
					extension: MAJIKMessageActivity.MessageActivity,
					options: {
						summaryView: {
							showDetailViewButton: true,
							showAnalyticsToggle: true,
							isActivityNameRequired: true
						},
						messageDefinition: {
							showPreviewSubscriber: true,
							showLegacyMessageTab: true,
							getAssetId: (scm) => {
								const { activity } = scm.sdk;

								if (activity && hasProperty(activity, 'configurationArguments') && hasProperty(activity.configurationArguments, 'assetId')) {
									return activity.configurationArguments.assetId;
								}

								return null;
							},
							contentBuilder: {
								grid: {
									defaultView: 'thumbnail',
									query: {
										acceptedTypes: [230],
										channelViews: ['push']
									}
								},
								editor: {
									view: 'Push',
									asset: {
										assetType: {
											id: 230
										}
									}
								},
								previewSubscriber: {
									view: 'Push',
									previewData: null,
									recipientData: null,
									dataSource: null
								}
							}
						}
					}
				},
				{
					extension: MAJIKChannelAnalytics.Extension,
					options: {
						getStack: function (scm) {
							return scm.sdk.endpoints.stackKey;
						},
						getId: function (scm) {
							if (isEmpty(scm) || isEmpty(scm.sdk) || isEmpty(scm.sdk.activity) || !hasProperty(scm.sdk.activity, 'configurationArguments') || !hasProperty(scm.sdk.activity.configurationArguments, 'pushMessageId')) {
								return null;
							}

							return scm.sdk.activity.configurationArguments.pushMessageId;
						},
						route: 'push/:id/dock'
					}
				},
				{
					extension: betterValidationExtension,
					options: {
						saveButtonId: 'done-btn'
					}
				}
			],
			onLoad: function (done, scm) {
				const { store, sdk, config, xt } = scm;
				const { activity } = sdk;

				if (activity && hasProperty(activity, 'metaData') && hasProperty(activity.metaData, 'store') && activity.metaData.store) {
					scm.store = {
						...activity.metaData.store,
						...scm.store // In case of preloaded content
					};

					if (scm.store.isLegacyContent) {
						// Manually set content if it's legacy content
						const { views: { messageDefinition } } = config;

						messageDefinition.setContent(scm.store.messageDefinition.content);
					} else {
						// Just enable spokes since content was already set by getAssetId
						xt.enableSpoke('messageConfig');

						if (isApplicationSelected(scm.store)) {
							// Enable other spokes only when message-configuration view is configured by selecting application
							xt.enableSpoke('deliveryOptions');
							xt.enableSpoke('advancedOptions');

							// If prefetched mobile app detail data exists, update store with it
							if (self.preFetchedMobileAppDetail) {
								scm.store.messageConfiguration.mobileAppDetail = self.preFetchedMobileAppDetail;
								if (self.preFetchedMobileAppDetail.categories) {
									scm.store.deliveryOptions.interactiveNotifications = self.preFetchedMobileAppDetail.categories.map((category) => ({
										id: category.id,
										label: category.name,
										value: category.name,
										buttons: category.buttons
									}));
								}
							}
						}
					}
				} else {
					store.isLegacyContent = false; // should check this for EDIT activity and change to true when scm.sdk.activity contains data and has an ID property and does not have a views property.
					store.messageDefinition = {}; // load data when edit
					store.messageConfiguration = {}; // load data when edit
					store.advancedOptions = {}; // load data when edit
					store.deliveryOptions = {}; // load data when edit
					store.shouldRefreshContentBuilderTabView = false; // load data when edit
					store.shouldRefreshLegacyContentTabView = false; // load data when edit
				}

				const isAnalyticsMode = activity && hasProperty(activity, 'editable') && hasProperty(activity, 'configurationArguments') && activity.editable === false && hasProperty(activity.configurationArguments, 'pushMessageId') && activity.configurationArguments.pushMessageId.length > 0;

				if (!isAnalyticsMode) {
					// Get Push Applications
					self.fetchApps();

					// Get Legacy Push Messages
					self.fetchLegacyMessages();
				}

				done();
			}
		};

		this.scm = new MAJIK.StandardConfigModel(majikConfig);

		if (PostmongerStore.devMode) {
			// Mock Journey Status
			this.activitySDK.requestInteraction = (callback) => {
				setTimeout(() => {
					callback({
						status: 'DRAFT'
					});
				}, 200);
			};

			this.activitySDK.ready = (callback) => {
				// Mock endpoints
				this.activitySDK.endpoints = {
					stackKey: 'QA1S1',
					stackHost: 'mc.s1.qa1.exacttarget.com'
				};

				// Uncomment to Mock Edit Flow
				// this.activitySDK.activity = LEGACY_CONTENT_MOCK_DATA; // Legacy Content Edit Flow
				// this.activitySDK.activity = CONTENT_BUILDER_MOCK_DATA; // Content Builder Edit Flow

				callback();
			};

			this.scm.registerExtension({
				extension: MAJIK.extensions.SCMDrawer
			});
		}

		// This is where the SCM object gets rendered on to the page
		// Use the activitySDK.ready() function to start the handshake with Journey Builder
		// The callback will fire when JB passes the activity back to us.
		this.activitySDK.ready(() => {
			const { activity, endpoints } = this.activitySDK;
			const isAnalyticsMode = activity && hasProperty(activity, 'editable') && hasProperty(activity, 'configurationArguments') && activity.editable === false && hasProperty(activity.configurationArguments, 'pushMessageId') && activity.configurationArguments.pushMessageId.length > 0;
			const isLegacyContent = activity && hasProperty(activity, 'metaData') && hasProperty(activity.metaData, 'store') && activity.metaData.store.isLegacyContent;
			const render = () => {
				// Fetch application detail before load if necessary
				if (!isAnalyticsMode && !isLegacyContent && hasProperty(activity, 'configurationArguments') && hasProperty(activity.configurationArguments, 'application')) {
					fetchApplicationDetail(activity.configurationArguments.application.id).then((data) => {
						this.preFetchedMobileAppDetail = (({ iconUrl, ...others }) => ({ ...others }))(data); // eslint-disable-line react/no-unused-class-component-methods
						this.scm.render();
					});
				} else {
					this.scm.render();
				}
			};

			this.initializeMCLib(endpoints, render);
		});
	}

	render () {
		return (
			<div className="mobilepush-push-actvity" ref={(el) => { this.majik = el; }} />
		);
	}
}

App.propTypes = {
	route: PropTypes.object.isRequired
};

export default compose(hot(module))(App);

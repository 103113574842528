var AssetUtils = require('@salesforce-mc/assetutils');
var HeroImageHtml = require('./templates/hero-image.html');
var cardActions = require('./card-actions');

module.exports = function configureView(scm, extensionOptions) {
    var ThisAssetUtils = extensionOptions.AssetUtils || AssetUtils;

    // We'll reuse or create a view called "messageDefinition"
    // this view will be the target we render the everything into.
    scm.config.views.messageDefinition = scm.config.views.messageDefinition || {};
    var view = scm.config.views.messageDefinition;

    // the Content Builder SDK is expensive to initialize
    // we'll make this view persistent so it only loads once
    view.persistent = true;

    view.size = 'scm-full';

    view.header = {
        text: scm.i18n.get('majik_ce_message_definition_header')
    };

    view.spoke = {
        index: 1,
        template: 'primary',
        required: true,
        text: scm.i18n.get('majik_ce_message_definition'),
        configured: isSpokeConfigured,
        onRenderUnconfigured: onMessageDefinitionSpokeRenderUnconfigured,
        onRenderConfigured: onMessageDefinitionSpokeRenderConfigured,
        onClick: onEditMessageDefinitionClicked
    };

    // "Message Definition" can translate into a very long single string in some languages
    // We need the ability to specify a optional menu localization key for the menu item
    var menuText = scm.i18n.get('majik_ce_message_definition_menu');
    if (!menuText || menuText === 'majik_ce_message_definition_menu') {
        menuText = scm.i18n.get('majik_ce_message_definition');
    }
    view.menu = {
        text: menuText
    };

    view.configured = isSpokeConfigured;

    function onEditMessageDefinitionClicked() {
        // show the messageDefinition view and pass a prop to tell
        // the view to show the edit message modal
        scm.show('messageDefinition', {
            action: 'edit-message'
        });
    }

    function isSpokeConfigured() {
        return Boolean(view.getContent());
    }

    function onMessageDefinitionSpokeRenderUnconfigured(element) {
        element.className = '';

        var callToActionOptions = {
            required: true,
            heroImageHtml: HeroImageHtml,
            captionTextLarge: scm.i18n.get('majik_ce_summary_call_to_action_lg'),
            captionTextSmall: scm.i18n.get('majik_ce_summary_call_to_action_sm'),
            button1Text: scm.i18n.get('majik_ce_summary_select_message_btn'),
            button2Text: scm.i18n.get('majik_ce_summary_new_message_btn'),
            onButton1Clicked: function() {
                scm.show('messageDefinition', {action: 'select-message'});
            },
            onButton2Clicked: function() {
                scm.show('messageDefinition', {action: 'new-message'});
            }
        };

        // we should never bee in the situation when we're in the read-only state
        // and the activity is unconfigured. with that said we're going to kill the
        // call to action buttons just in case we find ourselves in this state
        if (scm.isReadOnly()) {
            callToActionOptions.onButton1Clicked = null;
            callToActionOptions.onButton2Clicked = null;
            callToActionOptions.button1Text = null;
            callToActionOptions.button2Text = null;
        }

        // the new message button can be hidden via extension options
        if (!extensionOptions.showNewMessageButton) {
            callToActionOptions.button2Text = null;
            callToActionOptions.onButton2Clicked = null;
        }

        // we're going to trigger a `onRenderCallToAction` callback before we render the card.
        // this will let the channel dev modify the card options before its rendered
        // they can also cancel rendering in case they want to handle it manually

        var event = {
            // let the channel dev update the call to action options
            callToActionOptions: callToActionOptions,
            // pass the element in case they want to handle the render
            element: element,
            // a flag to cancel the automatic rendering
            cancel: false
        };

        if (typeof view.onRenderCallToAction === 'function') {
            view.onRenderCallToAction(event, scm);
        }

        if (!event.cancel) {
            scm.xt.renderCallToAction(element, callToActionOptions);
        }

    }

    function onMessageDefinitionSpokeRenderConfigured(element) {
        var content = view.getContent();

        element.className = '';

        var imageDiv = ThisAssetUtils.generateThumbnail({
            channel: getMessageChannelView(),
            asset: content,
            thumbnailWidth: 190,
            thumbnailHeight: 120,
            canvasWidth: 600
        });

        var cardOptions = {
            required: true,
            cardIconHtml: null,
            cardTitle: content.name,
            imageType: 'element',
            imageData: imageDiv,
            primaryButtonText: null,
            onPrimaryButtonClicked: null,
            onRenderContent: null,
            dropdownList: [],
            metadata: {
                description: scm.i18n.get('majik_ce_message_properties'),
                descriptionCssClass: 'slds-text-title_caps slds-text-color_weak',
                values: []
            }
        };

        setCardButtonOptions(cardOptions);

        // we're going to trigger a `onRenderMessageCard` callback before we render the card.
        // this will let the channel dev modify the card options before its rendered.
        // they can also cancel rendering in case they want to render something manually

        var event = {
            // give the channel dev asset utils in case they need it
            AssetUtils: ThisAssetUtils,
            // the channel will probably need the content object
            content: content,
            // let the channel dev update card options
            cardOptions: cardOptions,
            // pass the element in case they want to handle the render
            element: element,
            // a flag to cancel the automatic rendering
            cancel: false
        };

        if (typeof view.onRenderMessageCard === 'function') {
            view.onRenderMessageCard(event, scm);
        }

        if (!event.cancel) {
            scm.xt.renderCard(element, cardOptions);
        }

        if (typeof view.onAfterRenderMessageCard === 'function') {
            view.onAfterRenderMessageCard(event, scm);
        }
    }

    function setCardButtonOptions(cardOptions) {
        // actions contains every possible button/drop-down action that can appear in the card.
        var actions = cardActions(scm);

        // now we need to determine which action is the primary action
        // and what items appear in the drop down list
        var primaryAction = null;
        var dropdownList = [];

        if (scm.isReadOnly()) {

            if (extensionOptions.showPreviewSubscriber) {
                primaryAction = actions.previewSubscribers;
                dropdownList.push(actions.viewMessage);
            } else {
                primaryAction = actions.viewMessage;
            }

        } else if (extensionOptions.showPreviewSubscriber) {
            primaryAction = actions.previewSubscribers;
            dropdownList.push(actions.editMessage);
            dropdownList.push(actions.selectDifferentMessage);
            dropdownList.push(actions.createNewMessage);

        } else {
            primaryAction = actions.editMessage;
            dropdownList.push(actions.selectDifferentMessage);
            dropdownList.push(actions.createNewMessage);
        }

        // finally we can set the card options
        cardOptions.primaryButtonText = primaryAction.text;
        cardOptions.onPrimaryButtonClicked = primaryAction.onClick;
        cardOptions.dropdownList = dropdownList;
    }

    function getMessageChannelView() {
        var gridOptions = extensionOptions.contentBuilder.grid;
        if (gridOptions.query && gridOptions.query.channelViews) {
            return gridOptions.query.channelViews[0];
        }
        return null;
    }

    return view;
};

import 'isomorphic-fetch';
import svg4everybody from 'svg4everybody';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import './styles/index.scss';
import App from './containers/app';
import PostmongerStore from './utilities/postmonger';

import configureStore from './store/configure';

const store = configureStore();
const connection = PostmongerStore.getConnection();

svg4everybody();

const renderActivity = () => {
	ReactDOM.render(
		<Provider store={store}>
			<Router history={browserHistory}>
				<Route path="/index.html" component={App} store={store} />
			</Router>
		</Provider>,
		document.getElementById('mount')
	);

	document.body.classList.add('slds-wcag');
};

const checkDevMode = () => {
	const hasLocalStorage = typeof window.localStorage === 'object';

	// Use dev mode if localStorage has a 'dev' property set to 'true'
	if (hasLocalStorage) {
		const devSwitch = localStorage.getItem('dev');

		PostmongerStore.devMode = (devSwitch !== null && devSwitch === 'true');
	}
};

const init = () => {
	checkDevMode();

	// If it's in dev mode, just render the activity
	if (PostmongerStore.devMode) {
		renderActivity();
	} else {
		connection.trigger('requestTriggerEventDefinition');
	}
};

connection.on('requestedTriggerEventDefinition', (data) => {
	PostmongerStore.entrySourceData = data;

	connection.trigger('requestCulture');
});

connection.on('requestedCulture', (cultureCode) => {
	PostmongerStore.cultureCode = cultureCode;

	renderActivity();
});

init();

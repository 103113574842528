import React from 'react';
import PropTypes from 'prop-types';
import Input from '@salesforce/design-system-react/components/input';
import Tooltip from '../common/tooltip';

function AddCustomFunctionality ({ i18n, customKeysFromSelectedApp, payloadCustomKeys, handleCustomKeysChange }) {
	const customKeySections = customKeysFromSelectedApp.map((customKey) => {
		const id = customKey.id ? customKey.id : '';
		const { key } = customKey;
		const desc = customKey.description ? customKey.description : '';
		const payloadCustomKey = payloadCustomKeys.find((keyItem) => keyItem.key === key);
		const value = payloadCustomKey ? payloadCustomKey.value : '';

		return (
			<div key={id} className="input-container">
				<Input
					id={id}
					label={key}
					value={value}
					placeholder={i18n.get('custom_key_placeholder')}
					onChange={(event) => {
						handleCustomKeysChange(key, event.target.value);
					}}
				/>
				<span className="input-desc">{desc}</span>
			</div>
		);
	});

	return (
		<div className="add-custom-functionality-container">
			<div className="container-heading">{i18n.get('add_custom_functionality_heading')}</div>
			<Tooltip
				i18n={i18n}
				cid="mp-messages-customfuntionality"
			/>
			{customKeySections}
		</div>
	);
}

AddCustomFunctionality.propTypes = {
	i18n: PropTypes.object.isRequired,
	customKeysFromSelectedApp: PropTypes.array.isRequired,
	payloadCustomKeys: PropTypes.array.isRequired,
	handleCustomKeysChange: PropTypes.func.isRequired
};

export default AddCustomFunctionality;
